import { useContext } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiContext } from '../providers/api-provider';

export function useUserProjects() {
  /** Axios client */
  const _axios = useContext(ApiContext);

  return useQuery({
    queryKey: ['USER_PROJECTS'],
    queryFn: () =>
      _axios.get(`/projects/me`).then(({ data }) => {
        return data?.data;
      }),
    useErrorBoundary: true,
    staleTime: Infinity,
  });
}

export function useUserProjectById(id) {
  /** Axios client */
  const _axios = useContext(ApiContext);

  return useQuery({
    queryKey: ['USER_PROJECT_BY_ID', id],
    queryFn: () =>
      _axios.get(`/projects/${id}`).then(({ data }) => {
        return data?.data;
      }),
    useErrorBoundary: true,
    staleTime: Infinity,
  });
}

export function useUpdateProjectDetails() {
  /** Axios client */
  const _axios = useContext(ApiContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, data }) =>
      _axios
        .put(`/projects/${id}`, JSON.stringify(data), {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(({ data }) => {
          return data;
        }),
    onSuccess: async (data, variables, context) => {
      console.log('userProjects Mutate successful');
      // ✅ refetch the comments list for our blog post
      queryClient.invalidateQueries({ queryKey: ['USER_PROJECT_BY_ID'] });
      queryClient.invalidateQueries({ queryKey: ['USER_PROJECTS'] });
    },
    onSettled: async (data, error, variables, context) => {
      console.log('userProjects onSettled');
    },
  });
}

export function useCreateProjectDetails() {
  /** Axios client */
  const _axios = useContext(ApiContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, data }) =>
      _axios
        .post(`/projects`, JSON.stringify(data), {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(({ data }) => {
          return data?.data;
        }),
    onSuccess: async (data, variables, context) => {
      console.log('useCreateProjectDetails Mutate successful');
      // ✅ refetch the comments list for our blog post
      queryClient.invalidateQueries({ queryKey: ['USER_PROJECT_BY_ID'] });
      queryClient.invalidateQueries({ queryKey: ['USER_PROJECTS'] });
    },
    onSettled: async (data, error, variables, context) => {
      console.log('useCreateProjectDetails onSettled');
    },
  });
}
