import { Box, Flex, Grid, Spinner } from '@chakra-ui/react';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import EmojiRender from './Emoji';

const AllEmojiGrid = ({ onClick, data, isLoading = false }) => {
  // const newData = data.slice(26);
  return (
    <>
      <Flex
        mt="auto"
        flex={1}
        align="center"
        justify={'center'}
        data-testid="all-emoji-grid"
      >
        {isLoading && <Spinner alignSelf="center" size={'lg'} />}
        <Grid templateColumns="repeat(3, 1fr)" gap={3} data-testid="emojisgrid">
          {map(
            filter(
              data,
              (f) =>
                ![
                  '1FAE3',
                  '1FAE0',
                  '1FAE3',
                  '1FAE8',
                  '1F62E%20200D%201F4A8',
                  '1FAE5',
                  '1FAE1',
                  '1FAE2',
                  '263A FE0F',
                  'FE0F',
                  '1F636 200D 1F32B FE0F',
                  '1F62E 200D 1F4A8',
                ].includes(f.codePoint)
            ),
            (value, index) => {
              const emojiRawValue = {
                id: value.codePoint,
                name: value.codePoint,
                droptype: 'emojis',
                type: 'emojis',
              };
              return (
                <LazyLoadComponent
                  delayTime={400}
                  key={`${value.codePoint}-${index}`}
                >
                  <Box
                    key={value.codePoint}
                    w="90px"
                    h="50px"
                    bg="charadeDark3"
                    fontSize="37px"
                    justifyContent="center"
                    display="flex"
                    alignItems="center"
                    borderRadius={5}
                    color="azure"
                    data-testid="emoji-grid-box"
                    as="button"
                    onClick={() => onClick(emojiRawValue)}
                  >
                    <EmojiRender emojiCode={value.codePoint} />
                  </Box>
                </LazyLoadComponent>
              );
            }
          )}
        </Grid>
      </Flex>
    </>
  );
};

export default AllEmojiGrid;
