import {
  FormControl as ChakraFormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/react';
import React from 'react';

const FormControl = ({
  label,
  name,
  helperText,
  errorText,
  mb = 0,
  children,
  ...rest
}) => {
  return (
    <ChakraFormControl id={name} mb={mb} {...rest}>
      {label && <FormLabel color={'gray.500'}>{label}</FormLabel>}
      {children}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {errorText && <FormErrorMessage>{errorText}</FormErrorMessage>}
    </ChakraFormControl>
  );
};

export default FormControl;
