import { Box } from '@chakra-ui/react';
import React from 'react';

const Block = ({ children, ...props }) => (
  <Box
    borderBottomStyle={'solid'}
    borderBottomWidth={'thin'}
    borderBottomColor={'gray.700'}
    mb={6}
    pb={6}
    {...props}
  >
    {children}
  </Box>
);

export default Block;
