const config = {
  app: {
    name: 'Eyecast',
    domain: process.env.REACT_APP_URL,
    logoutUrl: 'https://www.clippy.io/dashboard',
  },
  auth: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    audience: 'https://dev-api.eyecast.com',
    claims: {
      loginsCount: 'https://eyecast.com/loginsCount',
    },
  },
};

export default config;
