import { useEditAreaObservable } from 'observeables';

export const useEditAreaRepository = () => {
  const editAreaObservable = useEditAreaObservable();

  const list = async () => {
    try {
      // editAreaObservable.list();
    } catch (error) {
      editAreaObservable.error('Error in updating edit area dimensons');
    } finally {
      editAreaObservable.updating(false);
    }
  };

  const update = async (payload) => {
    try {
      editAreaObservable.updating(true);
      editAreaObservable.update(payload);
    } catch (error) {
      editAreaObservable.error('Error in updating edit area dimensons');
    } finally {
      editAreaObservable.updating(false);
    }
  };

  const getEditAreaObservable = () => editAreaObservable.getObservable();

  return {
    list,
    update,
    getEditAreaObservable,
  };
};
