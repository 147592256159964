import { Box, Image } from '@chakra-ui/react';
import React from 'react';
import { Hover } from '../../hover';

export const PixelsComponent = ({ filter, imgSource }) => {
  const imgRef = React.useRef(null);
  const imgSrc =
    'https://eyecastvideoeditorfiles.s3.ap-southeast-2.amazonaws.com/assets/normaloverlay.png';

  const onLoad = React.useCallback(() => {
    if (imgRef.current) {
      if (filter !== 'Normal' || !['Normal', 'film'].includes(filter)) {
        // eslint-disable-next-line no-undef
        pixelsJS.filterImg(imgRef.current, filter);
      }
    }
  }, [filter]);

  return (
    <Box boxSize="sm" mb={'8px'} width="104px" height="55px">
      <Hover>
        <Image
          src={imgSource || imgSrc}
          onLoad={onLoad}
          w="104px"
          h="55px"
          alt="Clippy"
          crossOrigin="anonymous"
          id={`bypixels-${filter}`}
        />
      </Hover>
    </Box>
  );
};
