import { useAuth0 } from '@auth0/auth0-react';
import { Center, VStack } from '@chakra-ui/react';
import React from 'react';
import { LoadingSpinner } from 'components';
import Routes from 'routes';
import spinnerAnimationData from './assets/images/clippy-spinner.json';

// import { useHistory } from 'react-router-dom';
function refreshPage() {
  window.location.reload(false);
}

const App = () => {
  const { isLoading, error } = useAuth0();
  // const navigate = useHistory();

  // React.useEffect(() => {
  //   const pathName = window.location.pathname;
  //   if (pathName === '/') {
  //     navigate.push('/canvas');
  //   }
  // }, [navigate]);

  if (error) {
    // refreshPage();
    // return <div>Oops... {error.message}</div>;
    console.log('oops. invalidate state error', error.message);
    window.location.href = 'https://www.clippy.io/';
  }

  if (isLoading) {
    return (
      <VStack
        spacing={4}
        flex={1}
        bgColor={'shark'}
        align={'center'}
        justifyContent={'center'}
        width={'100%'}
        height={'100vh'}
      >
        <Center w="100px" h="100px">
          <LoadingSpinner
            animationData={spinnerAnimationData}
            width={100}
            height={100}
          />
        </Center>
      </VStack>
    );
  }

  return <Routes />;
};

export default App;
