import BlankLayout from 'layouts/blank';
import DefaultLayout from 'layouts/default';
import QueueLayout from 'layouts/queue';
import VideoLayout from 'layouts/video-editing';
import React from 'react';
import { BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';
import Dashboard from 'scenes/dashboard';
import { EditorHome } from 'scenes/editor-home/editor-home';
import QueueTable from 'scenes/queue-table';
import CanvasLanding from '../scenes/canvas-landing/canvas-landing';
import UploadMedia from '../scenes/canvas-landing/components/media';
import ModalRoute from './components/modal-route';
import ProtectedRoute from './components/protected-route';
import {
  ACOUNT_NAV_ITEMS,
  EDITING_NAV_ITEMS,
  HOME_NAV_ITEMS,
} from './nav-items';
import { DashboardReroute } from './components/dashboard-reroutes';

const Routes = ({ children }) => (
  <Router>
    <RoutesSwitch />
  </Router>
);

const RoutesSwitch = () => {
  let location = useLocation();
  let background = location.state && location.state.background;

  return (
    <React.Fragment>
      <Switch location={background || location}>
        <ProtectedRoute
          exact
          path="/dashboard"
          component={Dashboard}
          layout={DefaultLayout}
          routes={HOME_NAV_ITEMS}
        />
        <ProtectedRoute
          exact
          path="/account/settings"
          component={DashboardReroute}
          layout={DefaultLayout}
          routes={ACOUNT_NAV_ITEMS}
        />
        <ProtectedRoute
          exact
          path="/account/security"
          component={DashboardReroute}
          layout={DefaultLayout}
          routes={ACOUNT_NAV_ITEMS}
        />
        <ProtectedRoute
          exact
          path="/account/queue"
          component={QueueTable}
          layout={QueueLayout}
          routes={ACOUNT_NAV_ITEMS}
        />
        <ProtectedRoute
          exact
          path="/account/notifications"
          component={DashboardReroute}
          layout={DefaultLayout}
          routes={ACOUNT_NAV_ITEMS}
        />
        <ProtectedRoute
          exact
          path="/account/billing"
          component={DashboardReroute}
          layout={DefaultLayout}
          routes={ACOUNT_NAV_ITEMS}
        />
        <ProtectedRoute
          exact
          path="/onboarding"
          component={DashboardReroute}
          layout={BlankLayout}
        />

        <ProtectedRoute
          exact
          path="/canvas"
          component={EditorHome}
          layout={BlankLayout}
        />

        <ProtectedRoute
          exact
          path="/canvas/:id"
          component={CanvasLanding}
          layout={VideoLayout}
          routes={EDITING_NAV_ITEMS}
          editMenu={UploadMedia}
        />
      </Switch>
      {background && (
        <React.Fragment>
          <ModalRoute exact path="/pricing" component={DashboardReroute} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Routes;
export { HOME_NAV_ITEMS, ACOUNT_NAV_ITEMS, EDITING_NAV_ITEMS };
