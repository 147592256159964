import { ControlledMenu as MenuInner } from '@szhsin/react-menu';
import { menuItemSelector, menuSelector } from '@szhsin/react-menu/style-utils';
import styled from 'styled-components';

export const StyledContextMenu = styled(MenuInner)`
  ${menuSelector.name} {
    z-index: ${(props) => props.$maxZIndex || 10 + 10000};
  }
  ${menuItemSelector.name} {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-inline-start: 0.8rem;
    padding-inline-end: 0.8rem;
  }
`;
