import get from 'lodash/get';
import React, { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { EditorApiContext } from '../providers/api-provider';
import { EditorApiConstants } from '../constants';

export const useFontFamily = () => {
  const FETCH_FONT_FAMILY = 'FETCH_FONT_FAMILY';

  /** Axios client */
  const _axios = useContext(EditorApiContext);

  const getFontFamily = React.useCallback(async () => {
    const apiUrl = `${EditorApiConstants.endpoints.FONT_FAMILY}`;
    const result = await _axios.get(apiUrl).then((res) => res.data);
    const data = get(result, 'data', []);
    return data;
  }, [_axios]);

  const { isLoading, isError, error, data, isFetching, isSuccess, status } =
    useQuery([FETCH_FONT_FAMILY], () => getFontFamily());

  return {
    isLoading,
    isError,
    error,
    data: data || [],
    isFetching,
    isSuccess,
    status,
  };
};
