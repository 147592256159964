import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ children, container = document.body }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted ? createPortal(children, container) : null;
};

export default Portal;
