import { Box, Text } from '@chakra-ui/react';
import get from 'lodash/get';
import React from 'react';

export const TitleComponent = ({
  value,
  onClick = (f) => f,
  isCanvas = false,
  width = '104px',
  height = '55px',
  onMouseOver = (f) => f,
  onMouseOut = (f) => f,
  align = 'center',
  verticalAlign = 'center',
  id,
}) => {
  let extraProps = {};

  if (isCanvas) {
    extraProps = {
      // onMouseOver: onMouseOver,
      // onMouseOut: onMouseOut,
      onMouseLeave: onMouseOut,
      onMouseEnter: onMouseOver,
    };
  }
  // console.log('TitleComponent', value);
  const textValue = get(value, 'textValue', value.text) || '';

  return (
    <Box
      key={value.id}
      id={id || value.id}
      w={isCanvas ? width : '104px'}
      h={isCanvas ? height : '55px'}
      justifyContent={verticalAlign}
      backgroundColor="charadeDark3"
      display="flex"
      flexDir="column"
      alignItems={align}
      borderRadius={5}
      color="azure"
      data-testid="titles-grid-box"
      onClick={onClick}
      {...extraProps}
    >
      {value.hasBoxWrapper && (
        <Box {...value.boxStyles} {...extraProps}>
          <Text {...value.fontStyle}>{textValue}</Text>
          {value.hasSubTitle && (
            <Text {...value.subTitle}> {value.subTitle?.text}</Text>
          )}
        </Box>
      )}
      {!value.hasBoxWrapper && (
        <>
          <Text {...value.fontStyle}>{textValue}</Text>
          {value.hasSubTitle && (
            <Text {...value.subTitle}> {value.subTitle?.text}</Text>
          )}
        </>
      )}
    </Box>
  );
};
