import { Flex, Icon, Text } from '@chakra-ui/react';
import { useScrubberData } from 'hooks/useScrubberData';
import React, { useState } from 'react';
import Waveform from '../../waveformaudio/components/Waveform';

const getBackgroundColor = (droptype = '') => {
  if (droptype === 'temp') return 'dropMeHere';
  if (droptype === 'audios') return 'studio';
  return 'blushPink';
};

const getActionDragAnchorColors = (droptype = '') => {
  if (droptype === 'temp') return 'rubyRed';
  if (droptype === 'audios') return 'eastSide';
  return 'tapestry';
};

export const TextRender = ({ action, row, collision }) => {
  const { sortedScrubberData } = useScrubberData();
  const { id, droptype, src } = action;
  const rowInfo = sortedScrubberData.find((f) => f.uniqueId === id);
  const hasCollision = collision.rowId === row.id && collision.actionId === id;
  if (!rowInfo && droptype !== 'temp') return;
  return (
    <>
      {droptype === 'audios' && (
        <Flex
          flexDir="column"
          justify={'flex-start'}
          width="100%"
          height="100%"
          className="effect0-text"
          backgroundColor={
            hasCollision ? 'rubyRed' : getBackgroundColor(droptype)
          }
          data-testid="dragg-layer-flexitem"
        >
          <Waveform audio={src} uniqueId={id} />
          <Text
            noOfLines={1}
            fontSize="xx-small"
            color="white"
            textOverflow="ellipsis"
            position={'absolute'}
            top={0}
            zIndex={1001}
          >
            {rowInfo?.name || rowInfo?.text || rowInfo?.droptype}
          </Text>
        </Flex>
      )}
      {droptype !== 'audios' && (
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDir="row"
          width="100%"
          height="100%"
          className="effect0-text"
          backgroundColor={
            hasCollision ? 'rubyRed' : getBackgroundColor(droptype)
          }
          data-testid="dragg-layer-flexitem"
        >
          <Text
            mt={1}
            noOfLines={1}
            fontSize="small"
            align="center"
            color="white"
            textOverflow="ellipsis"
          >
            {droptype === 'temp' && <>{`Drop here`}</>}
            {droptype !== 'temp' && (
              <>{rowInfo?.name || rowInfo?.text || rowInfo?.droptype}</>
            )}
            {droptype === 'audios' && <Waveform audio={src} />}
          </Text>
        </Flex>
      )}
    </>
  );
};

export const CustomRender = ({ action, row, collision = {} }) => {
  const { droptype, id } = action;
  const hasCollision = collision.rowId === row.id && collision.actionId === id;

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDir="row"
      width="100%"
      height="100%"
      className="effect0"
      px="2"
      sx={{
        '& + div.timeline-editor-action-left-stretch::after': {
          borderLeftColor: hasCollision
            ? 'rubyRed'
            : getActionDragAnchorColors(droptype),
          backgroundColor: hasCollision
            ? 'rubyRed'
            : getBackgroundColor(droptype),
        },
        '& + .timeline-editor-action-left-stretch + .timeline-editor-action-right-stretch::after':
          {
            borderRightColor: hasCollision
              ? 'rubyRed'
              : getActionDragAnchorColors(droptype),
            backgroundColor: hasCollision
              ? 'rubyRed'
              : getBackgroundColor(droptype),
          },
      }}
      border={row.selected ? '1px solid var(--chakra-colors-corn)' : 'none'}
      _hover={{ cursor: 'context-menu' }}
      data-testid="customrender"
      data-rowid={row.id}
      data-actionid={id}
    >
      {action.effectId === 'effect0' && (
        <>
          <TextRender action={action} row={row} collision={collision} />
        </>
      )}
    </Flex>
  );
};
