const Modal = {
  baseStyle: {
    body: {
      bg: 'brand.primary.900',
    },
    closeButton: {
      color: 'white',
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        bgColor: 'gray.500',
      },
    },
  },
};

export default Modal;
