import { Icon } from '@chakra-ui/react';
import React from 'react';

export const Webcam = (props) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      d="M9.99967 15C13.6816 15 16.6663 12.0152 16.6663 8.33332C16.6663 4.65142 13.6816 1.66666 9.99967 1.66666C6.31778 1.66666 3.33301 4.65142 3.33301 8.33332C3.33301 12.0152 6.31778 15 9.99967 15Z"
      stroke="#29AEB7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10.8333C11.3807 10.8333 12.5 9.71403 12.5 8.33331C12.5 6.9526 11.3807 5.83331 10 5.83331C8.61929 5.83331 7.5 6.9526 7.5 8.33331C7.5 9.71403 8.61929 10.8333 10 10.8333Z"
      stroke="#29AEB7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99967 18.3333V15V18.3333ZM5.83301 18.3333H9.99967H5.83301ZM14.1663 18.3333H9.99967H14.1663Z"
      stroke="#29AEB7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
