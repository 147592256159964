const Tabs = {
  parts: ['TabList', 'TabPanels', 'Tabs', 'Tab', 'TabPanel'],
  baseStyle: {
    Tabs: { backgroundColor: 'shark' },
    TabList: { backgroundColor: 'shark' },
    TabPanels: { backgroundColor: 'shark' },
    Tab: { backgroundColor: 'shark' },
  },
  sizes: {},
  variants: {},
  // The default size and variant values
  defaultProps: {
    Tab: { backgroundColor: 'shark' },
  },
};

export default Tabs;
