const constants = {
  endpoints: {
    users: 'users',
    me: 'users/me',
  },
};

export const EditorApiConstants = {
  endpoints: {
    EDITOR_PROJECT_ASSET: 'projectassets',
    PROJECT_MEDIA_FILES: 'userprojects',
    FILE_UPLOAD: 'fileupload/s3upload',
    PRESIGNED_S3_URL: 'fileupload/s3-presigned-url',
    CREATE_MULTIPART_UPLOAD: 'fileupload/s3-create-multipart-upload',
    UPLOAD_MULITPART: 'fileupload/s3-upload-part',
    COMPLETE_MULTIPART_UPLOAD: 'fileupload/s3-complete-multipart-upload',
    CONVERT_VIDEO_TO_MP4: 'media/video-to-webm',
    GET_THUMBNAIL: 'media/getthumbnail',
    GET_AUDIO_META: 'media/get-audio-meta-data',
    GET_TEXTS: 'typography/gettexts',
    STIPOP_STICKERS: 'stipop/getstickers',
    FONT_FAMILY: 'typography/getfontfamily',
    QUEUED_USER_PROJECTS: 'remotionprojects/get-user-projects',
    LOTTIE_FILE_UPLOAD: 'fileupload/lottie-upload',
    CREATE_AI_IMAGE_WITH_PROMPT: 'media/create-ai-image',
  },
};

export const DROPTYPES = {
  NONE: 'none',
  VIDEOS: 'videos',
  AUDIOS: 'audios',
  IMAGES: 'images',
  SHAPES: 'shapes',
  TITLES: 'titles',
  EMOJIS: 'emojis',
  GIFS: 'gifs',
  TRANSITIONS: 'transitions',
};

export const PanelResizer = {
  PANEL_RESIZE: 'PANEL_RESIZE',
};

export * from './animation';
export * from './apiConstants';
export * from './canvascolors';
export * from './canvassize';
export * from './dragTypes';
export * from './emoji';
export * from './imageConstants';
export * from './ItemTypes';
export * from './overlaylist';
export * from './reducerNames';
export * from './sagaActionTypes';
export * from './scrubberConstants';
export * from './side-menus';
export * from './titleSize';
export * from './PlayerEvents';
export * from './backgroundColor';
export * from './overlays';
export default constants;
