import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from '@chakra-ui/react';

function AccordionView({ header, children, defaultIndex, ...props }) {
  return (
    <Accordion
      allowToggle
      borderRadius={'5px'}
      border="1px solid var(--chakra-colors-charade)"
      bg="charadeDark2"
      size="xs"
      data-testid="accordion"
      defaultIndex={defaultIndex || 0}
    >
      <AccordionItem data-testid="accordion-item">
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <Text fontSize="xs" color="white">
              {header}
            </Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel px={1} pb={4} {...props}>
          {children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export default AccordionView;
