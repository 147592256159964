import { Icon } from '@chakra-ui/react';
import React from 'react';

export const GroupEditIcon = (props) => (
  <Icon viewBox="0 0 29 27" {...props}>
    <g opacity="0.75">
      <path
        d="M20.742 4.61765L23.9242 7.79834L20.742 4.61765ZM22.7884 1.81557L14.1838 10.4201C13.7392 10.8641 13.436 11.4297 13.3124 12.0458L12.5176 16.0243L16.4961 15.228C17.1121 15.1048 17.677 14.8028 18.1217 14.3581L26.7263 5.7535C26.9849 5.49494 27.19 5.18797 27.3299 4.85013C27.4698 4.5123 27.5419 4.15021 27.5419 3.78454C27.5419 3.41887 27.4698 3.05678 27.3299 2.71894C27.19 2.38111 26.9849 2.07414 26.7263 1.81557C26.4677 1.557 26.1608 1.3519 25.8229 1.21196C25.4851 1.07202 25.123 1 24.7573 1C24.3916 1 24.0296 1.07202 23.6917 1.21196C23.3539 1.3519 23.0469 1.557 22.7884 1.81557V1.81557Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.5368 18.0292V22.5365C26.5368 23.3335 26.2202 24.0978 25.6567 24.6613C25.0932 25.2249 24.3289 25.5414 23.5319 25.5414H5.00491C4.20796 25.5414 3.44365 25.2249 2.88012 24.6613C2.31659 24.0978 2 23.3335 2 22.5365V7.00955C2 6.2126 2.31659 5.44829 2.88012 4.88476C3.44365 4.32123 4.20796 4.00464 5.00491 4.00464H9.51227"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </Icon>
);
