import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faSnapchat,
  faTiktok,
  faUncharted,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faBell,
  faCaretSquareRight,
  faCaretUp,
  faCheckCircle,
  faChevronRight,
  faCircle,
  faCopy,
  faCreditCard,
  faEdit,
  faEllipsisH,
  faEye,
  faFileExport,
  faFont,
  faGlobe,
  faHeart,
  faHome,
  faImage,
  faLock,
  faMusic,
  faPause,
  faPhotoVideo,
  faPlay,
  faPlus,
  faQuestionCircle,
  faSquare,
  faStar,
  faTimesCircle,
  faTrash,
  faUserCircle,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';

const initFonts = () => {
  library.add(
    faHome,
    faVideo,
    faPlay,
    faEye,
    faPhotoVideo,
    faTrash,
    faPlus,
    faEllipsisH,
    faCopy,
    faFileExport,
    faChevronRight,
    faAngleLeft,
    faAngleRight,
    faArrowLeft,
    faUserCircle,
    faLock,
    faBell,
    faCreditCard,
    faTimesCircle,
    faCheckCircle,
    faFacebook,
    faInstagram,
    faTiktok,
    faSnapchat,
    faYoutube,
    faLinkedin,
    faGlobe,
    faQuestionCircle,
    faEdit,
    faHeart,
    faStar,
    faCaretSquareRight,
    faUncharted,
    faImage,
    faMusic,
    faFont,
    faPause,
    faSquare,
    faCircle,
    faCaretUp
  );
};

export default initFonts;
