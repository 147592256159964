import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _merge from 'lodash/merge';
import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

const defaultStyles = {
  fallbackWrapper: {
    padding: '10rem 1rem 1rem 1rem',
  },
};

const ErrorBoundary = ({ children, Component = ErrorFallback, ...props }) => {
  return (
    <ReactErrorBoundary FallbackComponent={Component} {...props}>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;

export const ErrorFallback = ({ styles = {} }) => {
  const combinedStyles = _merge(defaultStyles, styles);

  return (
    <ErrorFallbackWrapper overrideStyles={combinedStyles.fallbackWrapper}>
      <ErrorFallbackIcon>
        <FontAwesomeIcon icon={['fas', 'times-circle']} />
      </ErrorFallbackIcon>

      <ErrorFallbackTitle>Something went wrong</ErrorFallbackTitle>
      <ErrorFallbackText>
        Please try again or contact support if the problem persists.
      </ErrorFallbackText>
    </ErrorFallbackWrapper>
  );
};

const ErrorFallbackWrapper = ({ children, overrideStyles }) => {
  return (
    <Flex
      height={'100%'}
      alignItems={'center'}
      direction={'column'}
      {...overrideStyles}
    >
      {children}
    </Flex>
  );
};

const ErrorFallbackIcon = ({ children }) => {
  return (
    <Box color={'red.600'} fontSize={'2rem'}>
      {children}
    </Box>
  );
};

const ErrorFallbackTitle = ({ children }) => {
  return (
    <Heading as={'h5'} mb={'0.5rem'} fontWeight={700}>
      {children}
    </Heading>
  );
};

const ErrorFallbackText = ({ children }) => {
  return (
    <Text textAlign={'center'} color={'white'}>
      {children}
    </Text>
  );
};
