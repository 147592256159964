import {
  useEditMenuTabObservable,
  useEffectsFilterObservable,
  useTitlesFilterObservable,
  useElementsFilterObservable,
  useTransitionFilterObservable,
  useMediaMenuDrawerObservable,
} from 'observeables';

export const useFilterRepository = () => {
  const editMenuTabObservable = useEditMenuTabObservable();
  const effectsFilterObservable = useEffectsFilterObservable();
  const titlesFilterObservable = useTitlesFilterObservable();
  const elementsFilterObservable = useElementsFilterObservable();
  const transitionFilterObservable = useTransitionFilterObservable();
  const mediaMenuObservable = useMediaMenuDrawerObservable();

  const update = async (payload, filterName) => {
    try {
      if (filterName === 'EDIT_MENU') {
        editMenuTabObservable.update(payload);
      } else if (filterName === 'EFFECTS_MENU') {
        effectsFilterObservable.update(payload);
      } else if (filterName === 'EFFECTS_MENU') {
        titlesFilterObservable.update(payload);
      } else if (filterName === 'ELEMENTS_FILTER') {
        elementsFilterObservable.update(payload);
      } else if (filterName === 'TRANSITION_FILTER') {
        transitionFilterObservable.update(payload);
      } else if (filterName === 'MEDIA_MENU') {
        mediaMenuObservable.update(payload);
      }
    } catch (error) {
      editMenuTabObservable.error('Error in updating edit area dimensons');
    } finally {
    }
  };

  const getEditMenuTabObservable = () => editMenuTabObservable.getObservable();
  const getEffectsFilterObservable = () =>
    effectsFilterObservable.getObservable();

  const getTitlesFilterObservable = () =>
    titlesFilterObservable.getObservable();

  const getElementsFilterObservable = () =>
    elementsFilterObservable.getObservable();

  const getTransitionFilterObservable = () =>
    transitionFilterObservable.getObservable();

  const getMediaMenuObservable = () => mediaMenuObservable.getObservable();

  return {
    update,
    getEditMenuTabObservable,
    getEffectsFilterObservable,
    getTitlesFilterObservable,
    getElementsFilterObservable,
    getTransitionFilterObservable,
    getMediaMenuObservable,
  };
};
