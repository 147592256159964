import { Icon } from '@chakra-ui/react';
import React from 'react';

export const Addsquare = (props) => (
  <Icon viewBox="0 0 19 19" {...props}>
    <g>
      <path
        d="M9.20833 14.75H13.4583C17 14.75 18.4167 13.3333 18.4167 9.79168V5.54168C18.4167 2.00001 17 0.583344 13.4583 0.583344H9.20833C5.66667 0.583344 4.25 2.00001 4.25 5.54168V9.79168C4.25 13.3333 5.66667 14.75 9.20833 14.75Z"
        stroke="#A8A8A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.37533 17.5833H10.6253C14.167 17.5833 15.5837 16.1667 15.5837 12.625V8.37501C15.5837 4.83334 14.167 3.41667 10.6253 3.41667H6.37533C2.83366 3.41667 1.41699 4.83334 1.41699 8.37501V12.625C1.41699 16.1667 2.83366 17.5833 6.37533 17.5833Z"
        fill="#262730"
        stroke="#A8A8A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.66602 10.5H11.3327"
        stroke="#A8A8A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 13.3333V7.66667"
        stroke="#A8A8A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </Icon>
);
