import { Box, Flex, Grid, Icon, Text } from '@chakra-ui/react';
import { VectorTransitions } from 'assets/icons';
import { map, take, union, find } from 'lodash';
import { AiOutlineStop } from 'react-icons/ai';
import { BsThreeDots } from 'react-icons/bs';
import { ANIMATION } from '../../../constants';
import { useEditorRepository, useSidebarRepository } from 'repositories';
import { useScrubberData } from 'hooks/useScrubberData';

export const AnimationGrid = () => {
  const actionType = 'ANIMATION';
  const sidebarMenuRepo = useSidebarRepository();
  const editorRepo = useEditorRepository();
  const { selectedKeyFrame, sortedScrubberData } = useScrubberData();
  const { uniqueId } = selectedKeyFrame || {};
  const selectedItem = find(sortedScrubberData, (f) => f.uniqueId === uniqueId);
  const { transformation = {} } = selectedItem || {};
  const { animation = '' } = transformation;

  const handleOnClickAnimation = (animName) => {
    let animation = animName;
    if (animation === 'VIEW') {
      sidebarMenuRepo.update('Elements/Animation');
    } else {
      const payload = { uniqueId, animation: animation };
      editorRepo.updateTransformation(payload);
    }
  };

  const animList = union(
    take(
      map(ANIMATION, (f) => ({
        name: f,
        icon: f === 'NONE' ? AiOutlineStop : VectorTransitions,
      })),
      5
    ),
    [{ name: 'VIEW', icon: BsThreeDots }]
  );

  return (
    <Flex
      wrap="wrap" // Allows wrapping of items
      gap={2} // Set the gap between items
      data-testid="animations-grid"
      justifyContent="start" // Center items horizontally when wrapping
    >
      {map(animList, (value) => {
        return (
          <Box>
            <Box
              key={value.name}
              w="88px"
              h="50px"
              bgColor={animation === value.name ? 'azure' : 'charadeDark3'}
              fontSize="md"
              justifyContent="center"
              display="flex"
              flexDir="column"
              alignItems="center"
              borderRadius={5}
              color="azure"
              data-testid="shape-grid-box"
              cursor="pointer"
              onClick={() => handleOnClickAnimation(value.name)}
            >
              <Icon as={value.icon} color="alabaster" />
            </Box>
            <Text mt={1} fontSize="xx-small" align="center" color="pearl">
              {value.name.replace(/_/gi, ' ')}
            </Text>
          </Box>
        );
      })}
    </Flex>
  );
};

export default AnimationGrid;
