export const OverlayList = [
  {
    id: '7c7d11ef-c977-41da-8d48-8abcdddfeab0',
    text: 'Normal',
  },
  {
    id: 'b6da67e3-56db-432c-b7fc-8917429e0222',
    text: 'twenties',
  },
  {
    id: '145d92e4-1e62-4520-9088-d567e5d7422f',
    text: 'ocean',
  },
  {
    id: 'f4002c85-fb0b-45ce-8c22-cb4403ee7423',
    text: 'cosmic',
  },
  {
    id: '7536e2db-c579-4000-9d54-7defc4ce484d',
    text: 'lix',
  },
  {
    id: 5,
    text: 'horizontal_lines',
  },
  {
    id: '64b7cf99-9476-4948-95de-9121e4c13b0c',
    text: 'extreme_offset_blue',
  },
  {
    id: 'a71d05b4-2671-4d6f-b06b-3acbd3900271',
    text: 'extreme_offset_green',
  },
  {
    id: '85029e28-cd77-4e51-a423-cf75df72f70a',
    text: 'offset_green',
  },
  {
    id: 'e71e1151-dd72-4779-a97c-c6d243c6b467',
    text: 'extra_offset_blue',
  },
  {
    id: 'ad6d2b85-1d8e-4a79-b6dc-880de8becc60',
    text: 'extra_offset_red',
  },
  {
    id: '7f5960cf-d09e-4a2d-8e7e-f8db74dd48cc',
    text: 'extra_offset_green',
  },
  {
    id: '4f83477e-9685-4aeb-92d5-98afcfd1de7f',
    text: 'extreme_offset_red',
  },
  {
    id: 'b24d41c0-2c7c-4a74-82b0-4b11e1872544',
    text: 'specks_redscale',
  },
  {
    id: '1ca0f74d-69b8-46c0-8d5b-a9e744aac83d',
    text: 'eclectic',
  },
  {
    id: 'baccd5bd-0e81-4a9b-a423-702e23b9dd35',
    text: 'pane',
  },
  {
    id: '08e7afec-bccf-4e05-9697-d61de1750f99',
    text: 'diagonal_lines',
  },
  {
    id: 'aff75f10-ea55-45d9-b2bf-8ae59106674e',
    text: 'green_specks',
  },
  {
    id: '2ce16ed5-cc8b-4121-92a0-120d2a41a437',
    text: 'casino',
  },
  {
    id: '35acdbb4-7df7-475d-82c4-6c5db1ac1119',
    text: 'yellow_casino',
  },
  {
    id: 'b59bc9b7-75eb-4357-b4aa-d9c82b57b0a1',
    text: 'neue',
  },
  {
    id: '947bbdcc-576f-498d-9554-0571a34c7e50',
    text: 'sunset',
  },
  {
    id: 'd2e468f1-027d-420e-a73b-81ea0dd983a0',
    text: 'specks',
  },
  {
    id: '84898f3c-013d-457c-8bbc-8e4f3f92155a',
    text: 'wood',
  },
  {
    id: '5b4b4947-a4a9-4d3e-bb43-e2cb95ffeceb',
    text: 'ryo',
  },
  {
    id: '458e626c-e1a6-4ab7-ac6b-a22804569913',
    text: 'bluescale',
  },
  {
    id: '5cd5632c-e8f2-4439-89cf-9b75b0ae62f9',
    text: 'solange',
  },
  {
    id: '5aed5d87-5692-4544-93b7-dc6126018fd5',
    text: 'evening',
  },
  {
    id: 'b8164396-1667-414a-8840-7699da1dabdc',
    text: 'crimson',
  },
  {
    id: '80277ac8-7865-4a60-8d97-eed54bb6bd42',
    text: 'teal_min_noise',
  },
  {
    id: '8924efd2-07e2-494f-a02c-e3dd69e40e48',
    text: 'phase',
  },
  {
    id: '2377b292-88f3-4824-998b-1d65c8504a2d',
    text: 'dark_purple_min_noise',
  },
  {
    id: '1e77e08e-5bf4-4676-a672-a6e34f8b0e16',
    text: 'coral',
  },
  {
    id: 'ff027847-820e-4d27-938f-68f68aac3f2a',
    text: 'darkify',
  },
  {
    id: '21dc00a5-6dda-4cdf-b431-70c37137411c',
    text: 'incbrightness',
  },
  {
    id: '8ac7ece5-e793-4cde-b759-00e7b80d5395',
    text: 'incbrightness2',
  },
  {
    id: '31782243-7566-4bde-ae36-9f522d6cb835',
    text: 'invert',
  },
  {
    id: '2042b30a-e3b6-45b8-810c-57a7688958dd',
    text: 'sat_adj',
  },
  {
    id: 'd621c7af-e17e-4643-b7a4-02a4157028ec',
    text: 'lemon',
  },
  {
    id: '86b8e6f1-0f41-4817-b230-19641d25c0ae',
    text: 'lemon',
  },
  {
    id: '8236cc09-7b97-4ed9-a862-251f117194e0',
    text: 'pink_min_noise',
  },
  {
    id: 'c097e64e-4b3a-4aaa-b110-4133b970bc6a',
    text: 'frontward',
  },
  {
    id: '6e3b512a-d882-440c-9a35-34f3728457cd',
    text: 'vintage',
  },
  {
    id: '78c5e175-1a97-4b01-a8c8-f9464855d5f7',
    text: 'perfume',
  },
  {
    id: '3a93cb67-33f2-403f-beb9-7cf88b90339a',
    text: 'serenity',
  },
  {
    id: 'cf5f22d4-5b8a-4f3d-b9bc-2957ba813d23',
    text: 'pink_aura',
  },
  {
    id: '499160a5-c4b9-4d7e-a359-e7b4ab9a790d',
    text: 'haze',
  },
  {
    id: '8b812667-a645-4d5f-b81e-0e0c4ae170af',
    text: 'cool_twilight',
  },
  {
    id: '636ca89e-a791-4ef8-9dff-d0ded61afda7',
    text: 'blues',
  },
  {
    id: 'c3af6183-2c9e-479c-81c7-9040889cfb56',
    text: 'horizon',
  },
  {
    id: 'f7a8da1d-35a1-43a4-8dfb-243a1f2360e4',
    text: 'mellow',
  },
  {
    id: 'a0d458ea-7d3f-4261-9831-7f51b2b8a76f',
    text: 'solange_dark',
  },
  {
    id: '595c772e-7541-4e1a-9758-213d611d11f7',
    text: 'solange_grey',
  },
  {
    id: '190bccac-473e-42f1-ba91-366551a6db54',
    text: 'zapt',
  },
  {
    id: '6256fe8e-78db-4605-8ea2-ce035d32fece',
    text: 'eon',
  },
  {
    id: 'd27a14a2-7e42-49ca-a8c6-2e3c6e981e63',
    text: 'aeon',
  },
  {
    id: 'c27f8e3d-cd73-4d22-b902-9722abad9ba1',
    text: 'matrix',
  },
  {
    id: '12f94a12-b174-4566-adad-415e1598c9f2',
    text: 'min_noise',
  },
  {
    id: '2e14bb2a-123c-4fcd-a6b2-b99bcfdb7eaa',
    text: 'red_min_noise',
  },
  {
    id: '733db2cc-3c06-452d-9283-d2850b2c6101',
    text: 'red_min_noise',
  },
  {
    id: 'cc427aa0-39c7-4a85-9afd-eaf1be526bf8',
    text: 'purplescale',
  },
  {
    id: '04cbb934-cbdf-4d14-857a-812f0e23776b',
    text: 'radio',
  },
  {
    id: 'b1ed1318-b494-4f20-b9b1-831838475435',
    text: 'pixel_blue',
  },
  {
    id: 'c847fd2e-7557-4aa2-8965-e548b83369f7',
    text: 'greyscale',
  },
  {
    id: 'ca00c74e-060a-4f18-8225-28697b3c7484',
    text: 'grime',
  },
  {
    id: 'ac4f0c6e-623d-4df3-a7f8-38410857bb2b',
    text: 'redgreyscale',
  },
  {
    id: '5daf9bfd-ca02-4c39-a91f-1c73fc90b007',
    text: 'retroviolet',
  },
  {
    id: '6695b260-9ef9-4663-b0cd-995bd642884f',
    text: 'greengreyscale',
  },
  {
    id: '43846778-bba3-48c4-bf84-1e8dc085270b',
    text: 'warmth',
  },
  {
    id: '83fc79d5-e1fa-4aea-acb5-799c1db0a8a1',
    text: 'green_med_noise',
  },
  {
    id: '2430beb7-ddd3-4a56-9a48-e03f9687ff25',
    text: 'blue_min_noise',
  },
  {
    id: 'e4708534-ef18-4647-9dbe-d274fb917155',
    text: 'rosetint',
  },
  {
    id: 'fe91d403-d121-47a5-81ae-abeeb98e84c0',
    text: 'purple_min_noise',
  },
];
