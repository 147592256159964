import React, { useContext } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ApiContext } from '../providers/api-provider';

export function useScheduleVideoGeneration() {
  /** Axios client */
  const _axios = useContext(ApiContext);
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload) =>
      _axios
        .post(
          `https://lyddjmoed1.execute-api.ap-southeast-2.amazonaws.com/dev/remotion/generate-video`,
          JSON.stringify(payload),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(({ data }) => {
          return data?.data;
        }),
    onSuccess: async (data, variables, context) => {
      /// console.log('useScheduleVideoGeneration Mutate successful');
    },
    onSettled: async (data, error, variables, context) => {
      /// console.log('useScheduleVideoGeneration onSettled');
    },
  });
}

export function useGenerateVideoThumbnail() {
  const _axios = useContext(ApiContext);
  return useMutation({
    mutationFn: (payload) =>
      _axios
        .post(
          `https://lyddjmoed1.execute-api.ap-southeast-2.amazonaws.com/dev/remotion/generate-cover-photo`,
          JSON.stringify(payload),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(({ data }) => {
          return data?.data;
        }),
    onSuccess: async (data, variables, context) => {
      /// console.log('useGenerateVideoThumbnail Mutate successful');
    },
    onSettled: async (data, error, variables, context) => {
      /// console.log('useGenerateVideoThumbnail onSettled');
    },
  });
}

export function useGeneratedVideo({ id }) {
  /** Axios client */
  const _axios = useContext(ApiContext);

  return useQuery({
    queryKey: ['GET_GENERATED_VIDEO'],
    queryFn: () =>
      _axios
        .get(
          `https://r4dw7m08ab.execute-api.ap-southeast-2.amazonaws.com/dev/api/video/remotion/${id}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(({ data }) => {
          return data;
        }),
    useErrorBoundary: true,
    staleTime: Infinity,
  });
}

export function useGetRemotionProgress() {
  /** Axios client */
  const _axios = useContext(ApiContext);
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload) =>
      _axios
        .post(
          `https://lyddjmoed1.execute-api.ap-southeast-2.amazonaws.com/dev/remotion/get-render-progress`,
          JSON.stringify(payload),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(({ data }) => {
          return data?.data;
        }),
    onSuccess: async (data, variables, context) => {
      /// console.log('useGetRemotionProgress Mutate successful');
    },
    onSettled: async (data, error, variables, context) => {
      /// console.log('useGetRemotionProgress onSettled');
    },
  });
}
