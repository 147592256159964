const Menu = {
  parts: ['menu', 'item'],
  baseStyle: {
    menu: {},
    item: {
      _hover: {
        backgroundColor: 'azure',
        fontWeight: '700',
      },
      _focus: {
        backgroundColor: 'azure',
        fontWeight: '700',
      },
      _active: {
        backgroundColor: 'azure',
        fontWeight: '700',
      },
    },
  },
};

export default Menu;
