import { useScrubberBarObserveable } from 'observeables';

export const useScrubberBarRepository = () => {
  const scrubberBarObservable = useScrubberBarObserveable();

  const list = async () => {
    try {
      // editAreaObservable.list();
    } catch (error) {
      scrubberBarObservable.error('Error in updating edit area dimensons');
    } finally {
      scrubberBarObservable.updating(false);
    }
  };

  const zoomInScale = async () => {
    try {
      scrubberBarObservable.zoomInScale();
    } catch (error) {
      scrubberBarObservable.error('Error in updating edit area dimensons');
    } finally {
    }
  };

  const zoomOutScale = async () => {
    try {
      scrubberBarObservable.zoomOutScale();
    } catch (error) {
      scrubberBarObservable.error('Error in updating edit area dimensons');
    } finally {
    }
  };

  const update = async (payload) => {
    try {
      scrubberBarObservable.updating(true);
      scrubberBarObservable.update(payload);
    } catch (error) {
      scrubberBarObservable.error('Error in updating edit area dimensons');
    } finally {
      scrubberBarObservable.updating(false);
    }
  };

  const getScrubberBarObservable = () => scrubberBarObservable.getObservable();

  return {
    list,
    update,
    zoomInScale,
    zoomOutScale,
    getScrubberBarObservable,
  };
};
