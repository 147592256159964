import { Icon } from '@chakra-ui/react';
import React from 'react';

export const VectorEffectsIcon = (props) => (
  <Icon viewBox="undefined" {...props}>
    <rect
      id="backgroundrect"
      width="100%"
      height="100%"
      x="0"
      y="0"
      fill="none"
      stroke="none"
    />

    <g className="currentLayer">
      <title>Layer 1</title>
      <path
        d="M11.309 20.18L14.042 14.185L20.386 12.325L15.503 7.885L15.687 1.3035L9.936 4.555L3.706 2.347L5.0345 8.797L1 14.0135L7.572 14.7485L11.3095 20.18H11.309Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        id="svg_1"
      />
      <path
        fill="currentColor"
        fillOpacity="1"
        stroke="currentColor"
        strokeOpacity="1"
        strokeWidth="2"
        strokeDasharray="none"
        strokeLinejoin="round"
        strokeLinecap="butt"
        strokeDashoffset=""
        fillRule="nonzero"
        opacity="1"
        markerStart=""
        markerMid=""
        markerEnd=""
        d="M16.056189681917658,16.61797039449513 L22.235965062145056,23.359543536561404 "
        id="svg_2"
      />
    </g>
  </Icon>
);
