import { Card, CardContent, CardPhoto } from 'components';
import React, { useState } from 'react';
import TemplateActions from './template-actions';

const Template = ({ coverPhoto, title, previewUrl }) => {
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => setIsActive(true);

  const handleMouseLeave = () => {
    if (isOpen) return;
    setIsActive(false);
  };

  return (
    <Card
      padding={0}
      borderRadius={0}
      cursor={'pointer'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <CardContent>
        <CardPhoto src={coverPhoto} title={title} />
        <TemplateActions
          isActive={isActive}
          isOpen={isOpen}
          setIsActive={setIsActive}
          setIsOpen={setIsOpen}
          title={title}
        />
      </CardContent>
    </Card>
  );
};

export default Template;
