import { Box, Button, Grid, Text } from '@chakra-ui/react';
import map from 'lodash/map';
import { FiMoreHorizontal } from 'react-icons/fi';
import data from 'svgmoji/emoji.json';
import EmojiRender from './Emoji';
import { useSidebarRepository } from 'repositories';
import useScreenSize from 'hooks/useScreenSize';

const fewEmojis = data.slice(26, 31);

export const EmojiGrid = ({ onClick }) => {
  const sidebarMenuRepo = useSidebarRepository();

  return (
    <Grid
      templateColumns="repeat(auto-fit, minmax(90px, 1fr))"
      gap={3}
      data-testid="emoji-grid"
      justifyContent="center"
      alignItems="center"
    >
      {map(fewEmojis, (value) => {
        const emojiRawValue = {
          id: value.hexcode,
          name: value.hexcode,
          droptype: 'emojis',
          type: 'emojis',
        };
        return (
          <Box
            key={value.hexcode}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              key={value.hexcode}
              w="90px"
              h="50px"
              bg="charadeDark3"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius={5}
              color="azure"
              cursor="pointer"
              data-testid="emoji-grid-box"
              onClick={() => onClick(emojiRawValue)}
            >
              <EmojiRender emojiCode={value.hexcode} />
            </Box>
          </Box>
        );
      })}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          w="90px"
          h="50px"
          bg="charadeDark3"
          color="azure"
          fontSize="sm"
          borderRadius={5}
          onClick={() => sidebarMenuRepo.update('Elements/Emojis')}
          data-testid="view-all-button"
        >
          <FiMoreHorizontal color="white" />
        </Button>
        <Text fontSize="xx-small" align="center" color="pearl" mt={1}>
          View All
        </Text>
      </Box>
    </Grid>
  );
};
