import { useContext } from 'react';
import { useEditorObservable } from 'observeables';
import { useAuth0 } from '@auth0/auth0-react';
import { EditorApiConstants } from '../constants';
import { map } from 'lodash';
import { nanoid } from 'nanoid';
import { EditorApiContext } from 'providers/api-provider';

export const useTemplateRepository = () => {
  const useEditorObserveable = useEditorObservable();
  const { user } = useAuth0();
  /** Axios client */
  const _axios = useContext(EditorApiContext);

  const addTemplate = async (payload, projectId) => {
    const templateData = map(payload, (t) => {
      return {
        id: nanoid(10),
        src: t.s3Url,
        name: t.name || `template${nanoid(10)}`,
        type: t.file_type,
        duration: t.duration,
        width: t.width,
        height: t.height,
        s3Url: t.s3Url,
        s3Key: t.s3Key || null,
      };
    });
    const data = { data: { videos: templateData }, id: projectId, projectId };

    const editorRemoteData = await _axios
      .post(
        `/${EditorApiConstants.endpoints.PROJECT_MEDIA_FILES}/mediafiles`,
        JSON.stringify(data),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(({ data }) => {
        /// return data?.data || {};
        /** create thumbnail requests */
        const allPromises = map(templateData, (td) => {
          const metaData = {
            projectId,
            uniqueId: td.id,
            id: td.id,
            src: td.s3Url,
            s3Key: td.s3Key,
            s3Url: td.s3Url,
            user: user.email,
            fileName: td.name,
            type: td.type,
          };

          return _axios
            .post(
              `/${EditorApiConstants.endpoints.GET_THUMBNAIL}`,
              JSON.stringify(metaData),
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            )
            .then(({ data }) => {
              return data?.data || {};
            });
        });

        Promise.all(allPromises).then((data) => {
          console.log('all promises fullfilled', data);
        });
      });
  };

  const getObservable = () => useEditorObserveable.getObservable();

  return {
    addTemplate,
    getObservable,
  };
};
