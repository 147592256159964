import { IconButton as ChakraIconButton } from '@chakra-ui/react';
import React from 'react';

const IconButton = React.forwardRef(
  (
    {
      onClick = () => null,
      variant = 'solid',
      isRound = false,
      size = 'md',
      ariaLabel = 'Button',
      icon,
      ...rest
    },
    ref: null
  ) => {
    return (
      <ChakraIconButton
        ref={ref}
        onClick={onClick}
        variant={variant}
        isRound={isRound}
        size={size}
        aria-label={ariaLabel}
        icon={icon}
        {...rest}
      />
    );
  }
);

export default IconButton;
