import { useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EditorApiContext } from '../providers/api-provider';
import { EditorApiConstants } from '../constants';
import axiosV2 from 'axios';

const chunkSize = 1025 * 1025 * 5; // 5MB

export function useLottieFileUpload(id) {
  /** Axios client */
  const queryClient = useQueryClient();
  const _axios = useContext(EditorApiContext);
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload) => {
      const { file, user, projectId, uniqueId, progressCallback } = payload;
      let totalUploaded = 0;
      const filesize = file.size;
      // Create a FormData object
      const formData = new FormData();

      // Append the file and additional fields to the FormData
      formData.append('file', file);
      formData.append('projectId', projectId);
      formData.append('uniqueId', uniqueId);
      formData.append('user', user);

      const completeResponse = await _axios.post(
        `/${EditorApiConstants.endpoints.LOTTIE_FILE_UPLOAD}`,
        formData,
        {
          headers: {
            // 'Access-Control-Allow-Origin': 'http://localhost:3000', // (* or a specific host)
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
          },
          // eslint-disable-next-line no-loop-func
          onUploadProgress: (event) => {
            const totalPercent = Math.round(
              (100 * (totalUploaded + event.loaded)) / filesize
            );
            progressCallback && progressCallback(totalPercent);
          },
        }
      );
      const { s3Key, s3Url } = completeResponse.data;
      return { s3Key, s3Url };
    },
    onSuccess: async (data, variables, context) => {
      console.log('useLottieFileUpload Mutate successful');
      queryClient.invalidateQueries({ queryKey: ['USER_PROJECT_MEDIA_ASSET'] });
    },
    onSettled: async (data, error, variables, context) => {
      console.log('useLottieFileUpload onSettled');
    },
  });
}

export function useFileUploadAws(id) {
  /** Axios client */
  const _axios = useContext(EditorApiContext);
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload) => {
      const { file, user, projectId, uniqueId, progressCallback } = payload;

      // eslint-disable-next-line no-undef
      // const formUploadData = new FormData();
      // formUploadData.append('file', file);
      // // formUploadData.append('projectId', projectId);
      // formUploadData.append('user', user);
      // formUploadData.append('uniqueId', uniqueId);
      const numberofChunks = Math.ceil(file.size / chunkSize);

      const presignedBody = {
        filename: file.name,
        fileType: file.type,
        projectId,
        user,
        uniqueId,
        parts: numberofChunks,
      };

      const presignedUrl = await _axios.post(
        `/${EditorApiConstants.endpoints.CREATE_MULTIPART_UPLOAD}`,
        presignedBody
      );

      const {
        data: { results },
      } = presignedUrl;
      const { s3Key, UploadId, uploadPartSignedUrls } = results;

      const partETags = [];

      let start = 0;
      let chunkEnd = start + chunkSize;
      let totalUploaded = 0;
      const filesize = file.size;

      for (let i = 0; i < numberofChunks; i++) {
        start = i * chunkSize;
        chunkEnd = Math.min(start + chunkSize, file.size);
        const chunk = file.slice(start, chunkEnd);
        const arrayBuffer = await chunk.arrayBuffer();
        // console.log(
        //   'i created a chunk of video' + start + '-' + chunkEnd + 'minus 1	'
        // );
        const uploadResponse = await axiosV2.put(
          uploadPartSignedUrls[i],
          arrayBuffer,
          {
            headers: {
              // 'Access-Control-Allow-Origin': 'http://localhost:3000', // (* or a specific host)
              'Content-Type': 'multipart/form-data',
              Accept: 'application/json',
            },
            // eslint-disable-next-line no-loop-func
            onUploadProgress: (event) => {
              const totalPercent = Math.round(
                (100 * (totalUploaded + event.loaded)) / filesize
              );
              progressCallback && progressCallback(totalPercent);
            },
          }
        );

        const partETag = JSON.parse(uploadResponse.headers.etag);
        partETags.push({
          ETag: partETag,
          PartNumber: i + 1,
        });
        totalUploaded = chunkEnd;
      }
      // complete the multipart upload
      const completeBody = {
        projectId,
        user,
        uniqueId,
        parts: partETags,
        s3Key,
        uploadId: UploadId,
      };

      const completeResponse = await _axios.post(
        `/${EditorApiConstants.endpoints.COMPLETE_MULTIPART_UPLOAD}`,
        completeBody
      );
      const { Location, Key } = completeResponse.data.results;
      return { s3Key: Key, s3Url: Location };
    },
    onSuccess: async (data, variables, context) => {
      console.log('useFileUploadAws Mutate successful');
    },
    onSettled: async (data, error, variables, context) => {
      console.log('useFileUploadAws onSettled');
    },
  });
}

export function useGenerateThumbnail() {
  /** Axios client */
  const _axios = useContext(EditorApiContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) =>
      _axios
        .post(
          `/${EditorApiConstants.endpoints.GET_THUMBNAIL}`,
          JSON.stringify(data),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(({ data }) => {
          return data;
        }),
    onSuccess: async (data, variables, context) => {
      console.log('userProjects Mutate successful');
      queryClient.invalidateQueries({ queryKey: ['USER_PROJECT_MEDIA_ASSET'] });
    },
    onSettled: async (data, error, variables, context) => {
      console.log('userProjects onSettled');
    },
  });
}

export function useGetAudioFileMeta() {
  /** Axios client */
  const _axios = useContext(EditorApiContext);

  return useMutation({
    mutationFn: (data) =>
      _axios
        .post(
          `/${EditorApiConstants.endpoints.GET_AUDIO_META}`,
          JSON.stringify(data),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(({ data }) => {
          return data;
        }),
    onSuccess: async (data, variables, context) => {
      console.log('userProjects Mutate successful');
    },
    onSettled: async (data, error, variables, context) => {
      console.log('userProjects onSettled');
    },
  });
}

export function ConvertVideoToMp4() {
  /** Axios client */
  const _axios = useContext(EditorApiContext);

  return useMutation({
    mutationFn: (data) =>
      _axios
        .post(
          `/${EditorApiConstants.endpoints.CONVERT_VIDEO_TO_MP4}`,
          JSON.stringify(data),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(({ data }) => {
          return data;
        }),
    onSuccess: async (data, variables, context) => {
      console.log('convert to mp4 video successful');
    },
    onSettled: async (data, error, variables, context) => {
      console.log('convert to mp4 video  onSettled');
    },
  });
}
