import { Icon } from '@chakra-ui/react';
import React from 'react';

export const Scissor = (props) => (
  <Icon viewBox="0 0 17 17" {...props}>
    <g>
      <path
        d="M3.89616 7.08333C5.26537 7.08333 6.37533 5.97337 6.37533 4.60417C6.37533 3.23496 5.26537 2.125 3.89616 2.125C2.52695 2.125 1.41699 3.23496 1.41699 4.60417C1.41699 5.97337 2.52695 7.08333 3.89616 7.08333Z"
        stroke="#A8A8A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.89616 14.875C5.26537 14.875 6.37533 13.765 6.37533 12.3958C6.37533 11.0266 5.26537 9.91667 3.89616 9.91667C2.52695 9.91667 1.41699 11.0266 1.41699 12.3958C1.41699 13.765 2.52695 14.875 3.89616 14.875Z"
        stroke="#A8A8A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5832 4.25L6.12695 11.3192"
        stroke="#A8A8A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5832 12.7287L6.12695 5.6525"
        stroke="#A8A8A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </Icon>
);
