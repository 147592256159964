import { isEmpty, get, merge } from 'lodash';
import { nanoid } from 'nanoid';
import React, { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { reducerNames } from '../constants';
import {
  useFileUploadAws,
  useGenerateThumbnail,
  useGetAudioFileMeta,
  useLottieFileUpload,
} from 'api/useMediaUploadApi';
import {
  useGetProjectMediaAssets,
  useCreateProjectMediaAsset,
} from 'api/useProjectMediaAsset';
import { useMediaMenuDrawerRepository } from 'repositories';

const allowedGeneralVideoExtensions = /(mp4|mov|webm)$/i;
const allowedVideoExtensions = /(mp4|mov|webm|mkv|avi|quicktime)$/i;
const allowedAudioExtensions = /(mp3|aac|ogg|mpeg)$/i;
const allowedImageExtensions = /(jpeg|jpg|png|raw)$/i;

export const useMediaUpload = () => {
  const [progress, setProgress] = React.useState(0);
  const [error, setError] = React.useState(null);
  // rrd
  const { id } = useParams();

  // oAuth
  const { user } = useAuth0();

  // tanstack query
  const { mutateAsync: uploadFileAws } = useFileUploadAws(id);
  const { mutateAsync: uploadLottieFileAws } = useLottieFileUpload(id);
  const { mutateAsync: addProjectMediaAsset } = useCreateProjectMediaAsset(id);
  const { mutateAsync: generateThumnailApi } = useGenerateThumbnail();
  const { mutateAsync: getAudioMetaData } = useGetAudioFileMeta();
  const { data: projectMediaAssetData } = useGetProjectMediaAssets(id);
  // end tanstack

  const projectMediaAsset = get(projectMediaAssetData, 'data', {});
  const mediaMenuRepo = useMediaMenuDrawerRepository();

  /**
   * get image file dimensions
   * @param {img} file
   * @returns {object} {width, height}
   */
  async function getImageDimensions(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          resolve({ width: img.width, height: img.height });
        };
      };

      reader.readAsDataURL(file);
    });
  }

  const updateMediaAssets = useCallback(
    (droptype, metadata) => {
      let { videos = [], audios = [], images = [] } = projectMediaAsset;
      if (droptype === 'videos') {
        videos = videos.concat(metadata);
      } else if (droptype === 'audios') {
        audios = audios.concat(metadata);
      } else if (droptype === 'images') {
        images = images.concat(metadata);
      }
      const mergedData = merge(
        {},
        { id: id, projectId: id },
        {
          data: {
            videos,
            audios,
            images,
          },
        }
      );
      addProjectMediaAsset(mergedData);
    },
    [id, addProjectMediaAsset, projectMediaAsset]
  );

  const onMediaUpload = useCallback(
    async (acceptedFiles) => {
      if (!isEmpty(acceptedFiles)) {
        const numberofFiles = acceptedFiles.length;
        const mediaAssets = [];

        for (let i = 0; i < numberofFiles; i++) {
          let acceptedFile = acceptedFiles[i];
          const fileType = acceptedFile?.type;
          const isVideo = allowedVideoExtensions.test(fileType);
          const isAudio = allowedAudioExtensions.test(fileType);
          const isImage = allowedImageExtensions.test(fileType);
          const isLottie = fileType.includes('json');
          setProgress(0);
          setError(false);

          if (isVideo) {
            const file = acceptedFile,
              email = user.email,
              projectId = id,
              uniqueId = nanoid(10);

            try {
              const data = await uploadFileAws({
                file,
                user: email,
                projectId,
                uniqueId,
                progressCallback: (progress) =>
                  setProgress(Math.round(progress)),
              });

              const { s3Key, s3Url } = data;
              // replace s3Url origin with cloudfront url https://d1r0cf5836ptgd.cloudfront.net
              const cloudFrontUrl = s3Url.replace(
                'https://eyecastvideoeditorfiles.s3.ap-southeast-2.amazonaws.com',
                'https://d1r0cf5836ptgd.cloudfront.net'
              );
              const metaData = {
                projectId,
                uniqueId,
                id,
                src: cloudFrontUrl,
                s3Key,
                s3Url: cloudFrontUrl,
                user: email,
                fileName: acceptedFile?.name,
                type: acceptedFile?.type,
              };

              const thumbData = await generateThumnailApi(metaData);
              const {
                duration,
                width,
                height,
                s3Url: thumbUrl,
              } = thumbData.data;

              const finalMetaData = {
                ...metaData,
                id: uniqueId,
                src: cloudFrontUrl,
                name: acceptedFile?.name,
                type: acceptedFile?.type,
                duration,
                width,
                height,
                thumbUrl,
                s3Key,
                s3Url: cloudFrontUrl,
                reducerType: reducerNames.videos,
              };
              mediaAssets.push(finalMetaData);
              /// updateMediaAssets(reducerNames.videos, finalMetaData);
              setProgress(0);
            } catch (error) {
              toast('Uploading content failed. Try again!', {
                position: 'bottom-center',
              });
              setError(true);
              setProgress(0);
            }
          } else if (isAudio) {
            const file = acceptedFile,
              email = user.email,
              projectId = id,
              uniqueId = nanoid(10);

            setProgress(0);
            try {
              const data = await uploadFileAws({
                file,
                user: email,
                projectId,
                uniqueId,
                progressCallback: (progress) =>
                  setProgress(Math.round(progress)),
              });

              const { s3Key, s3Url } = data;
              const cloudFrontUrl = s3Url.replace(
                'https://eyecastvideoeditorfiles.s3.ap-southeast-2.amazonaws.com',
                'https://d1r0cf5836ptgd.cloudfront.net'
              );
              const metaData = {
                projectId,
                uniqueId,
                id,
                src: cloudFrontUrl,
                s3Key,
                s3Url: cloudFrontUrl,
                user: email,
                fileName: acceptedFile?.name,
                type: acceptedFile?.type,
              };

              const audioData = await getAudioMetaData(metaData);
              const { duration } = audioData.data;

              const finalMetaData = {
                ...metaData,
                id: uniqueId,
                src: cloudFrontUrl,
                name: acceptedFile?.name,
                type: acceptedFile?.type,
                duration,
                s3Key,
                s3Url: cloudFrontUrl,
                reducerType: reducerNames.audios,
              };

              mediaAssets.push(finalMetaData);

              //// updateMediaAssets(reducerNames.audios, finalMetaData);
              setProgress(0);
            } catch (error) {
              toast('Uploading content failed. Try again!', {
                position: 'bottom-center',
              });
              setError(true);
              setProgress(0);
            }
          } else if (isImage) {
            const file = acceptedFile,
              email = user.email,
              projectId = id,
              uniqueId = nanoid(10),
              filename = acceptedFile?.name;

            setProgress(0);
            try {
              const data = await uploadFileAws({
                file,
                user: email,
                projectId,
                uniqueId,
                filename,
                progressCallback: (progress) =>
                  setProgress(Math.round(progress)),
              });
              const { width, height } = await getImageDimensions(file);
              const { s3Key, s3Url } = data;
              const cloudFrontUrl = s3Url.replace(
                'https://eyecastvideoeditorfiles.s3.ap-southeast-2.amazonaws.com',
                'https://d1r0cf5836ptgd.cloudfront.net'
              );
              const metaData = {
                ...data,
                id: uniqueId,
                src: cloudFrontUrl,
                name: filename,
                type: acceptedFile?.type,
                thumbUrl: cloudFrontUrl,
                s3Key,
                s3Url: cloudFrontUrl,
                reducerType: reducerNames.images,
                width,
                height,
              };
              mediaAssets.push(metaData);
              /// updateMediaAssets(reducerNames.images, metaData);
              setProgress(0);
            } catch (error) {
              toast('Uploading content failed. Try again!', {
                position: 'bottom-center',
              });
              setError(true);
              setProgress(0);
            }
          } else if (isLottie) {
            const file = acceptedFile,
              email = user.email,
              projectId = id,
              uniqueId = nanoid(10);

            try {
              const data = await uploadLottieFileAws({
                file,
                user: email,
                projectId,
                uniqueId,
                progressCallback: (progress) =>
                  setProgress(Math.round(progress)),
              });

              const { s3Key, s3Url } = data;
              // replace s3Url origin with cloudfront url https://d1r0cf5836ptgd.cloudfront.net
              const cloudFrontUrl = s3Url.replace(
                'https://eyecastvideoeditorfiles.s3.ap-southeast-2.amazonaws.com',
                'https://d1r0cf5836ptgd.cloudfront.net'
              );
              const metaData = {
                projectId,
                uniqueId,
                id,
                src: cloudFrontUrl,
                s3Key,
                s3Url: cloudFrontUrl,
                user: email,
                fileName: acceptedFile?.name,
                type: acceptedFile?.type,
              };

              /// const thumbData = await generateThumnailApi(metaData);
              const {
                duration = 3,
                width = 300,
                height = 300,
                thumbUrl = null,
              } = {};

              const finalMetaData = {
                ...metaData,
                id: uniqueId,
                src: cloudFrontUrl,
                name: acceptedFile?.name,
                type: acceptedFile?.type,
                duration,
                width,
                height,
                thumbUrl,
                s3Key,
                s3Url: cloudFrontUrl,
                reducerType: reducerNames.videos,
                isLottie: true,
              };
              mediaAssets.push(finalMetaData);
              /// updateMediaAssets(reducerNames.videos, finalMetaData);
              setProgress(0);
            } catch (error) {
              toast('Uploading content failed. Try again!', {
                position: 'bottom-center',
              });
              setError(true);
              setProgress(0);
            }
          } // end of Uploads
        }

        // get video metadata array to upload
        const videoMetaData = mediaAssets.filter(
          (item) => item.reducerType === reducerNames.videos
        );
        if (!isEmpty(videoMetaData)) {
          // send whole array to update
          await updateMediaAssets(reducerNames.videos, videoMetaData);
        }

        // get audio metadata array to upload
        const audioMetaData = mediaAssets.filter(
          (item) => item.reducerType === reducerNames.audios
        );
        if (!isEmpty(audioMetaData)) {
          // send whole array to update
          await updateMediaAssets(reducerNames.audios, audioMetaData);
        }

        // get image metadata array to upload
        const imageMetaData = mediaAssets.filter(
          (item) => item.reducerType === reducerNames.images
        );
        if (!isEmpty(imageMetaData)) {
          // send whole array to update
          await updateMediaAssets(reducerNames.images, imageMetaData);
        }
        mediaMenuRepo.update({ isOpen: false });
        /// dispatchAPI(Actions.mediaDrawerActions.set(false));
      }
    },
    [
      id,
      user,
      uploadFileAws,
      getAudioMetaData,
      generateThumnailApi,
      updateMediaAssets,
      mediaMenuRepo,
    ]
  );

  return {
    onMediaUpload,
    progress,
    error,
  };
};
