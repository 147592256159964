import { useAuth0 } from '@auth0/auth0-react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box,
  Link,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { useProjectExportRepository } from 'repositories';
import { useObservableWithPipe } from 'utils/useObserveable';

const QueueTable = () => {
  const projectExportRepo = useProjectExportRepository();
  const queueData = useObservableWithPipe(projectExportRepo.getObservable());

  const { user } = useAuth0();

  React.useEffect(() => {
    projectExportRepo.fetchQueuedProjects(user);
  }, []);

  const { data: list = [], isLoading = false } = queueData || {};

  if (isLoading) {
    return (
      <Box
        display={'flex'}
        flexDir="column"
        justifyContent="center"
        alignItems={'center'}
      >
        <Spinner size="xl" />
        <Text>Loading ...</Text>
      </Box>
    );
  }

  return (
    <TableContainer marginLeft={5} paddingTop={5} paddingX={10}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Project</Th>
            <Th>Date</Th>
            <Th>Status</Th>
            <Th>Download Link</Th>
          </Tr>
        </Thead>
        <Tbody>
          {map(orderBy(list, ['updatedAt'], ['desc']), (data) => {
            return (
              <Tr key={data.id}>
                <Td>
                  <Link as={ReactLink} to={`/canvas/${data.projectId}`}>
                    {data?.title || data.projectId}
                  </Link>
                </Td>
                <Td>{data.updatedAt}</Td>
                <Td>{data.status}</Td>
                <Td>
                  {!!data.s3Url && (
                    <Link href={data.s3Url} isExternal>
                      Download <ExternalLinkIcon mx="2px" />
                    </Link>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default QueueTable;
