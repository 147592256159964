import React from 'react';

export const HOME_NAV_ITEMS = [
  {
    id: 'dashboard',
    path: '/dashboard',
    name: 'Home',
    icon: 'home',
  },
  {
    id: 'projects',
    path: '/projects',
    name: 'All your projects',
    icon: 'video',
  },
  {
    id: 'discover',
    path: '/discover',
    name: 'Discover',
    icon: 'play',
  },
  {
    id: 'library',
    path: '/library',
    name: 'Library',
    icon: 'photo-video',
  },
  {
    id: 'trash',
    path: '/trash',
    name: 'Trash',
    icon: 'trash',
  },
];

// export const USER_NAV_ITEMS = []

export const ACOUNT_NAV_ITEMS = [
  {
    id: 'account-settings',
    path: '/account/settings',
    name: 'My account',
    icon: 'user-circle',
    main: React.lazy(() => import('scenes/account-settings')),
  },
  {
    id: 'account-security',
    path: '/account/security',
    name: 'Login & Security',
    icon: 'lock',
    main: React.lazy(() => import('scenes/account-security')),
  },
  {
    id: 'account-queue',
    path: '/account/queue',
    name: 'Queue',
    icon: 'bell',
    main: React.lazy(() => import('scenes/queue-table')),
  },
  {
    id: 'account-notifications',
    path: '/account/notifications',
    name: 'Notifications',
    icon: 'bell',
    main: React.lazy(() => import('scenes/account-notifications')),
  },
  {
    id: 'account-billing',
    path: '/account/billing',
    name: 'Billing',
    icon: 'credit-card',
    main: React.lazy(() => import('scenes/account-billing')),
  },
];

export const EDITING_NAV_ITEMS = [
  {
    id: 'photo-video',
    icon: 'caret-square-right',
    main: React.lazy(() => import('scenes/canvas-landing/canvas-landing')),
    name: 'Media',
    isBrand: false,
  },
  {
    id: 'edit',
    icon: 'edit',
    name: 'Edit',
    isBrand: false,
  },
  {
    id: 'discover',
    icon: 'heart',
    name: 'Favorites',
    isBrand: false,
  },
  {
    id: 'effects',
    icon: 'star',
    name: 'Effects',
    isBrand: false,
  },
  {
    id: 'elements',
    icon: 'uncharted',
    library: 'fontawesome',
    name: 'Elements',
    isBrand: true,
  },
];
