import { Icon } from '@chakra-ui/react';
import React from 'react';

export const VectorTransitions = (props) => (
  <Icon viewBox="0 0 25 26" {...props}>
    <path
      d="M16.25 0.5C18.5706 0.5 20.7962 1.42187 22.4372 3.06282C24.0781 4.70376 25 6.92936 25 9.25C25 12.6375 23.075 15.5625 20.275 17.025C19.4353 18.6272 18.1272 19.9353 16.525 20.775C15.7853 22.1985 14.6693 23.3918 13.2984 24.2249C11.9274 25.0581 10.3542 25.4991 8.75 25.5C6.42936 25.5 4.20376 24.5781 2.56282 22.9372C0.921872 21.2962 0 19.0706 0 16.75C0 13.3625 1.925 10.4375 4.725 8.975C5.56469 7.37283 6.87283 6.06469 8.475 5.225C9.21467 3.80148 10.3307 2.60819 11.7016 1.77506C13.0726 0.941929 14.6458 0.500901 16.25 0.5ZM12.5 21.75C10.1794 21.75 7.95376 20.8281 6.31282 19.1872C4.67187 17.5462 3.75 15.3206 3.75 13C2.9625 14.05 2.5 15.3375 2.5 16.75C2.5 18.4076 3.15848 19.9973 4.33058 21.1694C5.50269 22.3415 7.0924 23 8.75 23C10.1625 23 11.45 22.5375 12.5 21.75ZM16.25 18C13.9294 18 11.7038 17.0781 10.0628 15.4372C8.42187 13.7962 7.5 11.5706 7.5 9.25C6.7125 10.3 6.25 11.5875 6.25 13C6.25 14.6576 6.90848 16.2473 8.08058 17.4194C9.25269 18.5915 10.8424 19.25 12.5 19.25C13.9125 19.25 15.2 18.7875 16.25 18ZM16.25 3C14.8375 3 13.55 3.4625 12.5 4.25C14.8206 4.25 17.0462 5.17187 18.6872 6.81282C20.3281 8.45376 21.25 10.6794 21.25 13C22.0375 11.95 22.5 10.6625 22.5 9.25C22.5 7.5924 21.8415 6.00269 20.6694 4.83058C19.4973 3.65848 17.9076 3 16.25 3ZM10 9.25C10 10.9076 10.6585 12.4973 11.8306 13.6694C13.0027 14.8415 14.5924 15.5 16.25 15.5C17 15.5 17.7125 15.375 18.375 15.125C18.625 14.4625 18.75 13.75 18.75 13C18.75 11.3424 18.0915 9.75269 16.9194 8.58058C15.7473 7.40848 14.1576 6.75 12.5 6.75C11.75 6.75 11.0375 6.875 10.375 7.125C10.125 7.7875 10 8.5 10 9.25Z"
      fill="currentColor"
    />
  </Icon>
);
