import filter from 'lodash/filter';
import React from 'react';
import * as WebFont from 'webfontloader';

export const useWebFontLoader = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [fontsLoaded, setFontsLoaded] = React.useState([]);

  React.useEffect(() => {
    // load initial fonts
    const fontsReq = [
      'Poppins: 400,500,700',
      'PT Serif: 400,700',
      'Roboto Mono',
      'Droid Sans',
      'Droid Serif',
      'Montserrat Subrayada: 400,500,700,800',
      'Inter: 400,700,900',
    ];
    try {
      WebFont.load({
        google: {
          families: fontsReq,
        },
        loading: () => setIsLoading(true),
        active: () => {
          setFontsLoaded(fontsReq);
          setIsLoading(false);
        },
      });
    } catch (e) {
      console.error('Font Loading Failed:');
    }
  }, []);

  const loadWebFontCallback = React.useCallback(
    (fonts, cb) => {
      try {
        const fontsToLoad = filter(fonts, (f) => !fontsLoaded.includes(f));
        const uniqueFontsToLoad = [...new Set(fontsToLoad)];
        const loaded = fontsLoaded.concat(uniqueFontsToLoad);
        WebFont.load({
          google: {
            families: fontsToLoad,
          },
          loading: () => setIsLoading(true),
          active: () => {
            setFontsLoaded(loaded);
            setIsLoading(false);
            if (cb) {
              cb();
            }
          },
        });
      } catch (e) {
        if (cb) {
          cb();
        }
        console.warn('Font Loading Failed:');
      }
    },
    [fontsLoaded]
  );

  return {
    fontsLoaded,
    isLoading,
    loadWebFontCallback,
  };
};
