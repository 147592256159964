import { BehaviorSubject } from 'rxjs';

const initialState = {
  zoomValue: '1920 X 1080',
  selected: false,
};

const canvasZoomSubject = new BehaviorSubject(initialState);

export const useCanvasZoomObservable = () => {
  const update = (payload) => {
    setNextState({ ...payload, error: '' });
  };

  const updating = (flag) => {
    setNextState({ updating: flag });
  };

  const removing = (flag) => {
    setNextState({ removing: flag });
  };

  const error = (message) => {
    setNextState({ error: message });
  };

  const setNextState = (payload) => {
    const state = canvasZoomSubject.getValue();
    canvasZoomSubject.next({ ...state, ...payload });
  };

  const getObservable = () => {
    return canvasZoomSubject;
  };

  return {
    update,
    updating,
    removing,
    error,
    getObservable,
  };
};
