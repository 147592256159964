import { Icon } from '@chakra-ui/react';
import React from 'react';

export const Dropbox = (props) => (
  <Icon viewBox="0 0 20 18" {...props}>
    <path
      d="M5 0.666687L10 3.79169L5 6.91669L0 3.79169L5 0.666687ZM15 0.666687L20 3.79169L15 6.91669L10 3.79169L15 0.666687ZM0 10.0417L5 6.91669L10 10.0417L5 13.1667L0 10.0417ZM15 6.91669L20 10.0417L15 13.1667L10 10.0417L15 6.91669ZM5 14.2084L10 11.0834L15 14.2084L10 17.3334L5 14.2084Z"
      fill="#D4F7FF"
    />
  </Icon>
);
