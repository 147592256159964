import { Image } from '@chakra-ui/react';
import { Hover } from '../../hover';

const StipopElement = ({ stickerImg, width, onClick }) => {
  return (
    <Hover>
      <Image
        boxSize={`${width}px`}
        objectFit="cover"
        src={`${stickerImg}?d=${width}x${width}`}
        alt="sticker"
        onClick={onClick}
      />
    </Hover>
  );
};

export default StipopElement;
