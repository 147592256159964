import { Icon } from '@chakra-ui/react';
import React from 'react';

export const VectorMediaIcon = (props) => (
  <Icon {...props}>
    <rect
      id="backgroundrect"
      width="100%"
      height="100%"
      x="0"
      y="0"
      fill="none"
      stroke="none"
    />
    <g className="currentLayer">
      <title>Layer 1</title>
      <path
        opacity="0.75"
        d="M25.666 3.99998H18.2127C17.8629 3.99851 17.5277 3.85964 17.2793 3.61331L15.386 1.71998C15.1377 1.47365 14.8025 1.33479 14.4527 1.33331H4.33268C2.85992 1.33331 1.66602 2.52722 1.66602 3.99998V20C1.66602 21.4727 2.85992 22.6666 4.33268 22.6666H25.666C27.1388 22.6666 28.3327 21.4727 28.3327 20V6.66665C28.3327 5.19389 27.1388 3.99998 25.666 3.99998Z"
        stroke="currentColor"
        strokeWidth="2"
        id="svg_1"
      />
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeDashoffset=""
        fillRule="nonzero"
        opacity="0.75"
        markerStart=""
        markerMid=""
        markerEnd=""
        id="svg_2"
        d="M11.031130420977934,15.70525178886589 L14.883458337685662,8.96367793462731 L18.7357862543934,15.70525178886589 L11.031130420977934,15.70525178886589 z"
        transform="rotate(93.09654235839844 14.88346004486084,12.334465026855469) "
        fillOpacity="1"
      />
    </g>
  </Icon>
);
