import { Box, Grid, IconButton, Text } from '@chakra-ui/react';
import { map } from 'lodash';
import { FiMoreHorizontal } from 'react-icons/fi';
import { Hover } from '../../hover';
import { TitleComponent } from './TitleComponent';
import { useSidebarRepository } from 'repositories';

const titlesList = [
  {
    id: 1,
    text: 'YOUR TITLE HERE',
    textValue: 'My Sample Text',
    boxStyles: {},
    fontStyle: {
      color: '#C5C5C5',
      fontSize: 10,
      align: 'center',
      lineHeight: '11px',
      letterSpacing: '2px',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
    hasSubTitle: false,
    type: 'titles',
  },
  {
    id: 2,
    text: 'YOUR TITLE HERE',
    textValue: 'My Sample Text',
    boxStyles: {},
    fontStyle: {
      color: '#C5C5C5',
      fontSize: 10,
      align: 'center',
      lineHeight: '11px',
      letterSpacing: '2px',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
    hasSubTitle: true,
    subTitle: {
      text: 'SUBTITLE',
      color: '#C5C5C5',
      fontSize: '6px',
      type: 'titles',
    },
    type: 'titles',
  },
  {
    id: 3,
    text: 'YOUR TITLE HERE',
    textValue: 'My Sample Text',
    hasBoxWrapper: true,
    boxStyles: {
      background: 'white',
      border: '1px solid #F9F9F9',
      boxSizing: 'border-box',
      color: '#C5C5C5',
      transform: 'matrix(1, -0.12, 0, 0.99, 0, 0)',
    },
    fontStyle: {
      color: '#C5C5C5',
      fontSize: 10,
      align: 'center',
      lineHeight: '11px',
      letterSpacing: '2px',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
    hasSubTitle: false,
    type: 'titles',
  },
  {
    id: 4,
    text: 'YOUR TITLE HERE',
    textValue: 'My Sample Text',
    hasBoxWrapper: false,
    fontStyle: {
      color: 'white',
      fontSize: 10,
      fontWeight: '900',
      align: 'center',
      lineHeight: '11px',
      letterSpacing: '2px',
      textTransform: 'uppercase',
      fontStyle: 'normal',
    },
    hasSubTitle: false,
    type: 'titles',
  },
  {
    id: 5,
    text: 'YOUR TITLE HERE',
    textValue: 'My Sample Text',
    hasBoxWrapper: false,
    fontStyle: {
      color: '#C5C5C5',
      fontSize: 10,
      fontWeight: '900',
      fontStyle: 'normal',
      align: 'center',
      lineHeight: '11px',
      letterSpacing: '2px',
      border: '1px solid #fff',
      padding: '2px',
      textTransform: 'uppercase',
    },
    hasSubTitle: false,
    type: 'titles',
  },
];

export const TitlesGrid = ({ onClick }) => {
  const sidebarMenuRepo = useSidebarRepository();

  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={3} data-testid="titles-grid">
      {map(titlesList, (value, idx) => {
        const textRawValue = {
          id: value.id,
          value: value,
          droptype: value.type,
          type: 'typography',
        };
        return (
          <Box
            key={value.id}
            id={value.id}
            value={value}
            type="typography"
            droptype="titles"
            onClick={() => onClick(textRawValue)}
          >
            <Hover>
              <TitleComponent value={value} />
            </Hover>
            <Text mt={1} fontSize="xx-small" align="center" color="pearl">
              {`Title ${idx}`}
            </Text>
          </Box>
        );
      })}
      <Box>
        <Box
          w="104px"
          h="55px"
          bg="charadeDark3"
          fontSize="37px"
          justifyContent="center"
          display="flex"
          alignItems="center"
          borderRadius={5}
          color="azure"
          data-testid="shape-grid-box"
        >
          <IconButton
            icon={<FiMoreHorizontal />}
            onClick={() => {
              sidebarMenuRepo.update('Titles/All');
            }}
          />
        </Box>
        <Text fontSize="xx-small" align="center" color="pearl">
          View all
        </Text>
      </Box>
    </Grid>
  );
};

export default TitlesGrid;
