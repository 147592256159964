import { useContext } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { EditorApiContext } from '../providers/api-provider';
import { EditorApiConstants } from '../constants';

export function useGetProjectMediaAssets(id) {
  /** Axios client */
  const _axios = useContext(EditorApiContext);

  return useQuery({
    queryKey: ['USER_PROJECT_MEDIA_ASSET'],
    queryFn: () =>
      _axios
        .get(
          `/${EditorApiConstants.endpoints.PROJECT_MEDIA_FILES}/${id}/mediafiles`
        )
        .then(({ data }) => {
          return data?.data || {};
        }),
    useErrorBoundary: false,
    staleTime: Infinity,
  });
}

export function useCreateProjectMediaAsset(id) {
  /** Axios client */
  const _axios = useContext(EditorApiContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) =>
      _axios
        .post(
          `/${EditorApiConstants.endpoints.PROJECT_MEDIA_FILES}/mediafiles`,
          JSON.stringify(data),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(({ data }) => {
          return data || {};
        }),
    onSuccess: async (data, variables, context) => {
      console.log('userProjects Mutate successful');
      // ✅ refetch the comments list for our blog post
      queryClient.invalidateQueries({ queryKey: ['USER_PROJECT_MEDIA_ASSET'] });
    },
    onSettled: async (data, error, variables, context) => {
      console.log('userProjects onSettled');
    },
  });
}

export function useUpdateProjectMediaAsset(id) {
  /** Axios client */
  const _axios = useContext(EditorApiContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, data }) =>
      _axios
        .put(
          `/${EditorApiConstants.endpoints.PROJECT_MEDIA_FILES}/mediafiles`,
          JSON.stringify(data),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(({ data }) => {
          return data || {};
        }),
    onSuccess: async (data, variables, context) => {
      console.log('userProjects Mutate successful');
      queryClient.invalidateQueries({ queryKey: ['USER_PROJECT_MEDIA_ASSET'] });
    },
    onSettled: async (data, error, variables, context) => {
      console.log('userProjects onSettled');
    },
  });
}
