import React from 'react';
import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Flex, HStack, Icon, IconButton, Spacer } from '@chakra-ui/react';
import { Addsquare, Scissor, Voicesquare } from 'assets/icons';
import { nanoid } from 'nanoid';
import { BsZoomIn, BsZoomOut } from 'react-icons/bs';
import { GrRedo, GrUndo } from 'react-icons/gr';
import { timestamp } from '../../../utils/unixtimeStamp';
import { GsapPixieContext, emitCustomEvent } from 'reacthub-pixitweenjs';
import { useScrubberData } from 'hooks/useScrubberData';
import { delay } from 'utils/delay';
import {
  useEditorRepository,
  useScrubberBarRepository,
  useSelectedRepository,
} from 'repositories';
import { useObservableWithPipe } from 'utils/useObserveable';
import { find, isEmpty } from 'lodash';

const VideoEditingControls = () => {
  /** editor state */
  const { totalVideoSeconds, sortedScrubberData } = useScrubberData();
  const editorRepository = useEditorRepository();
  const selectedRepo = useSelectedRepository();
  const scrubberBarRepo = useScrubberBarRepository();

  const selectedKeyFrame = useObservableWithPipe(
    selectedRepo.getSelectedObservable(),
    'selectedKeyFrame'
  );
  const editorItems = sortedScrubberData;
  const selectedType = selectedKeyFrame?.droptype;

  const { playerTimeRef } = React.useContext(GsapPixieContext);

  const handleUndo = () => {
    editorRepository.undo();
  };

  const handleRedo = () => {
    editorRepository.redo();
  };

  const zoomInScale = () => {
    scrubberBarRepo.zoomInScale();
  };

  const zoomOutScale = () => {
    scrubberBarRepo.zoomOutScale();
  };

  const handleDeleteLayer = async () => {
    const { uniqueId } = selectedKeyFrame;
    selectedRepo.unSelect();
    await delay(100);
    editorRepository.remove(uniqueId);
    const playedSeconds = playerTimeRef.current;
    const time = playedSeconds;
    const nTotSeconds =
      totalVideoSeconds && totalVideoSeconds > 0 ? totalVideoSeconds : 1;
    const progress = time / nTotSeconds;
    emitCustomEvent('TIMELINE_SHIFT_END', { time, progress });
  };

  const handleDuplicateLayer = () => {
    const { droptype, uniqueId } = selectedKeyFrame;
    const selectedItem = find(editorItems, (f) => f.uniqueId === uniqueId);
    if (!isEmpty(selectedItem)) {
      const isVideoAudio = ['videos', 'audios'].includes(droptype);
      const newUniqueId = nanoid(10);
      const itemWithTimeStamp = {
        ...selectedItem,
        ...(!isVideoAudio && { start: 0, end: 5000 }),
        timestamp: timestamp(),
        uniqueId: newUniqueId,
        id: newUniqueId,
      };
      editorRepository.add(itemWithTimeStamp);

      const playedSeconds = playerTimeRef.current;
      const time = playedSeconds;
      const nTotSeconds =
        totalVideoSeconds && totalVideoSeconds > 0 ? totalVideoSeconds : 1;
      const progress = time / nTotSeconds;
      emitCustomEvent('TIMELINE_SHIFT_END', { time, progress });
    }
  };

  const handleSplitLayer = () => {
    const playedSeconds = playerTimeRef.current;
    const { uniqueId } = selectedKeyFrame;
    const selectedItem = find(editorItems, (f) => f.uniqueId === uniqueId);
    if (!isEmpty(selectedItem)) {
      const time = playedSeconds;
      const nTotSeconds =
        totalVideoSeconds && totalVideoSeconds > 0 ? totalVideoSeconds : 1;
      const progress = time / nTotSeconds;
      editorRepository.splitVideo({ playedSeconds, ...selectedItem });
      emitCustomEvent('TIMELINE_SHIFT_END', { time, progress });
    }
  };

  const handleOnDetachAudio = () => {
    // dispatch({
    //   type: sagaActionTypes.DETACH_AUDIO_FILE,
    //   payload: {},
    // });
  };

  return (
    <Flex flexDir="row" justifyContent="space-between" bg="shark" flex={1}>
      <Box ml="23px">
        <HStack spacing={3} justifyContent="center">
          <IconButton
            background="shark"
            icon={<Icon as={GrUndo} w={4} h="9" color="silverChalice" />}
            _hover={{ background: 'tuna' }}
            onClick={handleUndo}
            title="undo"
          />
          <IconButton
            background="shark"
            icon={<Icon as={GrRedo} w={4} h="9" color="silverChalice" />}
            _hover={{ background: 'tuna' }}
            onClick={handleRedo}
            title="redo"
          />
          <IconButton
            background="shark"
            icon={<Icon as={Scissor} w={4} h="9" color="silverChalice" />}
            _hover={{ background: 'tuna' }}
            onClick={handleSplitLayer}
            title="cut"
          />
          <IconButton
            background="shark"
            icon={<Icon as={Addsquare} w={4} h="9" color="silverChalice" />}
            _hover={{ background: 'tuna' }}
            onClick={handleDuplicateLayer}
            title="Duplicate"
          />
          <IconButton
            background="shark"
            icon={<Icon as={Voicesquare} w={4} h="9" color="silverChalice" />}
            _hover={{ background: 'tuna' }}
            disabled={selectedType !== 'videos'}
            onClick={handleOnDetachAudio}
            title="Detach Audio"
          />
          <IconButton
            background="shark"
            icon={<Icon as={DeleteIcon} w={4} h="9" color="silverChalice" />}
            _hover={{ background: 'tuna' }}
            onClick={handleDeleteLayer}
          />
        </HStack>
      </Box>
      <Spacer />
      <HStack spacing="1px">
        <IconButton
          background="shark"
          title="zoomin scale"
          icon={<Icon as={BsZoomIn} w={4} h="9" color="silverChalice" />}
          _hover={{ background: 'tuna' }}
          onClick={zoomInScale}
        />
        <IconButton
          background="shark"
          title="zoomout scale"
          icon={<Icon as={BsZoomOut} w={4} h="9" color="silverChalice" />}
          _hover={{ background: 'tuna' }}
          onClick={zoomOutScale}
        />
      </HStack>
    </Flex>
  );
};

export default VideoEditingControls;

// VideoEditingControls.whyDidYouRender = {
//   logOnDifferentValues: true,
//   customName: 'VideoEditingControls',
// };
