import { BehaviorSubject } from 'rxjs';

const queueDataSubject = new BehaviorSubject([]);

export const useQueueDataObservable = () => {
  const update = (payload) => {
    setNextState({ ...payload, error: '' });
  };

  const setNextState = (payload) => {
    const state = queueDataSubject.getValue();
    queueDataSubject.next({ ...state, ...payload });
  };

  const getObservable = () => {
    return queueDataSubject;
  };

  return {
    update,
    getObservable,
  };
};

const pollingSubject = new BehaviorSubject({ interval: 1000, toggled: false });

export const usePollingObservable = () => {
  const update = (payload) => {
    setNextState({ ...payload, error: '' });
  };

  const setNextState = (payload) => {
    const state = pollingSubject.getValue();
    pollingSubject.next({ ...state, ...payload });
  };

  const getObservable = () => {
    return pollingSubject;
  };

  return {
    update,
    getObservable,
  };
};
