const ButtonGroup = {
  baseStyle: {
    borderRadius: '5px',
  },
  variant: {
    solid: {
      bg: '#262630',
    },
  },
};

export default ButtonGroup;
