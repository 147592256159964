import { useProjectExportObserveable } from 'observeables';
import { EditorApiContext } from 'providers/api-provider';
import { useContext } from 'react';
import { EditorApiConstants } from '../constants';

export const useProjectExportRepository = () => {
  const projectExportObserveable = useProjectExportObserveable();
  /** Axios client */
  const _axios = useContext(EditorApiContext);

  const fetchQueuedProjects = async (user) => {
    try {
      const queuedProjects = await _axios
        .get(
          `/${EditorApiConstants.endpoints.QUEUED_USER_PROJECTS}/${user.email}`
        )
        .then(({ data }) => {
          return data?.data;
        });
      projectExportObserveable.update({ data: queuedProjects });
    } catch (error) {
    } finally {
    }
  };

  const update = async (payload) => {
    try {
      projectExportObserveable.update(payload);
    } catch (error) {
      projectExportObserveable.error('Error in updating edit area dimensons');
    } finally {
    }
  };

  const getObservable = () => projectExportObserveable.getObservable();

  return {
    update,
    getObservable,
    fetchQueuedProjects,
  };
};
