export const canvasSize = [
  {
    width: 1080,
    height: 1080,
    name: 'Instagram Post 1080 X 1080',
    value: '1080 X 1080',
  },
  {
    width: 1080,
    height: 1920,
    name: 'Instagram Story 1080 X 1920',
    value: '1080 X 1920',
  },
  {
    width: 940,
    height: 788,
    name: 'Facebook Post 940 X 788',
    value: '940 X 788',
  },
  {
    width: 851,
    height: 315,
    name: 'Facebook Cover 851 X 315',
    value: '851 X 315',
  },
  {
    width: 1200,
    height: 628,
    name: 'Facebook Ad 1200 X 628',
    value: '1200 X 628',
  },
  {
    width: 1280,
    height: 720,
    name: 'Youtube Thumbnail 1280 X 720',
    value: '1280 X 720',
  },
  {
    width: 2560,
    height: 1440,
    name: 'Youtube Channel 2560 X 1440',
    value: '2560 X 1440',
  },
  {
    width: 1920,
    height: 1080,
    name: 'Full HD 1920 X 1080',
    value: '1920 X 1080',
  },
];
