import { Box, Heading } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Menu, MenuItem } from 'components';
import React, { useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';

const TemplatetActions = ({
  setIsActive,
  setIsOpen,
  isActive,
  isOpen,
  title,
}) => {
  const [baseElement, setBaseElement] = useState(null);

  const ref = useOnclickOutside(
    () => {
      setIsActive(false);
      setIsOpen(false);
    },
    { disabled: !isOpen }
  );

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsActive((prev) => !prev);
    setIsOpen((prev) => !prev);
  };

  return (
    <Box position={'absolute'} right={'0.5rem'} top={'0.5rem'}>
      <IconButton
        ref={setBaseElement}
        onClick={handleClick}
        ariaLabel="actions"
        size="xs"
        variant={'solid'}
        isRound
        colorScheme={'blue'}
        opacity={isActive || isOpen ? 1 : 0}
        transition={'opacity 200ms ease'}
        icon={<FontAwesomeIcon icon={['fas', 'ellipsis-h']} size="sm" />}
      />

      <Menu
        ref={ref}
        baseElement={baseElement}
        isOpen={isOpen}
        overrideStyles={{
          nav: { minWidth: '12rem' },
        }}
      >
        <TemplateInfo title={title} />
        <MenuItem
          title={'Start with this template'}
          icon={'play'}
          onClick={() => {}}
        />
        <MenuItem
          title={'Preview this template'}
          icon={'eye'}
          onClick={() => {}}
        />
      </Menu>
    </Box>
  );
};

const TemplateInfo = ({ title }) => (
  <Box borderBottom={'1px solid #2C2D37'} p={'1rem'} textAlign={'left'}>
    <Heading as="h1" size="md" isTruncated pb={'0.25rem'} fontWeight={700}>
      {title}
    </Heading>
  </Box>
);

export default TemplatetActions;
