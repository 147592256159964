import { useSidebarObservable } from 'observeables';

export const useSidebarRepository = () => {
  const sidebarObservable = useSidebarObservable();

  const update = async (payload) => {
    try {
      sidebarObservable.update(payload);
    } catch (error) {
      console.error('Error in updating sidebar menu');
    } finally {
    }
  };

  const onOpenSidebar = () => {
    sidebarObservable.onOpenSidebar();
  };

  const onCloseSidebar = () => {
    sidebarObservable.onCloseSidebar();
  };

  const getSidebarObservable = () => sidebarObservable.getObservable();

  return {
    update,
    getSidebarObservable,
    onOpenSidebar,
    onCloseSidebar,
  };
};
