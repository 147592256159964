import {
  DropdownContainer,
  DropdownContent,
  DropdownNav,
  Popover,
} from 'components';
import _merge from 'lodash/merge';
import React from 'react';

const initialStyles = {
  container: {},
  content: {},
  nav: {},
};

const Menu = React.forwardRef(
  ({ isOpen, baseElement, children, overrideStyles, ...rest }, ref) => {
    const combinedStyles = _merge(initialStyles, overrideStyles);

    return (
      <Popover
        baseElement={baseElement}
        active={isOpen}
        placement="auto"
        fallbackPlacements={['top-start']}
        zIndex={1002}
        {...rest}
      >
        <DropdownContainer
          ref={ref}
          py={'0.5rem'}
          {...combinedStyles.container}
        >
          <DropdownContent {...combinedStyles.content}>
            <DropdownNav {...combinedStyles.nav}>{children}</DropdownNav>
          </DropdownContent>
        </DropdownContainer>
      </Popover>
    );
  }
);

export default Menu;
