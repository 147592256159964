export const mockEffect = {
  effect0: {
    id: 'effect0',
    name: 'sample',
  },
  effect1: {
    id: 'effect1',
    name: 'temp',
  },
};

export const mockData = [
  {
    id: '0',
    selected: false,
    actions: [
      {
        id: 'action00',
        start: 10,
        end: 12,
        effectId: 'effect0',
      },
    ],
  },
  {
    id: '1',
    selected: false,
    actions: [
      {
        id: 'action10',
        start: 1.5,
        end: 5,
        effectId: 'effect1',
      },
    ],
  },
  {
    id: '2',
    selected: false,
    actions: [
      {
        id: 'action20',
        start: 3,
        end: 4,
        effectId: 'effect0',
      },
    ],
  },
  {
    id: '3',
    selected: false,
    actions: [
      {
        id: 'action30',
        start: 4,
        end: 7,
        effectId: 'effect1',
      },
      {
        id: 'action31',
        start: 10,
        end: 200,
        effectId: 'effect1',
      },
    ],
  },
];

export const mockData2 = [
  {
    id: '0',
    selected: false,
    actions: [
      {
        id: 'action00',
        start: 0,
        end: 5,
        effectId: 'effect0',
      },
    ],
  },
  {
    id: '1',
    selected: false,
    actions: [
      {
        id: 'action00',
        start: 0,
        end: 10,
        effectId: 'effect0',
      },
    ],
  },
];
export const scaleWidth = 160;
export const scale = 5;
export const startLeft = 20;
