import { Box } from '@chakra-ui/react';
import { Slider, SliderItem } from 'components';
import React from 'react';
import Template from './template';

const imageUrls = [
  'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-009.jpeg',
  'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-008.jpeg',
  'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-007.jpeg',
  'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-006.jpeg',
  'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-005.jpeg',
  'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-004.jpg',
  'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-003.jpg',
  'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-002.jpg',
  'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-001.jpg',
];

const TemplateSlider = () => {
  return (
    <Box p={5} borderRadius={'0.25rem'} bgColor={'#262630'}>
      <Slider>
        {Array(9)
          .fill(undefined)
          .map((placeholder, index) => (
            <SliderItem key={index} mx={'1rem'} minWidth={'12.5rem'} zIndex={1}>
              <Template
                coverPhoto={imageUrls[index]}
                title={`Template 0${index}`}
                previewUrl={'http://google.com'}
              />
            </SliderItem>
          ))}
      </Slider>
    </Box>
  );
};

export default TemplateSlider;
