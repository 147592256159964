export const reducerNames = {
  shapes: 'shapes',
  titles: 'titles',
  videos: 'videos',
  audios: 'audios',
  images: 'images',
  emojis: 'emojis',
  gifs: 'gifs',
  background: 'background',
  layoutsize: 'layoutsize',
};
