import { Flex, Grid } from '@chakra-ui/react';
import { get, isEmpty, map } from 'lodash';
import React from 'react';
import { ImageItem } from './ImageItem';
import useScreenSize from 'hooks/useScreenSize';

export const ImageGallery = ({
  data = [],
  users = {},
  onClick,
  imagePath = 'urls.thumb',
  bgFilter,
}) => {
  const { isLgDown } = useScreenSize();

  const photoGalleryData = React.useMemo(() => {
    return map(data, (d) => d);
  }, [data]);

  return (
    <Grid
      data-testid="container"
      templateColumns={{
        base: 'repeat(auto-fill, minmax(150px, 1fr))',
        md: 'repeat(auto-fill, minmax(150px, 1fr))',
      }}
      gap={4}
    >
      {!isEmpty(photoGalleryData) &&
        map(photoGalleryData, (eachPhoto) => (
          <ImageItem
            key={eachPhoto.id}
            id={eachPhoto.id}
            src={get(
              eachPhoto,
              imagePath,
              'https://source.unsplash.com/e6FMMambeO4'
            )}
            username={users[eachPhoto.user]?.name}
            userlink={users[eachPhoto.user]?.links?.html}
            onClick={onClick}
            bgFilter={bgFilter}
          />
        ))}
    </Grid>
  );
};
