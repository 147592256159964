import { BehaviorSubject } from 'rxjs';

const initialState = {};

const selectedSubject = new BehaviorSubject(initialState);

export const useSelectedObserveable = () => {
  const update = (payload) => {
    setNextState({ ...payload, error: '' });
  };

  const select = (payload) => {
    selectedSubject.next({ ...payload });
  };

  const unSelect = () => {
    selectedSubject.next(initialState);
  };

  const error = (message) => {
    setNextState({ error: message });
  };

  const setNextState = (payload) => {
    const state = selectedSubject.getValue();
    selectedSubject.next({ ...state, ...payload });
  };

  const getObservable = () => {
    return selectedSubject;
  };

  return {
    update,
    select,
    unSelect,
    error,
    getObservable,
  };
};
