export const timestamp = () => {
  return Math.round(new Date().getTime() / 1000);
};

export const toISOStringWithTimezone = (date) => {
  // Get the timezone offset in minutes.
  const tzOffset = -date.getTimezoneOffset();
  // Get the sign of the timezone offset.
  const diff = tzOffset >= 0 ? '+' : '-';
  // Pad the number of hours so that it is always two digits long.
  const pad = (n) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
  return (
    // Format the date.
    `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${
      pad(date.getDate())
      // Format the time.
    }T${pad(date.getHours())}:${pad(date.getMinutes())}:${
      pad(date.getSeconds())
      // Format the timezone.
    }${diff}${pad(tzOffset / 60)}:${pad(tzOffset % 60)}`
  );
};
