import { Box } from '@chakra-ui/react';
import Aside from 'layouts/shared/aside';
import Header from 'layouts/shared/header';
import React from 'react';
import { useUserProjects } from 'api/useUserProjectApi';

const Layout = ({ routes, children }) => {
  const { data: userAllProjects } = useUserProjects();

  React.useEffect(() => {}, [userAllProjects]);

  return (
    <LayoutWrapper>
      <LayoutHeader>
        <Header />
      </LayoutHeader>

      <LayoutAside>
        <Aside routes={routes} isFixed />
      </LayoutAside>

      <LayoutMain>
        <LayoutMainScroll>{children}</LayoutMainScroll>
      </LayoutMain>
    </LayoutWrapper>
  );
};

const LayoutWrapper = ({ children }) => (
  <Box
    display={'grid'}
    my={0}
    mx={'auto'}
    minH={'100vh'}
    maxW={'2560px'}
    gridTemplateColumns={'auto minmax(0, 1fr)'}
    gridTemplateRows={'0fr 0fr 1fr 0fr'}
    backgroundColor={'#1A1C22'}
    color={'white'}
    data-testid={'layout-wrapper'}
  >
    {children}
  </Box>
);

const LayoutHeader = ({ children }) => (
  <Box
    width={'100%'}
    gridColumn={'1/3'}
    gridRow={'1/2'}
    position={'sticky'}
    top={0}
    zIndex={3}
    bg="woodsmoke"
    data-testid={'layout-header'}
  >
    {children}
  </Box>
);

const LayoutAside = ({ children }) => (
  <Box
    width={'280px'}
    gridRow={'2/5'}
    gridColumn={'1/2'}
    zIndex={1}
    display={['none', 'none', 'none', 'block']}
    data-testid={'layout-aside'}
  >
    {children}
  </Box>
);

const LayoutMain = ({ children }) => (
  <Box
    width={'100%'}
    gridRow={'3/4'}
    gridColumn={['1/3', '2/3']}
    zIndex={1}
    position={'relative'}
    data-testid={'layout-main'}
  >
    {children}
  </Box>
);

const LayoutMainScroll = ({ children }) => (
  <Box
    width={'100%'}
    height={'100%'}
    position={'absolute'}
    overflow={'auto'}
    data-testid={'layout-main-scroll'}
  >
    {children}
  </Box>
);

export default Layout;
