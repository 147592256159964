import { Box } from '@chakra-ui/react';
import React from 'react';
import AsideNav from './aside-nav';

const Aside = ({ routes, isFixed = false, isFullWidth = false }) => {
  return (
    <AsideHolder isFixed={isFixed} isFullWidth={isFullWidth}>
      <AsideContent>
        <AsideTop>
          <AsideTopHolder>
            <AsideTopHolderNav>
              <AsideNav routes={routes} />
            </AsideTopHolderNav>
          </AsideTopHolder>
        </AsideTop>

        <AsideBottom />
      </AsideContent>
    </AsideHolder>
  );
};

const AsideHolder = ({ isFullWidth, isFixed, children }) => {
  const fixedStyles = {
    position: 'fixed',
    top: '72px',
    bottom: 0,
  };

  return (
    <Box
      as={'aside'}
      width={isFullWidth ? '100%' : '280px'}
      {...(isFixed && fixedStyles)}
      data-testid={'aside-holder'}
    >
      {children}
    </Box>
  );
};

const AsideContent = ({ children }) => (
  <Box
    display={'flex'}
    pt={10}
    pl={10}
    height={'100%'}
    flexDirection={'column'}
    position={'relative'}
    boxSizing={'border-box'}
    data-testid={'aside-content'}
  >
    {children}
  </Box>
);

const AsideTop = ({ children }) => (
  <Box
    display={'flex'}
    flexDirection={'column'}
    flexGrow={1}
    position={'relative'}
    overflow={'auto'}
    data-testid={'aside-top'}
  >
    {children}
  </Box>
);

const AsideTopHolder = ({ children }) => (
  <Box
    display={'flex'}
    minH={0}
    flexDirection={'column'}
    position={'relative'}
    overflow={'hidden'}
    data-testid={'aside-top-holder'}
  >
    {children}
  </Box>
);

const AsideTopHolderNav = ({ children }) => (
  <Box
    flexGrow={1}
    overflowY={'auto'}
    zIndex={0}
    data-testid={'aside-top-holder-nav'}
    willChange={'transform'}
  >
    {children}
  </Box>
);

const AsideBottom = ({ children }) => (
  <Box
    width={'100%'}
    flexShrink={0}
    alignSelf={'end'}
    boxSizing={'border-box'}
    data-testid={'aside-bottom'}
  >
    {children}
  </Box>
);

export default Aside;
