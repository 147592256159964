import { useSelectedObserveable, useSidebarObservable } from 'observeables';

export const useSelectedRepository = () => {
  const selectedObserveable = useSelectedObserveable();
  const sideBarMenuObserveable = useSidebarObservable();

  const select = async (payload) => {
    try {
      selectedObserveable.select(payload);
    } catch (error) {
      console.error('Error in updating edit area dimensons');
    } finally {
    }
  };

  const unSelect = async () => {
    try {
      selectedObserveable.unSelect();
      sideBarMenuObserveable.update('Media');
    } catch (error) {
      console.error('Error in updating edit area dimensons');
    } finally {
    }
  };

  const update = async (payload) => {
    try {
      selectedObserveable.update(payload);
    } catch (error) {
      console.error('Error in updating edit area dimensons');
    } finally {
    }
  };

  const getSelectedObservable = () => selectedObserveable.getObservable();

  return {
    select,
    unSelect,
    update,
    getSelectedObservable,
  };
};
