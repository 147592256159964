const Select = {
  parts: ['select', 'option', 'optgroup'],
  baseStyle: {
    select: {
      backgroundColor: 'charade',
      background: 'charade',
    },
    option: {
      backgroundColor: 'var(--chakra-colors-charade)',
      background: 'var(--chakra-colors-charade)',
    },
    optgroup: {
      backgroundColor: 'var(--chakra-colors-charade)',
      background: 'var(--chakra-colors-charade)',
    },
  },
};

export default Select;
