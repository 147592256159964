import { Icon } from '@chakra-ui/react';
import React from 'react';

export const ElementsIcon = (props) => (
  <Icon viewBox="0 0 31 29" {...props}>
    <g opacity="0.75">
      <mask id="path-1-inside-1_97_1209" fill="white">
        <rect x="4.61328" y="18.6429" width="10.6396" height="10.3571" rx="1" />
      </mask>
      <rect
        x="4.61328"
        y="18.6429"
        width="10.6396"
        height="10.3571"
        rx="1"
        stroke="currentColor"
        strokeWidth="3.5"
        mask="url(#path-1-inside-1_97_1209)"
      />
      <path
        d="M7.75328 1.96534C7.80117 1.88089 7.92285 1.8809 7.97074 1.96534L13.0626 10.9432C13.1099 11.0265 13.0497 11.1299 12.9539 11.1299H2.77011C2.67431 11.1299 2.61412 11.0265 2.66138 10.9432L7.75328 1.96534Z"
        stroke="currentColor"
        strokeWidth="1.75"
      />
      <path
        d="M11.6875 12.5945C12.5052 13.6695 13.798 14.3634 15.2529 14.3634C17.7255 14.3634 19.73 12.359 19.73 9.88634C19.73 7.4137 17.7255 5.40923 15.2529 5.40923C14.2115 5.40923 13.2532 5.76477 12.4927 6.36107L11.6178 4.84563C12.6399 4.10722 13.8956 3.67206 15.2529 3.67206C18.685 3.67206 21.4672 6.45429 21.4672 9.88634C21.4672 13.3184 18.685 16.1006 15.2529 16.1006C12.7917 16.1006 10.6646 14.6698 9.6582 12.5945H11.6875Z"
        fill="currentColor"
      />
      <mask id="path-4-inside-2_97_1209" fill="white">
        <rect x="17.418" y="17.513" width="12.6169" height="7.53246" rx="1" />
      </mask>
      <rect
        x="17.418"
        y="17.513"
        width="12.6169"
        height="7.53246"
        rx="1"
        stroke="currentColor"
        strokeWidth="3.5"
        mask="url(#path-4-inside-2_97_1209)"
      />
      <path
        d="M26.2693 2.7776C26.2693 2.64759 26.3746 2.54221 26.5046 2.54221C26.6346 2.54221 26.74 2.64759 26.74 2.7776V9.27435C26.74 9.40435 26.6346 9.50974 26.5046 9.50974C26.3746 9.50974 26.2693 9.40435 26.2693 9.27435V2.7776Z"
        fill="currentColor"
      />
      <path
        d="M23.3975 6.02597C23.2675 6.02597 23.1621 5.92058 23.1621 5.79058C23.1621 5.66058 23.2675 5.55519 23.3975 5.55519L29.8943 5.55519C30.0243 5.55519 30.1296 5.66058 30.1296 5.79058C30.1296 5.92058 30.0243 6.02597 29.8943 6.02597L23.3975 6.02597Z"
        fill="currentColor"
      />
    </g>
  </Icon>
);
