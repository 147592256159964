import { Box } from '@chakra-ui/react';
import Aside from 'layouts/video-editing/components/aside';
import Header from 'layouts/video-editing/components/header';
import React from 'react';

const Layout = ({ routes, children }) => {
  return (
    <LayoutWrapper>
      <LayoutHeader>
        <Header />
      </LayoutHeader>

      <LayoutAside>
        <Aside routes={routes} isFixed />
      </LayoutAside>

      <LayoutMain>
        <LayoutMainScroll>{children}</LayoutMainScroll>
      </LayoutMain>
    </LayoutWrapper>
  );
};

const LayoutWrapper = ({ children }) => (
  <Box
    display={'grid'}
    my={0}
    mx={'auto'}
    minH={'100vh'}
    maxW={'2560px'}
    gridTemplateColumns={'auto minmax(0, 1fr)'}
    gridTemplateRows={'0fr 0fr 1fr 0fr'}
    backgroundColor="woodsmoke2"
    color={'white'}
    data-testid="layoutwrapper"
  >
    {children}
  </Box>
);

const LayoutHeader = ({ children }) => (
  <Box
    width={'100%'}
    gridColumn={'1/3'}
    gridRow={'1/2'}
    position={'sticky'}
    top={0}
    zIndex={3}
    bg="woodsmoke"
    data-testid="layoutheader"
  >
    {children}
  </Box>
);

const LayoutAside = ({ children }) => (
  <Box
    width={'81px'}
    gridRow={'2/5'}
    gridColumn={'1/2'}
    zIndex={1}
    display={['block']}
    backgroundColor="woodsmoke2"
    data-testid="layoutaside"
  >
    {children}
  </Box>
);

const LayoutMain = ({ children }) => (
  <Box
    width={'100%'}
    gridRow={'3/4'}
    gridColumn={['1/3', '2/3']}
    zIndex={1}
    position={'relative'}
    backgroundColor="woodsmoke2"
  >
    {children}
  </Box>
);

const LayoutMainScroll = ({ children }) => (
  <Box
    width={'100%'}
    height={'100%'}
    position={'absolute'}
    overflow={'auto'}
    display="flex"
    backgroundColor="woodsmoke2"
    flex={1}
  >
    {children}
  </Box>
);

export default Layout;
