import { Button, Flex, Grid, Text } from '@chakra-ui/react';
import { Audioonly, Screenonly, Webcam } from 'assets/icons';
import { map } from 'lodash';
import React from 'react';

const RecordOptionsList = [
  {
    id: 1,
    text: 'Screen & Webcam',
    icon: Screenonly,
  },
  {
    id: 2,
    text: 'Webcam Only',
    icon: Webcam,
  },
  {
    id: 3,
    text: 'Screen Only',
    icon: Screenonly,
  },
  {
    id: 4,
    text: 'Audio Only',
    icon: Audioonly,
  },
];

function RecordOptions() {
  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={3} data-testid="filter-grid">
      {map(RecordOptionsList, (value) => {
        return (
          <Button
            key={`recordoptions${value.text}`}
            width={'116px'}
            height={'47px'}
            bg="charadeDark5"
            border="1px solid var(--chakra-colors-mako)"
            borderColor="mako"
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
              width={'116px'}
              height={'47px'}
            >
              <Text fontSize="xx-small" color="gray">
                {value.text}
              </Text>
              <value.icon fontSize={'18px'} />
            </Flex>
          </Button>
        );
      })}
    </Grid>
  );
}

export default RecordOptions;
