import { Button, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import {
  Device,
  Dropbox,
  Facebook,
  Googledrive,
  Instagram,
} from 'assets/icons';
import { SocialLoginButton } from 'components/SocialLoginButton';
import { useMediaUpload } from 'hooks/useMediaUpload';
import React from 'react';
import { useDropzone } from 'react-dropzone';

const UploadOptionsList = [
  {
    id: 1,
    text: 'Device',
    icon: Device,
  },
  {
    id: 2,
    text: 'Facebook',
    icon: Facebook,
  },
  {
    id: 3,
    text: 'Instagram',
    icon: Instagram,
  },
  {
    id: 4,
    text: 'Google Drive',
    icon: Googledrive,
  },
  {
    id: 5,
    text: 'Dropbox',
    icon: Dropbox,
  },
];

function UploadOptions() {
  const { onMediaUpload } = useMediaUpload();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onMediaUpload,
    accept: '.jpeg, .png, .mp4, .mkv, .acc, .mp3, .ogg, .mov, .json',
  });

  /**
   * 
   *  accept: {
      'image/*': '.jpeg, .png',
      'video/*': '.mp4, .mkv, .avi, .mov',
      'audio/*': '.mp3, .ogg, .acc',
    },

   */

  const handleSocialLogin = (user, err) => {};

  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={3} data-testid="filter-grid">
      <GridItem area={'header'} {...getRootProps({ className: 'dropzone' })}>
        <input display="hidden" {...getInputProps()} />
        <Button
          key={`uploadOptions-deviceupload`}
          width={'116px'}
          height={'47px'}
          bg="charadeDark5"
          border="1px solid var(--chakra-colors-mako)"
          borderColor="mako"
        >
          <Flex
            alignItems="center"
            justifyContent="space-between"
            width={'116px'}
            height={'47px'}
          >
            <Text fontSize="xx-small" color="gray">
              Device
            </Text>
            <Device fontSize={'18px'} />
          </Flex>
        </Button>
      </GridItem>
      <GridItem area={'nav'}>
        <SocialLoginButton
          provider="facebook"
          callback={handleSocialLogin}
          providerName="facebook"
          Icon={Facebook}
          onLoginSuccess={handleSocialLogin}
          onLoginFailure={handleSocialLogin}
          onLogoutSuccess={handleSocialLogin}
          key={'facebook'}
          appId={'495310775751554'}
          onInternetFailure={() => {
            return true;
          }}
        >
          Facebook
        </SocialLoginButton>
      </GridItem>
      <GridItem area={'Instagram'}>
        <SocialLoginButton
          provider="Instagram"
          callback={handleSocialLogin}
          providerName="Instagram"
          Icon={Instagram}
          onLoginSuccess={handleSocialLogin}
          onLoginFailure={handleSocialLogin}
          onLogoutSuccess={handleSocialLogin}
          key={'google'}
          appId={
            '600212301387-fgle4h0jjb6crjjifltormjg1p14qko2.apps.googleusercontent.com'
          }
          onInternetFailure={() => {
            return true;
          }}
        >
          Instagram
        </SocialLoginButton>
      </GridItem>
      <GridItem area={'Googledrive'}>
        <SocialLoginButton
          provider="google"
          callback={handleSocialLogin}
          providerName="google"
          Icon={Googledrive}
          onLoginSuccess={handleSocialLogin}
          onLoginFailure={handleSocialLogin}
          onLogoutSuccess={handleSocialLogin}
          key={'google'}
          appId={
            '600212301387-fgle4h0jjb6crjjifltormjg1p14qko2.apps.googleusercontent.com'
          }
          onInternetFailure={() => {
            return true;
          }}
        >
          Googledrive
        </SocialLoginButton>
      </GridItem>

      <GridItem area={'Dropbox'}>
        <Button
          key={`uploadOptions-deviceupload`}
          width={'116px'}
          height={'47px'}
          bg="charadeDark5"
          border="1px solid var(--chakra-colors-mako)"
          borderColor="mako"
        >
          <Flex
            alignItems="center"
            justifyContent="space-between"
            width={'116px'}
            height={'47px'}
          >
            <Text fontSize="xx-small" color="gray">
              Dropbox
            </Text>
            <Dropbox fontSize={'18px'} />
          </Flex>
        </Button>
      </GridItem>

      {/* {map(UploadOptionsList, (value) => {
        return (
          <Button
            key={`uploadOptions${value.text}`}
            width={'116px'}
            height={'47px'}
            bg="charadeDark5"
            border="1px solid var(--chakra-colors-mako)"
            borderColor="mako"
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
              width={'116px'}
              height={'47px'}
            >
              <Text fontSize="xx-small" color="gray">
                {value.text}
              </Text>
              <value.icon fontSize={'18px'} />
            </Flex>
          </Button>
        );
      })} */}
    </Grid>
  );
}

export default UploadOptions;
