import { BehaviorSubject } from 'rxjs';

const initialState = {
  width: 1920,
  height: 1080,
};

const editAreaSubject = new BehaviorSubject(initialState);

export const useEditAreaObservable = () => {
  const update = (payload) => {
    setNextState({ ...payload, error: '' });
  };

  const updating = (flag) => {
    setNextState({ updating: flag });
  };

  const removing = (flag) => {
    setNextState({ removing: flag });
  };

  const error = (message) => {
    setNextState({ error: message });
  };

  const setNextState = (payload) => {
    const state = editAreaSubject.getValue();
    editAreaSubject.next({ ...state, ...payload });
  };

  const getObservable = () => {
    return editAreaSubject;
  };

  return {
    update,
    updating,
    removing,
    error,
    getObservable,
  };
};
