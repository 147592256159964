import { Box, Text, Stack, Progress } from '@chakra-ui/react';
import React from 'react';
import { Thumbnail } from 'assets/images/thumbnail';

export const FileUploaderSkeleton = ({ progress = 0 }) => {
  return (
    <Stack width={'120px'}>
      <Thumbnail width={'116px'} height={'71px'} />
      <>
        <Box
          display="inline-flex"
          mt={'0 !important'}
          data-testid="progressbox"
          border={'0.5px solid black'}
        >
          <Progress
            colorScheme="blue"
            value={progress}
            hasStripe
            size={'md'}
            width={'120px'}
            px={1}
            mt={0}
          />
          <Text fontSize="xx-small" align="center" color="pearl" pr={3}>
            {progress}%
          </Text>
          <Box px={1} position="absolute">
            <Text px={1} fontSize="xx-small" align="center" color="white">
              uploading...
            </Text>
          </Box>
        </Box>
      </>
    </Stack>
  );
};
