import { Icon } from '@chakra-ui/react';
import React from 'react';

export const Voicesquare = (props) => (
  <Icon viewBox="0 0 20 19" {...props}>
    <g>
      <path
        d="M6.17391 15.7826H10.6087C14.3043 15.7826 15.7826 14.3043 15.7826 10.6087V6.17391C15.7826 2.47826 14.3043 1 10.6087 1H6.17391C2.47826 1 1 2.47826 1 6.17391V10.6087C1 14.3043 2.47826 15.7826 6.17391 15.7826Z"
        stroke="#A8A8A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.39169 18H13.8265C17.5221 18 19.0004 16.5217 19.0004 12.8261V8.39131C19.0004 4.69565 17.5221 3.21739 13.8265 3.21739H9.39169C5.69603 3.21739 4.21777 4.69565 4.21777 8.39131V12.8261C4.21777 16.5217 5.69603 18 9.39169 18Z"
        fill="#262730"
        stroke="#A8A8A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.17383 9.02695V12.1978"
        stroke="#A8A8A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.3916 7.97V13.2474"
        stroke="#A8A8A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6084 6.91304V14.3043"
        stroke="#A8A8A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8262 7.97V13.2474"
        stroke="#A8A8A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0439 9.02695V12.1978"
        stroke="#A8A8A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </Icon>
);
