import { useMediaMenuDrawerObservable } from 'observeables';

export const useMediaMenuDrawerRepository = () => {
  const mediaMenuDrawerObserveable = useMediaMenuDrawerObservable();

  const update = async (payload) => {
    try {
      mediaMenuDrawerObserveable.update(payload);
    } catch (error) {
      mediaMenuDrawerObserveable.error('Error in updating edit area dimensons');
    } finally {
    }
  };

  const getObservable = () => mediaMenuDrawerObserveable.getObservable();

  return {
    update,
    getObservable,
  };
};
