import { Icon } from '@chakra-ui/react';
import React from 'react';

export const Device = (props) => (
  <Icon viewBox="0 0 20 17" {...props}>
    <path
      d="M17.2 2.875H12.169C11.9329 2.87396 11.7066 2.77632 11.539 2.60313L10.261 1.27187C10.0934 1.09868 9.86711 1.00104 9.631 1H2.8C1.80589 1 1 1.83947 1 2.875V14.125C1 15.1605 1.80589 16 2.8 16H17.2C18.1941 16 19 15.1605 19 14.125V4.75C19 3.71447 18.1941 2.875 17.2 2.875Z"
      stroke="#01CCA6"
      strokeWidth="2"
    />
  </Icon>
);
