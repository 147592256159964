import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { map } from 'lodash';
import React from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import { PixelsComponent } from './PixelsComponent';

const OverlayList = [
  {
    id: '7c7d11ef-c977-41da-8d48-8abcdddfeab0',
    text: 'Normal',
    imgSrc:
      'https://eyecastvideoeditorfiles.s3.ap-southeast-2.amazonaws.com/assets/normaloverlay.png',
  },
  {
    id: 'b6da67e3-56db-432c-b7fc-8917429e0222',
    text: 'Filmstrip',
    imgSrc:
      'https://eyecastvideoeditorfiles.s3.ap-southeast-2.amazonaws.com/assets/pixi-film-overlay.png',
  },
  {
    id: '145d92e4-1e62-4520-9088-d567e5d7422f',
    text: 'Lensflare',
    imgSrc:
      'https://eyecastvideoeditorfiles.s3.ap-southeast-2.amazonaws.com/assets/overlays/Eyecast_Lensflare.png',
  },
  {
    id: 'f4002c85-fb0b-45ce-8c22-cb4403ee7423',
    text: 'Grunge',
    imgSrc:
      'https://eyecastvideoeditorfiles.s3.ap-southeast-2.amazonaws.com/assets/overlays/Eyecast_Grunge.png',
  },
  {
    id: '7536e2db-c579-4000-9d54-7defc4ce484d',
    text: 'Bricks',
    imgSrc:
      'https://eyecastvideoeditorfiles.s3.ap-southeast-2.amazonaws.com/assets/overlays/Eyecast_brick.png',
  },
];

export const OverlayByPixelsGrid = ({
  onClick,
  onViewAllClick,
  selectedUniqueId,
  selectedOverlay,
}) => {
  return (
    <Flex
      wrap="wrap" // Allows wrapping of items
      gap={2} // Set the gap between items
      data-testid="overlay-grid"
      justifyContent="start" // Center items horizontally when wrapping
    >
      {map(OverlayList, (value) => {
        return (
          <GridItem
            key={`pixeslsgrid-${value.id}`}
            id={value.id}
            position={'relative'}
          >
            <Box
              key={value.id}
              fontSize="25px"
              justifyContent="center"
              alignItems="center"
              borderRadius={5}
              data-testid="filter-grid-box"
              onClick={(e) => {
                e.stopPropagation();
                onClick(value.text.toLowerCase());
              }}
            >
              <PixelsComponent filter={value.text} imgSource={value.imgSrc} />
            </Box>
            <Text mt={1} fontSize="xx-small" align="center" color="pearl">
              {value?.text}
            </Text>
            <Box position={'absolute'} right={1} bottom={'26%'}>
              {selectedUniqueId && selectedOverlay === value.text && (
                <CheckCircleIcon color="azure" />
              )}
            </Box>
          </GridItem>
        );
      })}
      <GridItem>
        <Box
          as={Button}
          w="90px"
          h="50px"
          bg="charadeDark3"
          fontSize="37px"
          justifyContent="center"
          display="flex"
          alignItems="center"
          borderRadius={5}
          color="azure"
          data-testid="shape-grid-box"
          // onClick={onViewAllClick}
        >
          <IconButton icon={<FiMoreHorizontal />} />
        </Box>
        <Text fontSize="xx-small" align="center" color="pearl">
          View all
        </Text>
      </GridItem>
    </Flex>
  );
};
