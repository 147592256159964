import { Icon } from '@chakra-ui/react';
import React from 'react';

export const Googledrive = (props) => (
  <Icon viewBox="0 0 20 17" {...props}>
    <g clipPath="url(#clip0_83_146)">
      <path
        d="M1.51203 14.5528L2.39406 16.0004C2.57734 16.3052 2.84078 16.5447 3.15008 16.7188C4.03602 15.6503 4.65258 14.8303 5.00008 14.2589C5.35266 13.679 5.78602 12.7719 6.30016 11.5377C4.91453 11.3644 3.86453 11.2778 3.15016 11.2778C2.46445 11.2778 1.41445 11.3644 0 11.5377C0 11.8751 0.0916406 12.2125 0.274922 12.5174L1.51203 14.5528Z"
        fill="#0066DA"
      />
      <path
        d="M16.8505 16.7188C17.1599 16.5447 17.4233 16.3052 17.6065 16.0004L17.9731 15.4018L19.7258 12.5174C19.9056 12.2192 20.0004 11.8815 20.0006 11.5377C18.5779 11.3644 17.5299 11.2778 16.8563 11.2778C16.1324 11.2778 15.0843 11.3644 13.7119 11.5377C14.22 12.7787 14.6476 13.6858 14.9949 14.2589C15.3451 14.8371 15.9636 15.657 16.8505 16.7188Z"
        fill="#EA4335"
      />
      <path
        d="M10.0007 5.4423C11.0257 4.26603 11.7321 3.35895 12.1198 2.72119C12.4321 2.20762 12.7758 1.38762 13.1508 0.261236C12.8415 0.0870786 12.4864 0 12.1198 0H7.88152C7.51496 0 7.15996 0.0979913 6.85059 0.261236C7.32762 1.55309 7.73246 2.4725 8.06496 3.01939C8.43246 3.62382 9.0777 4.43143 10.0007 5.4423Z"
        fill="#00832D"
      />
      <path
        d="M13.6993 11.5378H6.29941L3.14941 16.7188C3.45863 16.893 3.81371 16.9801 4.18027 16.9801H15.8184C16.185 16.9801 16.5401 16.8821 16.8493 16.7188L13.6993 11.5378H13.6993Z"
        fill="#2684FC"
      />
      <path
        d="M10.0001 5.44228L6.85008 0.261292C6.5407 0.435449 6.27727 0.674859 6.09398 0.979671L0.274922 10.5581C0.0950098 10.8563 0.000237693 11.194 0 11.5377H6.30016L10.0002 5.44228H10.0001Z"
        fill="#00AC47"
      />
      <path
        d="M16.8156 5.76886L13.906 0.97961C13.7228 0.674798 13.4593 0.435388 13.15 0.26123L10 5.4423L13.6998 11.5377H19.9886C19.9886 11.2002 19.897 10.8629 19.7137 10.5581L16.8156 5.76886Z"
        fill="#FFBA00"
      />
    </g>
    <defs>
      <clipPath id="clip0_83_146">
        <rect width="20" height="17" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
