import { BehaviorSubject } from 'rxjs';

const initialState = {
  play: false,
  playedSeconds: 0,
  loadedSeconds: 0,
  isSeeking: false,
};

const playerSubject = new BehaviorSubject(initialState);

export const usePlayerObservable = () => {
  const update = (payload) => {
    setNextState({ ...payload, error: '' });
  };

  const updating = (flag) => {
    setNextState({ updating: flag });
  };

  const removing = (flag) => {
    setNextState({ removing: flag });
  };

  const error = (message) => {
    setNextState({ error: message });
  };

  const setNextState = (payload) => {
    const state = playerSubject.getValue();
    playerSubject.next({ ...state, ...payload });
  };

  const getObservable = () => {
    return playerSubject;
  };

  return {
    update,
    updating,
    removing,
    error,
    getObservable,
  };
};
