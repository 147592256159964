import { BehaviorSubject } from 'rxjs';

const initialState = {
  value: null,
  scale: 5,
  scaleSplitCount: 10,
  scaleWidth: 160,
  startLeft: 5,
  listing: false,
  creating: false,
  updating: false,
  removing: false,
  error: '',
  isLoaded: false,
};

const userScrubberBarSubject = new BehaviorSubject(initialState);

export const useScrubberBarObserveable = () => {
  const update = (payload) => {
    setNextState({ ...payload, error: '' });
  };

  const zoomInScale = () => {
    const state = userScrubberBarSubject.getValue();
    let { scale } = state;
    if (state.scale === 1) {
      scale -= -0.5;
    } else if (state.scale > 1) {
      scale -= 1;
    }
    setNextState({ scale });
  };

  const zoomOutScale = () => {
    const state = userScrubberBarSubject.getValue();
    let { scale } = state;
    if (state.scale === 0.5) {
      scale = 1;
    } else if (state.scale < 15) {
      scale += 1;
    }
    setNextState({ scale });
  };

  const updating = (flag) => {
    setNextState({ updating: flag });
  };

  const removing = (flag) => {
    setNextState({ removing: flag });
  };

  const error = (message) => {
    setNextState({ error: message });
  };

  const setNextState = (payload) => {
    const state = userScrubberBarSubject.getValue();
    userScrubberBarSubject.next({ ...state, ...payload });
  };

  const getObservable = () => {
    return userScrubberBarSubject;
  };

  return {
    update,
    zoomInScale,
    zoomOutScale,
    updating,
    removing,
    error,
    getObservable,
  };
};
