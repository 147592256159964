import React, { useLayoutEffect, useState } from 'react';
// import hash from 'hash-it';
// import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, shareReplay } from 'rxjs';
import isEqual from 'lodash/isEqual';
import { map } from 'rxjs/operators';

export function useObservable(stateSubject) {
  const [state, setState] = useState(stateSubject.getValue());

  useLayoutEffect(() => {
    const subscription = stateSubject
      .pipe
      // map((s) => hash(s)),
      ()
      .subscribe((currentState) => {
        setState(currentState);
      });

    return () => subscription.unsubscribe();
  }, []);

  return state;
}

export function useObservableWithDebounce(stateSubject) {
  const [state, setState] = useState(stateSubject.getValue());

  useLayoutEffect(() => {
    const subscription = stateSubject
      .pipe(
        // map((s) => hash(s)),
        distinctUntilChanged(),
        debounceTime(10),
        shareReplay(1)
      )
      .subscribe((currentState) => {
        setState(currentState);
      });

    return () => subscription.unsubscribe();
  }, []);

  return state;
}

export function useObservableWithPipe(stateSubject, tagged = '') {
  const [state, setState] = useState(stateSubject.getValue());
  useLayoutEffect(() => {
    const subscription = stateSubject
      .pipe
      // map((s) => hash(s)),
      // distinctUntilChanged(),
      // shareReplay(1)
      ()
      .subscribe((currentState) => {
        setState(currentState);
      });

    return () => subscription.unsubscribe();
  }, []);

  return state;
}

export function useObservableForTitle(stateSubject, tagged = '') {
  const [title, setTitle] = useState(stateSubject.getValue()?.title || '');
  useLayoutEffect(() => {
    const subscription = stateSubject
      .pipe(
        map((state) => state?.title) // Extract 'title' property with optional chaining
      )
      .subscribe((currentTitle) => {
        setTitle(currentTitle);
      });

    return () => subscription.unsubscribe();
  }, []);

  return title;
}

export function useObservableEditItems(stateSubject) {
  const [state, setState] = useState(stateSubject.getValue());
  useLayoutEffect(() => {
    const subscription = stateSubject
      .pipe(
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)) // Apply deep comparison
      )
      .subscribe((currentState) => {
        setState(currentState);
      });

    return () => subscription.unsubscribe();
  }, []);

  return state;
}
