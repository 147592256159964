import { extendTheme } from '@chakra-ui/react';
// Component style overrides
import Button from './components/button';
import ButtonGroup from './components/button-group';
import Input from './components/input';
import Link from './components/link';
import Menu from './components/menu';
import Modal from './components/modal';
import Select from './components/select';
import Switch from './components/switch';
import Tabs from './components/tabs';
// Global style overrides
import styles from './styles';

const overrides = {
  ...styles,
  components: {
    Button,
    Input,
    Switch,
    Link,
    Modal,
    ButtonGroup,
    Tabs,
    Menu,
    Select,
  },
};

export default extendTheme(overrides);
