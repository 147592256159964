/* eslint-disable no-loop-func */
/* eslint-disable react/jsx-no-bind */
import { Box, Flex } from '@chakra-ui/react';
import { Timeline } from 'reacthub-timeline-editor';
import {
  debounce,
  find,
  findIndex,
  get,
  isEmpty,
  map,
  reduce,
  some,
  times,
  orderBy,
} from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { BsEyeSlashFill } from 'react-icons/bs';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  HiVolumeUp,
  HiVolumeOff,
  HiEye,
  HiEyeOff,
  HiLockClosed,
  HiLockOpen,
} from 'react-icons/hi';
import { CustomRender } from './CustomRenders';
import { mockEffect } from './mock';
import { ScrubberRowAction } from './ScrubberRowAction';
import {
  GsapPixieContext,
  Events,
  emitCustomEvent,
  useCustomEventListener,
} from 'reacthub-pixitweenjs';

import useRefDimensions from 'hooks/useRefDimensions';
import { ErrorBoundary } from '../..';
import { nanoid } from 'nanoid';
import useDebounce from 'hooks/useDebounce';

export const TimelineScrubber = ({
  scrubberData,
  handleOnLayerLock,
  handleOnLayerVisible,
  handleOnLayerMute,
  handleOnSelectedKeyFrame,
  selectedKeyFrame = {},
  handleOnScrubberUpdate,
  handleOnAfterChange,
  handleOnBeforeChange,
  handleOnDeleteAction,
  handleOnDeselectKeyFrame,
  handleOnScrubberRowUpdate,
  totalVideoSeconds,
  minRow,
  maxRow,
  scrubberBarMeta,
  updateVideoPlayerState,
}) => {
  const { scale, scaleSplitCount, scaleWidth, startLeft } = scrubberBarMeta;
  const [collision, setCollision] = React.useState({});
  const [data, setData] = useState([]);
  const timelineScrubberRef = useRef(null);
  const dimensions = useRefDimensions(timelineScrubberRef);
  const { width: dimWidth } = dimensions || {};
  const timelineStateRef = useRef(null);
  const rowMenusRef = useRef(null);
  const timelineEngineTimeRef = useRef(0.0);
  const autoScrollWhenPlay = useRef(true);
  const { playerTimeRef, tl: timeline } = React.useContext(GsapPixieContext);
  const rafRef = React.useRef();
  const seekBarDraggedDurationRef = React.useRef(0);

  // write debounce to update the data drop end
  /// const debounceDroppedData = useDebounce(dropDataEnd, 0);

  const updateSlider = () => {
    const currentProgressTime = playerTimeRef.current; //Math.round(playerTimeRef.current, 2);
    if (
      currentProgressTime >= 0 &&
      timelineStateRef.current &&
      timelineStateRef.current.setTime
    ) {
      timelineStateRef.current.setTime(currentProgressTime);
    }
    /// sliderValueRef.current = Math.round(timeline.progress() * 50);
  };

  const animate = (time) => {
    if (playerTimeRef && playerTimeRef.current >= 0) {
      updateSlider();
    }
    rafRef.current = requestAnimationFrame(animate);
  };

  React.useEffect(() => {
    rafRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(rafRef.current);
  }, []); //

  useCustomEventListener(Events.COMPLETE, () => {
    timelineStateRef.current.setTime(0);
  });

  // event listner
  // useCustomEventListener('SCRUBBER_PLAY', () => {
  //   /// timelineStateRef.current.play({ autoEnd: true });
  // });

  // useCustomEventListener('SCRUBBER_TIME_UPDATE', (playedToSeconds) => {
  //   const newsec =
  //     playedToSeconds > 0
  //       ? Math.min(playedToSeconds + 0.3, totalVideoSeconds)
  //       : playedToSeconds;
  //   timelineStateRef.current.setTime(newsec);
  // });

  function getWindowWidth() {
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    return Math.max(window.innerWidth - scrollbarWidth - 300, 600);
  }

  useEffect(() => {
    if (!timelineStateRef.current) return;
    const listener = timelineStateRef.current.listener;
    listener.on('play', () => {});
    listener.on('paused', () => {
      // setIsPlaying(false);
    });
    listener.on('afterSetTime', ({ time }) => {
      onUpdateEngineTime(time);
      // setTime(time);
      if (autoScrollWhenPlay.current) {
        const autoScrollFrom = getWindowWidth() || window?.innerWidth || 600;
        const left = time * (scaleWidth / scale) + startLeft - autoScrollFrom;
        timelineStateRef.current.setScrollLeft(left);
      }
    });
    listener.on('setTimeByTick', ({ time }) => {
      // setTime(time);
      // if (autoScrollWhenPlay.current) {
      //   const autoScrollFrom = 500;
      //   const left = time * (scaleWidth / scale) + startLeft - autoScrollFrom;
      //   timelineStateRef.current.setScrollLeft(left);
      // }
    });
    return () => {
      if (listener && listener.current) {
        listener.current.pause();
        listener.offAll();
      }
    };
  }, []);

  const handleOnTimelineCursorChangeEnd = React.useCallback((value) => {
    const totalSeconds = timeline.current.totalDuration() - 0.2;
    let maxCurrentTimeValue = Math.max(0.001, Number(value));
    maxCurrentTimeValue = Math.max(
      0,
      Math.min(maxCurrentTimeValue, totalSeconds)
    );
    // emitCustomEvent(PlayerEvents.SCRUBBER_PAUSE);
    emitCustomEvent(Events.SCRUBBER_SEEK, maxCurrentTimeValue);
    emitCustomEvent(Events.REVERSE_MODE_END, maxCurrentTimeValue);
    playerTimeRef.current = maxCurrentTimeValue;
    // timeline.current && timeline.current.progress(progress);
    timeline.current && timeline.current.time(maxCurrentTimeValue);
  }, []);

  const onUpdateEngineTime = (currTime) => {
    if (currTime >= 0) {
      timelineEngineTimeRef.current = currTime;
    }
    let now = playerTimeRef.current; // timeline.current.time();
    let elapsedTime;
    if (timelineEngineTimeRef.current) {
      elapsedTime = Math.abs(now - timelineEngineTimeRef.current);
      if (elapsedTime > 0.5) {
        handleOnTimelineCursorChangeEnd(currTime);
      }
    }
  };

  React.useEffect(() => {
    const rowBaseData = [];
    const extraRows = maxRow + 10;

    let n = 1;

    while (n < extraRows) {
      const scrubData = scrubberData.filter(
        (f) => f.scrubberRow === n && f.droptype !== 'background'
      );
      if (!isEmpty(scrubData)) {
        const rowId = {
          id: n,
          selected: false,
        };
        let clipId = '';
        let visible = true;
        let locked = false;
        let mute = false;
        const actions = map(scrubData, (d) => {
          const startAt = d.start / 1000;
          const endAt = d.end / 1000;
          const isSelected = selectedKeyFrame?.uniqueId === d.uniqueId;
          const isLocked = d.locked;
          const isVisible = d.visible;
          // these values are overwritted when composing the use scrubber data hook;
          clipId = d.clipId;
          visible = d.visible;
          locked = d.locked;
          mute = d.mute;
          return {
            id: d.uniqueId,
            start: startAt,
            end: endAt,
            effectId: n % 2 === 0 ? 'effect0' : 'effect0',
            selected: isSelected,
            disable: isLocked,
            moveable: !isLocked,
            flexible: !isLocked,
            visible: isVisible,
            locked: isLocked,
            droptype: d.droptype,
            zIndex: get(d, 'transformation.zIndex', 1),
            scrubberRow: d.scrubberRow,
            src: d.src,
          };
        });
        rowId.actions = actions;
        rowId.clipId = clipId;
        rowId.visible = visible;
        rowId.locked = locked;
        rowId.mute = mute;
        rowId.uniqueId = nanoid(10);
        rowId.scrubberRow = n;
        rowBaseData.push(rowId);
      } else {
        const rowIdEmpty = {
          id: n,
          selected: false,
          zIndex: 0,
          uniqueId: nanoid(10),
          visible: true,
          locked: false,
          mute: false,
          scrubberRow: n,
          clipId: null,
        };
        rowIdEmpty.actions = [];
        rowBaseData.push(rowIdEmpty);
      }
      n++;
    }

    /** Drop me here logic on scrubber */
    if (!isEmpty(collision)) {
      const { rowId } = collision;
      const rowDetails = find(rowBaseData, (f) => f.id === Number(rowId));
      const indexRow = findIndex(rowBaseData, (f) => f.id === Number(rowId));
      const rowActions = get(rowDetails, 'actions', []);
      const maxEnd = reduce(
        rowActions,
        (acc, curr) => {
          const nextEnd = Math.max(Number(acc), Number(curr.end));
          return nextEnd;
        },
        0
      );
      //find(rowActions, (f) => f.id === actionId);
      if (maxEnd) {
        const tempActionItem = {
          id: nanoid(10),
          start: Number(maxEnd) + 0.1,
          end: Number(maxEnd) + 0.1 + 5,
          effectId: 'effect0',
          selected: false,
          disable: false,
          moveable: false,
          visible: true,
          locked: false,
          droptype: 'temp',
        };
        // update rowbase Data for tempdata
        rowBaseData[indexRow].actions.push(tempActionItem);
      }
    }
    setData(rowBaseData);
  }, [scrubberData, selectedKeyFrame, maxRow, collision]);

  /**
   * A wrapper function to handle scrubber update
   * Update the timelie progress and playerTimeRef
   * @param {A} time
   */
  const handleOnSeekUpdate = (time) => {
    const maxTime = Math.max(0.001, Number(time));
    playerTimeRef.current = maxTime;
    // timeline.current && timeline.current.progress(progress);
    timeline.current && timeline.current.time(maxTime);
    emitCustomEvent(Events.SCRUBBER_PAUSE);
    // const newsec = Math.min(time + 0.3, totalVideoSeconds);
    // timelineStateRef.current.setTime(newsec);
    emitCustomEvent(Events.SCRUBBER_SEEK, maxTime);
    const nTotSeconds =
      totalVideoSeconds && totalVideoSeconds > 0 ? totalVideoSeconds : 1;
    const progress = time / nTotSeconds;
    playerTimeRef.current = maxTime;
    timeline.current && timeline.current.progress(progress);
    updateVideoPlayerState({ play: false, playedSeconds: time });
  };

  const handleTimelineForMoveEnd = (time) => {
    const maxTime = Math.max(0.001, Number(time));

    // reset time to its current position when move end.
    const nTotSeconds =
      totalVideoSeconds && totalVideoSeconds > 0 ? totalVideoSeconds : 1;
    const progress = maxTime / nTotSeconds;
    playerTimeRef.current = maxTime;
    timeline.current && timeline.current.progress(progress);
    emitCustomEvent(Events.SCRUBBER_SEEK, maxTime);
    updateVideoPlayerState({ play: false, playedSeconds: maxTime });
    emitCustomEvent('TIMELINE_SHIFT_END', { time: maxTime, progress });
  };

  const handleOnMoveEnd = (params) => {
    if (isEmpty(collision)) {
      emitCustomEvent(Events.SCRUBBER_PAUSE);
      handleOnScrubberUpdate(params, false);
    }
    setCollision({});
  };

  const handleOnResizeEnd = (params) => {
    if (isEmpty(collision)) {
      // console.log('handleOnResizeEnd', params);
      emitCustomEvent(Events.SCRUBBER_PAUSE);
      handleOnScrubberUpdate(params, true);
      handleTimelineForMoveEnd(playerTimeRef.current);
    }
    setCollision({});
  };

  const handleOnClickRow = (evt, params) => {
    emitCustomEvent(Events.SCRUBBER_PAUSE);
    const {
      action: { id },
      /// time,
    } = params;
    /// handleOnSeekUpdate(time);
    handleOnSelectedKeyFrame(id);
  };

  const handleOnDeselect = (evt, params) => {
    emitCustomEvent(Events.SCRUBBER_PAUSE);
    const { time } = params;
    handleOnSeekUpdate(time);
    handleOnDeselectKeyFrame();
  };

  const handleOnContextMenuRow = (evt, params) => {
    emitCustomEvent(Events.SCRUBBER_PAUSE);
    const { time } = params;
    handleOnSeekUpdate(time);
  };

  const handleOnMoveStart = (params) => {
    emitCustomEvent(Events.SCRUBBER_PAUSE);
  };

  const handleOnResizeStart = (params) => {
    emitCustomEvent(Events.SCRUBBER_PAUSE);
  };

  const handleOnCursorDragStart = (time) => {
    emitCustomEvent(Events.SEEK_START);
    handleOnBeforeChange(time);
    seekBarDraggedDurationRef.current = Date.now(); // Record the start timestamp
  };

  const handleOnCursorDragEnd = (time) => {
    emitCustomEvent(Events.SEEK_END);
    emitCustomEvent(Events.REVERSE_MODE_END, time);
    handleOnSeekUpdate(time);
    handleOnAfterChange(time);
  };

  const handleOnCursorDrag = (time) => {
    const nTotSeconds =
      totalVideoSeconds && totalVideoSeconds > 0 ? totalVideoSeconds : 1;
    const progress = time / nTotSeconds;
    if (time < playerTimeRef.current) {
      emitCustomEvent(Events.REVERSE_MODE_START, time);
    }
    playerTimeRef.current = time;
    timeline.current && timeline.current.progress(progress);
    handleOnSeekUpdate(time);
    /// emitCustomEvent(Events.SCRUBBER_PROGRESS_UPDATE, percent);
  };

  const handleOnCollisionActive = (rowId, actionId) => {
    setCollision({ rowId, actionId });
  };

  const handleOnCollisionInActive = () => {
    setCollision({});
  };

  return (
    <Flex flexDir="column" ref={timelineScrubberRef}>
      {/* <Flex>
        <TimelinePlayer
          timelineState={timelineStateRef}
          autoScrollWhenPlay={autoScrollWhenPlay}
          play={play}
        />
      </Flex> */}
      <Flex
        flex={1}
        justifyContent="space-between"
        data-testid="timeline-scrubberwrapper"
      >
        <Flex
          flexDir="column"
          width="150px"
          marginTop="42px"
          overflowY="auto"
          padding="0 10px"
          ref={rowMenusRef}
          flex="0 1 auto"
          id="scrollcontainer"
          onScroll={(e) => {
            const target = e.target;
            timelineStateRef.current.setScrollTop(target.scrollTop);
          }}
          data-testid="timeline-menu-rwrapper"
        >
          {data.map((item) => {
            const dataItem = scrubberData.filter((f) => {
              const { actions } = item;
              const isIdExists = actions.some((act) => act.id === f.uniqueId);
              return isIdExists;
            });
            const isAudio = some(dataItem, (d) => d.droptype === 'audios');
            const isVideoOrAudio =
              isAudio || some(dataItem, (d) => d.droptype === 'videos');

            return (
              <Flex
                height="32px"
                padding="2px"
                className="timeline-list-item"
                key={item.id}
                justifyContent="flex-start"
                alignItems="center"
              >
                <ScrubberRowAction
                  onClick={() => {
                    emitCustomEvent(Events.SCRUBBER_PAUSE);
                    updateVideoPlayerState({
                      play: false,
                      playedSeconds: playerTimeRef.current,
                    });
                    handleOnLayerLock({ ...item, locked: !item.locked });
                  }}
                  active={item.locked}
                  icon={item.locked ? HiLockClosed : HiLockOpen}
                  title="lock/unlock"
                />
                {!isAudio && (
                  <ScrubberRowAction
                    onClick={() => {
                      emitCustomEvent(Events.SCRUBBER_PAUSE);
                      updateVideoPlayerState({
                        play: false,
                        playedSeconds: playerTimeRef.current,
                      });
                      handleOnLayerVisible({ ...item, visible: !item.visible });
                    }}
                    active={!item.visible}
                    icon={!item.visible ? HiEyeOff : HiEye}
                    title="hide/show"
                  />
                )}
                {isVideoOrAudio && (
                  <ScrubberRowAction
                    onClick={() => {
                      emitCustomEvent(Events.SCRUBBER_PAUSE);
                      updateVideoPlayerState({
                        play: false,
                        playedSeconds: playerTimeRef.current,
                      });
                      handleOnLayerMute({ ...item, mute: !item.mute });
                    }}
                    active={item.mute}
                    icon={item.mute ? HiVolumeOff : HiVolumeUp}
                    title="mute/unmute"
                  />
                )}
              </Flex>
            );
          })}
        </Flex>
        <Flex flex={1}>
          <DndProvider backend={HTML5Backend}>
            <Box
              sx={{
                '& .timeline-editor': {
                  width: '3200px',
                  height: '100%',
                  bgColor: 'shark',
                  fontFamily: 'var(--chakra-fonts-body)',
                  color: 'var(--chakra-colors-chakra-body-text)',
                  '& .ReactVirtualized__Grid': {
                    width: `3200px !important`,
                  },
                },
                '& .timeline-editor-edit-row': {
                  backgroundImage: 'shark',
                  backgroundColor: 'shark',
                  '&:nth-of-type(odd)': {
                    backgroundColor: 'darkShark',
                  },
                },
                '& .timeline-editor-action-selected': {
                  border: '1px solid var(--chakra-colors-corn)',
                },
                '& .timeline-editor-cursor-area': {
                  cursor: 'pointer',
                },
                '& .timeline-editor-action': {
                  height: '31px !important',
                  '&.timeline-editor-action-selected .timeline-editor-action-left-stretch::after':
                    {
                      borderLeftColor: 'corn',
                    },
                  '&.timeline-editor-action-selected .timeline-editor-action-right-stretch::after':
                    {
                      borderRightColor: 'corn',
                    },
                },
              }}
              data-testid="timeline-wrapper"
            >
              <ErrorBoundary>
                <Timeline
                  ref={timelineStateRef}
                  autoScroll
                  autoReRender
                  onChange={setData}
                  editorData={data}
                  effects={mockEffect}
                  scale={scale}
                  startLeft={startLeft}
                  scaleSplitCount={scaleSplitCount}
                  scaleWidth={scaleWidth}
                  onActionMoveStart={handleOnMoveStart}
                  onActionMoveEnd={handleOnMoveEnd}
                  onActionResizeStart={handleOnResizeStart}
                  onActionResizeEnd={handleOnResizeEnd}
                  onClickActionOnly={handleOnClickRow}
                  onContextMenuAction={handleOnContextMenuRow}
                  gridSnap
                  dragLine
                  style={{ backgroundColor: 'shark' }}
                  getActionRender={(action, row) => (
                    <CustomRender
                      action={action}
                      row={row}
                      collision={collision}
                      handleOnDeleteAction={handleOnDeleteAction}
                    />
                  )}
                  onCursorDragStart={handleOnCursorDragStart}
                  onCursorDragEnd={handleOnCursorDragEnd}
                  onCursorDrag={handleOnCursorDrag}
                  onDoubleClickAction={handleOnDeselect}
                  onCollisionActive={handleOnCollisionActive}
                  onCollisionInActive={handleOnCollisionInActive}
                  onClickTimeArea={(time, e) => {
                    console.log('on click time area', time);
                  }}
                />
              </ErrorBoundary>
            </Box>
          </DndProvider>
        </Flex>
      </Flex>
    </Flex>
  );
};
