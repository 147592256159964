import { BehaviorSubject } from 'rxjs';

const mediaMenuDrawerSubject = new BehaviorSubject({
  isOpen: false,
});

export const useMediaMenuDrawerObserveable = () => {
  const update = (payload) => {
    setNextState({ ...payload, error: '' });
  };

  const setNextState = (payload) => {
    const state = mediaMenuDrawerSubject.getValue();
    mediaMenuDrawerSubject.next({ ...state, ...payload });
  };

  const getObservable = () => {
    return mediaMenuDrawerSubject;
  };

  return {
    update,
    getObservable,
  };
};
