import { BehaviorSubject } from 'rxjs';

const initialState = {
  activeMenu: 'Media',
  isOpen: true,
  listing: false,
  creating: false,
  updating: false,
  removing: false,
  error: '',
};

const sidebarSubject = new BehaviorSubject(initialState);

export const useSidebarObservable = () => {
  const update = (payload) => {
    setNextState({ activeMenu: payload, error: '' });
  };

  const onOpenSidebar = () => {
    setNextState({ isOpen: true });
  };

  const onCloseSidebar = () => {
    setNextState({ isOpen: false });
  };

  const updating = (flag) => {
    setNextState({ updating: flag });
  };

  const remove = (id) => {
    const customers = [...sidebarSubject.getValue().customers].filter(
      (customer) => customer.id !== id
    );
    setNextState({ customers, error: '' });
  };

  const removing = (flag) => {
    setNextState({ removing: flag });
  };

  const error = (message) => {
    setNextState({ error: message });
  };

  const setNextState = (payload) => {
    const state = sidebarSubject.getValue();
    sidebarSubject.next({ ...state, ...payload });
  };

  const getObservable = () => {
    return sidebarSubject;
  };

  return {
    update,
    updating,
    remove,
    removing,
    error,
    getObservable,
    onOpenSidebar,
    onCloseSidebar,
  };
};
