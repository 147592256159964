import { Search2Icon } from '@chakra-ui/icons';
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import React from 'react';
import { BsFillFilterSquareFill } from 'react-icons/bs';

const SearchInput = (props) => {
  return (
    <InputGroup>
      <InputLeftElement
        pointerEvents="none"
        fontSize="xs"
        children={<Search2Icon color="dolphin" />}
      />
      <Input
        color={'white'}
        type="text"
        placeholder="search"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
      />
      <InputRightElement fontSize="xs">
        <IconButton
          icon={<BsFillFilterSquareFill />}
          bg="charageDark2"
          color="silverChalice"
          variant="solid"
          width={'40px'}
          height={'40px'}
          borderRadius={'5px'}
          onClick={props.filterChange}
        />
      </InputRightElement>
    </InputGroup>
  );
};

export default SearchInput;
