import { debounce, get, isEmpty } from 'lodash';
import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import { useEditorRepository } from 'repositories';
import { timestamp } from 'utils/unixtimeStamp';

export const useAddToCompositionHook = () => {
  // repositories
  const editorRepository = useEditorRepository();

  /**
   * Add Unsplash photo to editor
   * @param {object} data; i.e unsplace photo data object
   * @param {string} photoId
   */
  const handleAddUnsplashPhotoToEditor = React.useCallback(
    (data, photoId) => {
      const droptype = 'images';
      const photoData = get(data, `entities.photos.${photoId}`, null);
      const srcUrl = get(photoData, 'urls.regular', '');
      const name = get(photoData, 'description', 'no name');
      const nextId = nanoid(10);
      const itemWithTimeStamp = {
        uniqueId: nextId,
        id: nextId,
        photoId,
        droptype: droptype,
        src: srcUrl,
        name,
        s3Url: srcUrl,
        s3Key: srcUrl,
        start: 0,
        end: 10000,
        frameStartAt: 0,
        frameEndAt: 10000,
        locked: false,
        visible: true,
        mute: false,
        timestamp: timestamp(),
        isAudio: false,
        isVideo: false,
        isUnSplash: true,
      };

      if (droptype) {
        /** Droptype: Is same as reducer name always */
        editorRepository.add(itemWithTimeStamp);
      }
    },
    [editorRepository]
  );

  /**
   * Add AI image to editor
   * @param {object} data; i.e AI image data object
   * @param {string} uniqueId
   */
  const handleOnAiImageCreateToEditor = React.useCallback(
    (data, uniqueId) => {
      /**
         *  "data": {
        "s3Url": "https://eyecastvideoeditorfiles.s3.ap-southeast-2.amazonaws.com/public/ai/images/xdjdyiodxuds/img-IxskxvhBshROXzvR2a73KJes.png",
        "s3Key": "public/ai/images/xdjdyiodxuds/img-IxskxvhBshROXzvR2a73KJes.png",
        "name": "img-IxskxvhBshROXzvR2a73KJes.png",
    }
         */
      const droptype = 'images';
      const itemWithTimeStamp = {
        uniqueId: uniqueId,
        id: uniqueId,
        droptype: droptype,
        src: data.s3Url,
        name: data.name,
        s3Url: data.s3Url,
        s3Key: data.s3Key,
        start: 0,
        end: 10000,
        frameStartAt: 0,
        frameEndAt: 10000,
        locked: false,
        visible: true,
        mute: false,
        timestamp: timestamp(),
        isAudio: false,
        isVideo: false,
        isUnSplash: true,
      };
      console.log(
        'itemWithTimeStamp handle add ai generate image',
        itemWithTimeStamp
      );
      if (droptype) {
        /** Droptype: Is same as reducer name always */
        editorRepository.add(itemWithTimeStamp);
      }
    },
    [editorRepository]
  );

  return {
    handleAddUnsplashPhotoToEditor,
    handleOnAiImageCreateToEditor,
  };
};
