import { AddIcon } from '@chakra-ui/icons';
import { Box, Flex, Image, Spinner } from '@chakra-ui/react';
import { useWebFontLoader } from 'hooks/useWebFontLoader';
import React from 'react';

export const TitleItem = ({
  src,
  id,
  fonts = [],
  filter = false,
  onClick = (f) => f,
}) => {
  const [isLoadingFont, setIsLoadingFont] = React.useState(false);
  const { loadWebFontCallback } = useWebFontLoader();

  const handleOnFontLoadCallback = React.useCallback(() => {
    onClick(id);
    setIsLoadingFont(false);
  }, [onClick, id]);

  const handleOnClick = React.useCallback(() => {
    setIsLoadingFont(true);
    loadWebFontCallback(fonts, handleOnFontLoadCallback);
  }, [fonts, loadWebFontCallback, handleOnFontLoadCallback]);

  return (
    <Flex
      position={`relative`}
      cursor="pointer"
      backgroundColor="charadeDark3"
      _hover={{
        '& > .overlay': {
          transform: 'scale(1)',
        },
      }}
      onClick={handleOnClick}
    >
      <Image
        width={'100%'}
        borderRadius="5px"
        height="100%"
        objectFit={'cover'}
        src={src}
        alt="titles"
        {...(filter && { filter: filter })}
        crossOrigin="anonymous"
      />
      <Box
        as={'span'}
        className="overlay"
        data-testid="overlay"
        position={'absolute'}
        width="100%"
        height="100%"
        background={'rgba(57, 57, 57, 0.502)'}
        top={0}
        left={0}
        transform={`scale(0)`}
        transition={`all 0.2s 0.1s ease-in-out`}
        color={'#fff'}
        alignItems={'center'}
        justifyContent={'center'}
        display={'flex'}
      >
        {isLoadingFont && (
          <Spinner size={'md'} margin="auto" colorScheme={'whiteAlpha'} />
        )}
        {!isLoadingFont && (
          <AddIcon
            fontSize={20}
            margin={'auto'}
            position={'relative'}
            transitionDelay={'0s'}
            display={'inline-block'}
            color={'#ffffff'}
          />
        )}
      </Box>
    </Flex>
  );
};
