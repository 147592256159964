import { ChakraProvider } from '@chakra-ui/react';
import {
  ApiContextProvider,
  EditorApiContextProvider,
} from 'providers/api-provider';
import { GsapPixieContextProvider } from 'reacthub-pixitweenjs';
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import theme from 'theme';
import AuthProvider from './auth-provider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const Providers = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <GsapPixieContextProvider>
      <ChakraProvider theme={theme}>
        <AuthProvider>
          <ApiContextProvider>
            <EditorApiContextProvider>{children}</EditorApiContextProvider>
          </ApiContextProvider>
        </AuthProvider>
      </ChakraProvider>
      <ToastContainer />
    </GsapPixieContextProvider>
  </QueryClientProvider>
);

export default Providers;
