import { useCanvasZoomObservable } from 'observeables';

export const useCanvasZoomRepository = () => {
  const canvasZoomObservable = useCanvasZoomObservable();

  const list = async () => {
    try {
      canvasZoomObservable.list();
    } catch (error) {
      canvasZoomObservable.error('Error in updating edit area dimensons');
    } finally {
      canvasZoomObservable.listing(false);
    }
  };

  const update = async (payload) => {
    try {
      canvasZoomObservable.updating(true);
      canvasZoomObservable.update(payload);
    } catch (error) {
      canvasZoomObservable.error('Error in updating edit area dimensons');
    } finally {
      canvasZoomObservable.updating(false);
    }
  };

  const getCanvasZoomObservable = () => canvasZoomObservable.getObservable();

  return {
    list,
    update,
    getCanvasZoomObservable,
  };
};
