import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import { Center, VStack } from '@chakra-ui/react';
import PublicRoute from './public-route';
import spinnerAnimationData from '../../assets/images/clippy-spinner.json';
import { LoadingSpinner } from 'components';

export default withAuthenticationRequired(PublicRoute, {
  onRedirecting: () => (
    <VStack
      spacing={4}
      flex={1}
      bgColor={'shark'}
      height={'90vh'}
      align={'center'}
      justifyContent={'center'}
    >
      <Center w="100px" h="100px">
        <LoadingSpinner
          animationData={spinnerAnimationData}
          width={100}
          height={100}
        />
      </Center>
    </VStack>
  ),
});
