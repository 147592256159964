import React from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Portal,
  FormLabel,
  ButtonGroup,
  Button as ChakraButton,
  Stack,
  SimpleGrid,
  Icon,
  Image,
  Box,
  Center,
  Flex,
  CircularProgress,
  CircularProgressLabel,
  useDisclosure,
} from '@chakra-ui/react';
import { TfiExport } from 'react-icons/tfi';
import { Button, FormControl, Input, ReactSelect } from 'components';
import { useForm } from 'react-hook-form';
import { AccordionView } from 'components/accordion';

const qualityOptions = [
  { value: 'Recommended', label: 'Recommended' },
  { value: 'High', label: 'High' },
];

const resolutionOptions = [
  { value: '720p', label: '720p' },
  { value: '1080p', label: '1080p' },
  { value: '1920p', label: '1920p' },
];

const frameRateOptions = [
  { value: '20', label: '20' },
  { value: '30', label: '30' },
  { value: '60', label: '60' },
];

const formatOptions = [
  { value: 'mp4', label: 'mp4' },
  { value: 'mov', label: 'mov' },
];

const Form = ({ name, onExport, onClose }) => {
  const { control } = useForm({
    defaultValues: {
      filename: name,
      resolution: '720p',
      quality: 'High',
      frameRate: '30',
      format: 'mp4',
    },
  });

  const onExportClick = () => {
    onExport();
    onClose();
  };

  const customStyles = {
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      color: 'white',
      background: 'transparent',
    }),
    input: (baseStyles, state) => ({
      ...baseStyles,
      color: 'white',
      background: 'transparent',
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      background: 'transparent',
      border: '1px solid #2D3748',
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      borderBottom: '1px solid #3a3749',
      background: state.isFocused || state.isSelected ? '#45444a' : '',
      color: 'white',
      zIndex: 100,
    }),
    menu: (baseStyles, state) => ({
      ...baseStyles,
      background: '#45444a',
    }),
  };
  return (
    <SimpleGrid columns={1} gap={3}>
      <FormControl label={'Filename'} id="filename">
        <Input
          name="filename"
          placeholder="Enter your company name"
          control={control}
          color="white"
        />
      </FormControl>
      <FormControl label={'Resolution'} id="resolution">
        <ReactSelect
          name="resolution"
          control={control}
          options={resolutionOptions}
          styles={customStyles}
        />
      </FormControl>
      <AccordionView
        header="Advance settings"
        id="advancesettings"
        defaultIndex={-1}
      >
        <FormControl label={'Quality'} id="quality">
          <ReactSelect
            name="quality"
            control={control}
            options={qualityOptions}
            styles={customStyles}
          />
        </FormControl>
        <FormControl label={'Frame Rate'} id="framerate">
          <ReactSelect
            name="frameRate"
            control={control}
            options={frameRateOptions}
            styles={customStyles}
          />
        </FormControl>
        <FormControl label={'Format'} id="exportformat">
          <ReactSelect
            name="format"
            control={control}
            options={formatOptions}
            styles={customStyles}
          />
        </FormControl>
      </AccordionView>
      <ButtonGroup
        isAttached
        variant="outline"
        spacing={1}
        bg="woodsmoke2"
        size="sm"
        borderRadius={5}
        justifyContent="space-between"
      >
        <Button
          variant="solid"
          bgColor="cornflowerBlue"
          w={'99px'}
          h={'36px'}
          fontWeight="500"
          color="woodsmoke"
          label="Export"
          fontSize="xs"
          _hover={{
            bg: 'gunpowder',
          }}
          flex={1}
          onClick={onExportClick}
        />
      </ButtonGroup>
    </SimpleGrid>
  );
};

const ExportButton = ({
  name = '',
  isVideoInProgress = false,
  videoExportStatusInProgress = false,
  progress = 1,
  onExport,
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Popover placement="bottom">
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <ChakraButton
              variant="solid"
              bgColor="cornflowerBlue"
              w={'99px'}
              h={'36px'}
              fontSize="md"
              fontWeight="500"
              color="woodsmoke"
              _hover={{ background: 'tuna' }}
              leftIcon={
                <Icon
                  as={TfiExport}
                  w={4}
                  h={4}
                  fill={'#17161D'}
                  color={'#17161D'}
                />
              }
            >
              {(isVideoInProgress || videoExportStatusInProgress) && (
                <CircularProgress
                  value={progress || 1}
                  size={'35px'}
                  color="woodsmoke"
                  colorScheme="teal"
                  isIndeterminate={true}
                >
                  <CircularProgressLabel fontSize={'10px'}>{`${
                    progress || 1
                  }%`}</CircularProgressLabel>
                </CircularProgress>
              )}
              {!isVideoInProgress && 'Export'}
            </ChakraButton>
          </PopoverTrigger>
          <PopoverContent
            backgroundColor="woodsmoke2"
            borderColor={'pearl'}
            maxH="80vh"
            overflowY="auto"
          >
            <PopoverHeader fontWeight="semibold">
              <Flex flex={1} justifyContent="center">
                <Image
                  objectFit="contain"
                  src="https://eyecastvideoeditorfiles.s3.ap-southeast-2.amazonaws.com/assets/placeholders/placeholderimage.png"
                  alt="clippy"
                />
              </Flex>
            </PopoverHeader>
            <PopoverArrow backgroundColor="#3a3749" />
            <PopoverCloseButton />
            <PopoverBody height={'600px'}>
              <Form name={name} onExport={onExport} onClose={onClose} />
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default ExportButton;
