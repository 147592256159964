import { AddIcon, SmallAddIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { Box, Flex } from '@chakra-ui/react';

export const Hover = ({
  children,
  isEnabled = true,
  type,
  addClick = () => {},
  deleteClick = () => {},
  onClick = (f) => f,
}) => {
  return (
    <Box
      position={'relative'}
      overflow={'hidden'}
      width={'100%'}
      display={'flex'}
      justifyContent={'center'}
      _disabled={!isEnabled}
      _hover={{
        '*': { cursor: 'pointer' },
        '&>div::before': {
          bg: 'rgba(255, 255, 255, 0)',
          opacity: 1,
          left: '10%',
          right: '10%',
          transitionDelay: '0s',
        },
        '&>div': { opacity: 0.35 },
        '&>div>svg': {
          opacity: 0.9,
          transition: '0.3s ease-in-out',
          transitionDelay: '0.3s',
        },
        '&>div>div': {
          opacity: 0.9,
          transition: '0.3s ease-in-out',
          transitionDelay: '0.3s',
        },
        '&>div>div>svg': {
          opacity: 0.9,
          transition: '0.3s ease-in-out',
          transitionDelay: '0.3s',
        },
      }}
    >
      <Box maxWidth={'100%'} opacity={1} transition="opacity 0.35s">
        {children}
      </Box>
      <>
        {isEnabled && (
          <Box
            position={'absolute'}
            top={0}
            right={0}
            left={0}
            bottom={0}
            onClick={onClick}
            height={'100%'}
            width={'100%'}
            display={'flex'}
            flex={1}
            _before={{
              content: '""',
              position: 'absolute',
              top: '5%',
              right: '50%',
              bottom: '5%',
              left: '50%',
              borderLeft: '1px solid rgba(255, 255, 255, 0.8)',
              borderRight: '1px solid rgba(255, 255, 255, 0.8)',
              opacity: 0,
              bg: '#ffffff',
              transition: 'all 0.4s',
              transitionDelay: '0.3s',
            }}
          >
            {type !== 'media' && (
              <AddIcon
                fontSize={20}
                opacity={0}
                margin={'auto'}
                position={'relative'}
                transitionDelay={'0s'}
                display={'inline-block'}
                color={'#ffffff'}
              />
            )}
            {type === 'media' && (
              <>
                <Flex
                  position={'absolute'}
                  direction={'row'}
                  justifyContent={'space-around'}
                  alignItems={'center'}
                  gap={4}
                  flex={1}
                  width="100%"
                  height="100%"
                >
                  <SmallAddIcon
                    data-testid="small-add-icon"
                    fontSize={35}
                    opacity={0}
                    position={'relative'}
                    transitionDelay={'0s'}
                    display={'flex'}
                    color={'#ffffff'}
                    onClick={addClick}
                    xlinkTitle="Add"
                  />
                  <SmallCloseIcon
                    data-testid="small-close-icon"
                    fontSize={35}
                    opacity={0}
                    position={'relative'}
                    transitionDelay={'0s'}
                    display={'flex'}
                    color={'#ffffff'}
                    onClick={deleteClick}
                    xlinkTitle="Remove"
                  />
                </Flex>
              </>
            )}
          </Box>
        )}
      </>
    </Box>
  );
};

export default Hover;
