import { Box } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from 'components/base';
import React from 'react';

const defaultStyles = {
  width: '4%',
  height: '100%',
  content: "''",
  position: 'absolute',
  top: 0,
  opacity: 0,
  transition: 'opacity 0.6s',
  pointerEvents: 'none',
  zIndex: 1,
};

const SliderControls = ({ showPrev, showNext, onClick }) => {
  return (
    <Box
      height={'100%'}
      width={'100%'}
      position={'absolute'}
      top={0}
      _before={{
        left: 0,
        background: 'linear-gradient(90deg, #000 0, hsla(0, 0%, 100%, 0))',
        ...defaultStyles,
        opacity: showPrev ? 1 : 0,
      }}
      _after={{
        right: 0,
        background: 'linear-gradient(270deg, #000 0, hsla(0, 0%, 100%, 0))',
        ...defaultStyles,
        opacity: showNext ? 1 : 0,
      }}
    >
      <SliderControlsArrow
        icon={'angle-left'}
        left={'-10px'}
        onClick={() => onClick(-1)}
        isActive={showPrev}
      />
      <SliderControlsArrow
        icon={'angle-right'}
        right={'-10px'}
        onClick={() => onClick(1)}
        isActive={showNext}
      />
    </Box>
  );
};

const SliderControlsArrow = ({ onClick, icon, isActive, ...rest }) => (
  <IconButton
    onClick={onClick}
    colorScheme={'blue'}
    size="md"
    position={'absolute'}
    top={'50%'}
    zIndex={2}
    isRound
    opacity={isActive ? 1 : 0}
    sx={{
      transform: 'translateY(calc(-50%))',
    }}
    icon={<FontAwesomeIcon icon={['fas', icon]} size="lg" />}
    {...rest}
  />
);

export default SliderControls;
