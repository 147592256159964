import { usePlayerObservable } from 'observeables';

export const usePlayerRepository = () => {
  const playerObservable = usePlayerObservable();

  const update = async (payload) => {
    try {
      playerObservable.update(payload);
    } catch (error) {
      playerObservable.error('Error in updating edit area dimensons');
    } finally {
    }
  };

  const getObservable = () => playerObservable.getObservable();

  return {
    update,
    getObservable,
  };
};
