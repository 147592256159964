import { Box, Button, Grid, Text } from '@chakra-ui/react';
import { map, take } from 'lodash';
import { FiMoreHorizontal } from 'react-icons/fi';
import React from 'react';
import StipopElement from './StipopElement';
import { useSidebarRepository } from 'repositories';

export const StipopGrid = ({ onClick, data = [], allButton = true }) => {
  const sidebarMenuRepo = useSidebarRepository();

  return (
    <Grid
      templateColumns="repeat(auto-fit, minmax(90px, 1fr))"
      gap={3}
      data-testid="stipop-grid"
      justifyContent="center"
      alignItems="center"
    >
      {map(take(data, 5), (value) => {
        const ext = value.stickerImg.substr(
          value.stickerImg.lastIndexOf('.') + 1
        );
        const isGif = ext && ext.includes('gif');
        const stickerData = {
          id: value.id,
          type: isGif ? 'gifs' : 'images',
          name: value.id,
          droptype: isGif ? 'gifs' : 'images',
          src: value.stickerImg,
          s3Key: value.stickerImg,
          s3Url: value.stickerImg,
        };

        return (
          <Box
            key={value.stickerId}
            w="90px"
            h="50px"
            bg="charadeDark3"
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius={5}
            color="azure"
            data-testid="sticker-grid-box"
            onClick={() => onClick(stickerData)}
          >
            <StipopElement
              stickerImg={value.stickerImg}
              id={value.stickerId}
              width={50}
            />
          </Box>
        );
      })}
      {allButton && (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Button
            w="90px"
            h="50px"
            bg="charadeDark3"
            color="azure"
            fontSize="sm"
            borderRadius={5}
            onClick={() => sidebarMenuRepo.update('Elements/AllStickers')}
            data-testid="view-all-button"
          >
            <FiMoreHorizontal color="white" />
          </Button>
          <Text fontSize="xx-small" align="center" color="pearl" mt={1}>
            View All
          </Text>
        </Box>
      )}
    </Grid>
  );
};
