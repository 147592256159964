import { Icon } from '@chakra-ui/react';
import React from 'react';

export const Audioonly = (props) => (
  <Icon viewBox="0 0 12 18" {...props}>
    <path
      d="M11.4545 6.75H10.9091C10.6077 6.75 10.3636 7.00172 10.3636 7.3125V9C10.3636 11.6297 8.16511 13.7398 5.56398 13.4782C3.29693 13.25 1.63636 11.1484 1.63636 8.79961V7.3125C1.63636 7.00172 1.39227 6.75 1.09091 6.75H0.545455C0.244091 6.75 0 7.00172 0 7.3125V8.72437C0 11.8758 2.1808 14.6851 5.18182 15.1119V16.3125H3.27273C2.97136 16.3125 2.72727 16.5642 2.72727 16.875V17.4375C2.72727 17.7483 2.97136 18 3.27273 18H8.72727C9.02864 18 9.27273 17.7483 9.27273 17.4375V16.875C9.27273 16.5642 9.02864 16.3125 8.72727 16.3125H6.81818V15.1253C9.74011 14.7118 12 12.1254 12 9V7.3125C12 7.00172 11.7559 6.75 11.4545 6.75ZM6 12.375C7.8075 12.375 9.27273 10.864 9.27273 9H6.36375C6.16295 9 6 8.87414 6 8.71875V8.15625C6 8.00086 6.16295 7.875 6.36375 7.875H9.27273V6.75H6.36375C6.16295 6.75 6 6.62414 6 6.46875V5.90625C6 5.75086 6.16295 5.625 6.36375 5.625H9.27273V4.5H6.36375C6.16295 4.5 6 4.37414 6 4.21875V3.65625C6 3.50086 6.16295 3.375 6.36375 3.375H9.27273C9.27273 1.51102 7.8075 0 6 0C4.1925 0 2.72727 1.51102 2.72727 3.375V9C2.72727 10.864 4.1925 12.375 6 12.375Z"
      fill="#E74C3C"
    />
  </Icon>
);
