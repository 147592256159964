import { BehaviorSubject } from 'rxjs';

const projectExportSubject = new BehaviorSubject({
  isLoading: false,
  isLoaded: false,
  error: false,
  data: [],
});

export const useProjectExportObserveable = () => {
  const update = (payload) => {
    setNextState({ ...payload, error: '' });
  };

  const setNextState = (payload) => {
    const state = projectExportSubject.getValue();
    projectExportSubject.next({ ...state, ...payload });
  };

  const getObservable = () => {
    return projectExportSubject;
  };

  return {
    update,
    getObservable,
  };
};
