import { Box, Button, Flex, IconButton, Text } from '@chakra-ui/react';
import { FiMoreHorizontal } from 'react-icons/fi';
import { chunk, get, isEmpty, map } from 'lodash';
import React from 'react';
import { TitleItem } from './TitleItem';
import useScreenSize from 'hooks/useScreenSize';

export const TitlesGallery = ({ data = [], onClick, filter = false }) => {
  const { isLgDown } = useScreenSize();

  const photoGalleryData = React.useMemo(() => {
    return map(data, (d) => d);
  }, [data]);

  const chunkedData = React.useMemo(() => {
    if (isEmpty(photoGalleryData)) return [];
    if (isLgDown) return chunk(photoGalleryData, 3);
    return chunk(photoGalleryData, 2);
  }, [photoGalleryData, isLgDown]);

  return (
    <Flex data-tesid="container">
      <Flex
        wrap="wrap"
        gap={2}
        data-testid="titles-grid"
        justifyContent="start"
      >
        {!isEmpty(chunkedData) &&
          map(chunkedData, (eachChunk, idx) => {
            return (
              <Flex
                key={`photoImage${idx}idx`}
                flexDir={'row'}
                gap={2}
                data-testid="column"
                alignItems="center"
                justifyContent="center"
              >
                {eachChunk &&
                  map(eachChunk, (each) => {
                    return (
                      <TitleItem
                        key={each.id}
                        id={each.id}
                        src={get(
                          each,
                          'image',
                          'https://source.unsplash.com/e6FMMambeO4'
                        )}
                        filter={filter}
                        onClick={onClick}
                        fonts={each.fonts}
                      />
                    );
                  })}
              </Flex>
            );
          })}
      </Flex>
    </Flex>
  );
};

/**
 * 
 *  <Flex flexDir={'row'} gap={'8px'} data-testid="column">
          <ImageItem src="https://source.unsplash.com/e6FMMambeO4" />
          <ImageItem src="https://source.unsplash.com/O0N9MF--hK4" />
        </Flex>
        <Flex flexDir={'row'} gap={'8px'}>
          <ImageItem src="https://source.unsplash.com/FV3GConVSss" />
          <ImageItem src="https://source.unsplash.com/0ESjL-Nw22Y" />
        </Flex>
        <Flex flexDir={'row'} gap={'8px'}>
          <ImageItem src="https://source.unsplash.com/KTVn62x6fFw" />
          <ImageItem src="https://source.unsplash.com/07aFaTf24Kg" />
        </Flex>

        <Flex flexDir={'row'} gap={'8px'}>
          <ImageItem src="https://source.unsplash.com/IdNOTjPeHrE" />
          <ImageItem src="https://source.unsplash.com/FV3GConVSss" />
        </Flex>

        <Flex flexDir={'row'} gap={'8px'}>
          <ImageItem src="https://source.unsplash.com/IdNOTjPeHrE" />
          <ImageItem src="https://source.unsplash.com/FV3GConVSss" />
        </Flex>

 */
