import React from 'react';
import { useLocation } from 'react-router-dom';

export const DashboardReroute = () => {
  const location = useLocation();
  const { pathname } = location;
  React.useLayoutEffect(() => {
    window.location.replace(`https://www.clippy.io${pathname}`);
  }, []);

  return (
    <div>
      <span />
    </div>
  );
};
