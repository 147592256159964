import { Hover } from '../../hover';

const EmojiRender = ({ emojiCode, ...props }) => {
  // console.log(emojiCode, 'emojiCode');
  return (
    <Hover>
      <img
        width="40px"
        height="40px"
        src={`https://cdn.jsdelivr.net/npm/@svgmoji/openmoji@2.0.0/svg/${emojiCode}.svg`}
        alt=""
      />
    </Hover>
  );
};

export default EmojiRender;
