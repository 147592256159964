import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Link,
  Text,
  Skeleton,
  useMediaQuery,
  useColorModeValue,
} from '@chakra-ui/react';

const useScreenSize = () => {
  // Up (min-width)
  const [isXsUp] = useMediaQuery('(min-width: 0px)');
  const [isSmUp] = useMediaQuery('(min-width: 576px)');
  const [isMdUp] = useMediaQuery('(min-width: 992px)');
  const [isLgUp] = useMediaQuery('(min-width: 1024px)');
  const [isXlUp] = useMediaQuery('(min-width: 1200px)');
  const [isXxlUp] = useMediaQuery('(min-width: 1400px)');

  // Down (max-width)
  const [isXsDown] = useMediaQuery('(max-width: 0px)');
  const [isSmDown] = useMediaQuery('(max-width: 576px)');
  const [isMdDown] = useMediaQuery('(max-width: 992px)');
  const [isLgDown] = useMediaQuery('(max-width: 1024px)');
  const [isXlDown] = useMediaQuery('(max-width: 1440px)');
  const [isXxlDown] = useMediaQuery('(max-width: 2400px)');

  const fontSize = React.useMemo(() => {
    if (isXsUp && isSmDown) {
      return 'xs';
    } else if (isSmUp && isMdDown) {
      return 'sm';
    } else if (isMdUp && isLgDown) {
      return 'md';
    } else if (isLgUp) {
      return 'lg';
    }
  }, [isXsUp, isSmDown, isSmUp, isMdDown, isMdUp, isLgDown, isLgUp]);

  const headingFontSize = React.useMemo(() => {
    if (isXsUp && isSmDown) {
      return 'md';
    } else if (isSmUp && isMdDown) {
      return 'lg';
    } else if (isMdUp && isLgDown) {
      return 'xl';
    } else if (isLgUp) {
      return 'xl';
    }
  }, [isXsUp, isSmDown, isSmUp, isMdDown, isMdUp, isLgDown, isLgUp]);

  return {
    fontSize,
    headingFontSize,
    isXsUp,
    isSmUp,
    isMdUp,
    isLgUp,
    isXlUp,
    isXxlUp,
    isXsDown,
    isSmDown,
    isMdDown,
    isLgDown,
    isXlDown,
    isXxlDown,
  };
};

export default useScreenSize;
