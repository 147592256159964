import { useCallback, useLayoutEffect, useRef } from 'react';

function useEventCallback(fn) {
  let ref = useRef();

  useLayoutEffect(() => {
    ref.current = fn;
  });

  return useCallback(() => (0, ref.current)(), []);
}

export default useEventCallback;
