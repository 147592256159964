import React, { useState } from 'react';
import { useCustomEventListener } from 'react-custom-events';
import { PanelResizer } from '../constants';

const useRefDimensions = (ref) => {
  const [dimensions, setDimensions] = useState({ width: 1, height: 2 });

  const getDimensions = () => {
    const newWidth = ref.current.clientWidth;
    const newHeight = ref.current.clientHeight;
    const scale = 0;

    setDimensions({
      width: Math.round(newWidth) - scale - 10,
      height: Math.round(newHeight) - 15,
    });
  };

  /** Event Listeneres */
  useCustomEventListener(PanelResizer.PANEL_RESIZE, (time) => {
    getDimensions();
  });

  // React.useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     getDimensions();
  //   }, 500);
  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, []);

  // React.useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     getDimensions();
  //   }, 800);
  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, []);

  // React.useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     getDimensions();
  //   }, 1000);
  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, []);

  React.useEffect(() => {
    if (ref.current) {
      getDimensions();
    }
  }, [ref]);

  React.useEffect(() => {
    window.addEventListener('resize', getDimensions);
    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener('resize', getDimensions);
  }, []);

  return dimensions;
};

export default useRefDimensions;
