import { Box, Grid, GridItem } from '@chakra-ui/react';
import { TimelineScrubber } from 'components';
// import useDebounce from 'hooks/useDebounce';
// import useDeepEffect from 'hooks/useDeepEffect';
import useRefDimensions from 'hooks/useRefDimensions';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import inRange from 'lodash/inRange';
import { nanoid } from 'nanoid';
import React from 'react';
import { useDrop } from 'react-dnd';
import { ItemTypes } from '../../../constants/ItemTypes';
import { timestamp } from '../../../utils/unixtimeStamp';
import { useScrubberData } from 'hooks/useScrubberData';
import {
  useSelectedRepository,
  useSidebarRepository,
  useEditorRepository,
  useScrubberBarRepository,
  usePlayerRepository,
} from 'repositories';
import { useObservableWithPipe } from 'utils/useObserveable';

const ScrubberLayout = () => {
  const scrubberDataRef = React.useRef([]);
  const { sortedScrubberData, totalVideoSeconds, minMaxRowIndex } =
    useScrubberData();

  const editorRepo = useEditorRepository();
  const selectedRepo = useSelectedRepository();
  const sidebarMenuRepo = useSidebarRepository();
  const scrubberBarRepo = useScrubberBarRepository();
  const playerRepo = usePlayerRepository();

  /** state */
  const selectedKeyFrame = useObservableWithPipe(
    selectedRepo.getSelectedObservable()
  );

  const scrubberBarMeta = useObservableWithPipe(
    scrubberBarRepo.getScrubberBarObservable()
  );
  // const playerMeta = useObservableWithPipe(playerRepo.getObservable());

  // ref hold data
  React.useEffect(() => {
    scrubberDataRef.current = sortedScrubberData;
  }, [sortedScrubberData]);

  /** EOF states */

  const videoScrubberRef = React.useRef(null);
  const dimensions = useRefDimensions(videoScrubberRef);

  const setActiveMenu = (menuName) => {
    // console.log('SIDE BAR', menuName);
    sidebarMenuRepo.update(menuName);
  };

  const handleOnScrubberBarChange = React.useCallback((newPosition) => {
    // setScrubberBarValue(newPosition);
    // // console.log('newposition', newPosition);
  }, []);

  const handleOnBeforeChange = React.useCallback(
    (newPosition) => {
      let val = newPosition;
      if (val > totalVideoSeconds) val = totalVideoSeconds;
      if (val < 0) val = 0;
      playerRepo.update({ playedSeconds: val, play: false });
      scrubberBarRepo.update({ value: val });
    },
    [playerRepo, scrubberBarRepo, totalVideoSeconds]
  );

  const handleOnAfterChange = React.useCallback(
    (newPosition) => {
      let val = newPosition;
      if (val > totalVideoSeconds) val = totalVideoSeconds;
      if (val < 0) val = 0;
      // const actualSeconds = val / 100;
      playerRepo.update({ playedSeconds: val, play: false });
      // dispatch(
      //   AppActions.scrubberBarActions.scrubber.updateStatus({
      //     value: val,
      //   })
      // );
    },
    [playerRepo, totalVideoSeconds]
  );

  const updateVideoPlayerState = (data) => {
    playerRepo.update(data);
  };

  /** Todo:
   *  Update all scrubber transformations using Sagas
   *  remove direct updates to reducer
   */
  const handleOnScrubberUpdate = (payload, resize = false) => {
    const {
      action: { id },
      start,
      end,
      dropRowId,
    } = payload;

    const updatedRowId = dropRowId; // add +1 to count from 0 based index
    const startMs = start * 1000;
    const endMs = end * 1000;
    const layerValue = scrubberDataRef.current.find((d) => {
      return d.uniqueId === id;
    });
    if (isEmpty(layerValue)) return null;

    const {
      start: selectStart,
      end: selectEnd,
      locked,
      scrubberRow,
    } = layerValue;

    const updatedRowPayload = {
      ...layerValue,
    };
    let rowChanged = false;
    // check if the scrubber row is changed
    if (!isNaN(updatedRowId) && updatedRowId !== scrubberRow) {
      rowChanged = true;
      updatedRowPayload.scrubberRow = updatedRowId;
    }

    if (locked) return null;
    if (!resize && !rowChanged && startMs === selectStart) return;
    if (!resize && !rowChanged && endMs === selectEnd) return;

    // pefrom overlapping check here and update the scrubber row or avoid row update if overlapping exists
    const checkIfOverlappingExists = scrubberDataRef.current.some((f) => {
      const { start, end } = f;

      const isRangeOverlappingStartTime =
        f.scrubberRow === updatedRowPayload.scrubberRow &&
        f.uniqueId !== id &&
        inRange(startMs, start, end);
      const isRangeOverlappingEndTime =
        f.scrubberRow === updatedRowPayload.scrubberRow &&
        f.uniqueId !== id &&
        inRange(endMs, start, end);
      return isRangeOverlappingStartTime || isRangeOverlappingEndTime;
    });

    // do no update if overlapping exists
    if (checkIfOverlappingExists) return;
    const updatedPayload = { ...updatedRowPayload, start: startMs, end: endMs };
    editorRepo.updateActionItemsTimes(updatedPayload);
  };

  const handleOnSelectedKeyFrame = (id) => {
    const selectedData = scrubberDataRef.current.find((d) => {
      return d.uniqueId === id;
    });
    const { droptype, locked } = selectedData;
    if (isEmpty(selectedData)) return null;
    if (locked) return null;
    if (droptype === 'transitions') {
      sidebarMenuRepo.update('Transition');
    } else if (droptype === 'xaudios') {
      sidebarMenuRepo.update('Audio-Editing');
    } else {
      sidebarMenuRepo.update('Edit');
    }
    selectedRepo.select({ id, uniqueId: id, droptype });
  };

  const handleOnDeselectKeyFrame = () => {
    selectedRepo.unSelect();
  };

  const handleOnLayerLock = (payload) => {
    editorRepo.updateLocked(payload);
    handleOnDeselectKeyFrame();
  };

  const handleOnLayerVisible = (payload) => {
    editorRepo.updateVisible(payload);
  };

  const handleOnLayerMute = (payload) => {
    editorRepo.updateMute(payload);
  };

  const handleOnDeleteAction = (uniqueId) => {
    // const d = selectedRepo.getSelectedObservable();
    handleOnDeselectKeyFrame();
    editorRepo.remove(uniqueId);
  };

  const handleOnScrubberRowUpdate = (payload) => {
    const { uniqueId, scrubberRow, start, end } = payload;
    // const startMs = start * 1000;
    // const endMs = end * 1000;
    const layerValue = scrubberDataRef.current.find((d) => {
      return d.uniqueId === uniqueId;
    });
    if (!isEmpty(layerValue)) {
      // check atleast one element visible is false in the existing scrubber row
      const isAnyVisible = scrubberDataRef.current.some((f) => {
        return f.scrubberRow === scrubberRow && f.visible === false;
      });

      // check atleast one element is locked in the existing scrubber row
      const isAnyLocked = scrubberDataRef.current.some((f) => {
        return f.scrubberRow === scrubberRow && f.locked === true;
      });

      // checkt atleast one element is muted in the existing scrubber row
      const isAnyMuted = scrubberDataRef.current.some((f) => {
        return f.scrubberRow === scrubberRow && f.mute === true;
      });

      const updatedPayload = {
        ...layerValue,
        scrubberRow: scrubberRow || layerValue.scrubberRow,
        visible: isAnyVisible ? false : true,
        locked: isAnyLocked ? true : false,
        mute: isAnyMuted ? true : false,
        // start: startMs,
        // end: endMs,
      };

      // perform overlapping check here and update the scrubber row or avoid row update if overlapping exists
      const checkIfOverlappingExists = scrubberDataRef.current.some((f) => {
        const { start, end } = f;
        const currentStart = updatedPayload.start;
        const currentEnd = updatedPayload.end;
        const inRangeWithStartTime =
          f.scrubberRow === scrubberRow &&
          f.uniqueId !== uniqueId &&
          inRange(currentStart, start, end);

        const inRangeWithEndTime =
          f.scrubberRow === scrubberRow &&
          f.uniqueId !== uniqueId &&
          inRange(currentEnd, start, end);
        return inRangeWithStartTime || inRangeWithEndTime;
      });
      if (checkIfOverlappingExists) return;
      editorRepo.updateScrubberRow(updatedPayload);
    }
  };

  return (
    <Grid
      templateRows="repeat(4, 1fr)"
      templateColumns="repeat(1, 1fr)"
      gap={1}
      width={'100%'}
      height={'100%'}
      bg="shark"
      gridAutoFlow="column"
      pt={1}
      ref={videoScrubberRef}
    >
      <GridItem
        colSpan={1}
        rowSpan={4}
        minWidth="500px"
        minHeight={'230px'}
        data-testid="video-scrubberr-grid-item"
        gridAutoFlow="column"
      >
        <Box
          display="flex"
          data-testid="timeline-box-wrapper"
          overflow={'auto'}
          css={{
            '&::-webkit-scrollbar': {
              width: '10px',
              height: '10px',
            },
            '&::-webkit-scrollbar-track': {
              width: '1px',
              height: '1px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'var(--chakra-colors-brand-primary-800)',
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-corner': {
              backgroundColor: 'var(--chakra-colors-brand-primary-800)',
            },
          }}
          {...(dimensions && { ...dimensions })}
        >
          <TimelineScrubber
            dimensions={dimensions}
            scrubberData={sortedScrubberData}
            handleOnSelectedKeyFrame={handleOnSelectedKeyFrame}
            handleOnDeselectKeyFrame={handleOnDeselectKeyFrame}
            handleOnLayerLock={handleOnLayerLock}
            handleOnLayerVisible={handleOnLayerVisible}
            handleOnLayerMute={handleOnLayerMute}
            selectedKeyFrame={selectedKeyFrame}
            handleOnScrubberUpdate={handleOnScrubberUpdate}
            handleOnBeforeChange={handleOnBeforeChange}
            handleOnAfterChange={handleOnAfterChange}
            handleOnDeleteAction={handleOnDeleteAction}
            handleOnScrubberRowUpdate={handleOnScrubberRowUpdate}
            totalVideoSeconds={totalVideoSeconds}
            scrubberBarMeta={scrubberBarMeta}
            updateVideoPlayerState={updateVideoPlayerState}
            {...minMaxRowIndex}
          />
        </Box>
      </GridItem>
    </Grid>
  );
};

export default ScrubberLayout;
