import { Heading, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DropdownNavItem,
  DropdownNavItemContent,
  DropdownNavItemIcon,
} from 'components/dropdown';
import _merge from 'lodash/merge';
import React from 'react';

const initialStyles = {
  nav: {},
  icon: {},
  content: {},
};

const MenuItem = ({
  title,
  icon,
  text,
  onClick = () => null,
  overrideStyles,
  ...rest
}) => {
  const combinedStyles = _merge(initialStyles, overrideStyles);

  return (
    <DropdownNavItem onClick={onClick} {...combinedStyles.nav}>
      {icon && (
        <DropdownNavItemIcon {...combinedStyles.icon}>
          <FontAwesomeIcon icon={['fas', `${icon}`]} size="1x" />
        </DropdownNavItemIcon>
      )}
      <DropdownNavItemContent {...combinedStyles.content}>
        <Heading size="sm" fontWeight={500}>
          {title}
        </Heading>

        {text && (
          <Text size="sm" fontWeight={300}>
            {text}
          </Text>
        )}
      </DropdownNavItemContent>
    </DropdownNavItem>
  );
};

export default MenuItem;
