import { Button as ChakraButton } from '@chakra-ui/react';
import React from 'react';

const Button = ({
  onClick = () => null,
  variant = 'solid',
  label,
  size = 'md',
  isFullWidth = false,
  ...rest
}) => {
  return (
    <ChakraButton onClick={onClick} variant={variant} size={size} {...rest}>
      {label}
    </ChakraButton>
  );
};

export default Button;
