import { Input as ChakraInput } from '@chakra-ui/react';
import React from 'react';

const InputExtended = ({ variant = 'outline', value, onChange, ...rest }) => {
  const inputRef = React.useRef(null);
  const handleClick = (e) => {
    e && e.stopPropagation();
    e && e.preventDefault();
  };

  const handleFocus = (e) => {
    e && e.stopPropagation();
    e && e.preventDefault();
  };

  const handleChange = (e) => {
    console.log('ChangeSize', e);
    onChange(e);
  };

  React.useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 2);
    }
  }, []);

  return (
    <ChakraInput
      ref={inputRef}
      {...rest}
      onChange={handleChange}
      value={value}
      onClick={handleClick}
      onFocus={handleFocus}
      onFocusCapture={handleFocus}
      onBlur={handleFocus}
    />
  );
};

export default InputExtended;
