const Button = {
  baseStyle: {
    border: 'none',
    fontWeight: 400,
    lineHeight: '15px',
    _focus: {
      boxShadow: 'none',
    },
    _active: {
      backgroundColor: 'azure',
      color: 'black',
    },
  },
  sizes: {
    sm: {
      fontSize: '12px',
      lineHeight: '15px',
      fontWeight: 400,
    },
  },
  variants: {
    solid: {
      outline: 'none',
      borderColor: 'var(--chakra-colors-azure)',
      color: 'white',
      _active: {
        backgroundColor: 'azure',
        background: 'azure',
        color: 'white',
      },
      _hover: {
        bg: 'gunpowder',
      },
    },
  },
  // The default size and variant values
  // defaultProps: {
  //   size: 'md',
  //   variant: 'solid',
  // },
};

export default Button;
