import { reducerNames } from '../constants';
import { filter, orderBy, reduce, map, flatMap } from 'lodash';
import React from 'react';

import {
  useEditorRepository,
  useSelectedRepository,
  usePlayerRepository,
} from 'repositories';
import {
  useObservableWithPipe,
  useObservableEditItems,
  useObservableForTitle,
} from 'utils/useObserveable';

export const useScrubberData = () => {
  const editorRepository = useEditorRepository();
  const selectedRepo = useSelectedRepository();
  const playerRepo = usePlayerRepository();
  const editorState = useObservableEditItems(
    editorRepository.getEditorObservable()
  );
  const projectTitle = useObservableForTitle(
    editorRepository.getEditorObservable()
  );

  /** state */
  const selectedKeyFrame = useObservableWithPipe(
    selectedRepo.getSelectedObservable()
  );
  const playerState = useObservableWithPipe(playerRepo.getObservable());
  const totalVideoSeconds = editorRepository.getTotalSeconds();
  const minMaxRowIndex = editorRepository.getScrubberMinMaxRowIndex();

  const { width, height } = React.useMemo(() => {
    return {
      height: Number(editorState.height),
      width: Number(editorState.width),
    };
  }, [editorState.height, editorState.width]);

  // Sorted Files
  // flat map and  sort editor State data and add the clip properties to the items
  const sortedClipItems = React.useMemo(() => {
    return orderBy(
      flatMap(editorState.clips, (clip) => {
        return map(clip.items, (item) => {
          return {
            ...item,
            scrubberRow: Number(clip.scrubberRow),
            mute: clip.mute,
            locked: clip.locked,
            visible: clip.visible,
            clipId: clip.id,
          };
        });
      }),
      ['scrubberRow', 'startSeconds'], // sort by scrubberRow and startSeconds
      ['asc', 'asc']
    );
  }, [editorState.clips]);

  const totalVideos = reduce(
    editorState.clips,
    (count, clip) => {
      return (
        count +
        clip.items.filter((item) => item.droptype === reducerNames.videos)
          .length
      );
    },
    0
  );

  return {
    sortedScrubberData: sortedClipItems,
    clips: editorState.clips,
    totalVideos,
    totalVideoSeconds,
    playerState,
    selectedKeyFrame,
    minMaxRowIndex,
    projectTitle,
    width,
    height,
  };
};
