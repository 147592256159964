import { QuestionOutlineIcon, SettingsIcon } from '@chakra-ui/icons';
import { Box, Center, IconButton } from '@chakra-ui/react';
import React from 'react';
import AsideEditingNav from './aside-editing-nav';

const Aside = ({ routes, isFixed = false, isFullWidth = false }) => {
  return (
    <AsideHolder isFixed={isFixed} isFullWidth={isFullWidth}>
      <AsideContent>
        <AsideTop>
          <AsideTopHolder>
            <AsideTopHolderNav>
              <AsideEditingNav routes={routes} />
            </AsideTopHolderNav>
          </AsideTopHolder>
        </AsideTop>

        <AsideBottom>
          <Center alignItems="center" flexDir="column" mr={1}>
            <IconButton
              variant="solid"
              bg="woodsmoke2"
              color={'#9393A2'}
              aria-label="Call Sage"
              fontSize="25px"
              icon={<QuestionOutlineIcon style={{ fontSize: '1.0em' }} />}
              _hover={{
                backgroundColor: 'charade',
              }}
              _activeLink={{
                backgroundColor: 'charade',
                fontWeight: '700',
              }}
              data-testid="iconbuttons"
              mb={5}
            />
            <IconButton
              variant="solid"
              bg="woodsmoke2"
              color={'#9393A2'}
              aria-label="Call Sage"
              fontSize="25px"
              icon={<SettingsIcon style={{ fontSize: '1.0em' }} />}
              _hover={{
                backgroundColor: 'charade',
              }}
              _activeLink={{
                backgroundColor: 'charade',
                fontWeight: '700',
              }}
              data-testid="iconbuttons"
              mb={5}
            />
          </Center>
        </AsideBottom>
      </AsideContent>
    </AsideHolder>
  );
};

const AsideHolder = ({ isFullWidth, isFixed, children }) => {
  const fixedStyles = {
    position: 'fixed',
    top: '72px',
    bottom: 0,
  };

  return (
    <Box
      as={'aside'}
      width={isFullWidth ? '100%' : '80px'}
      {...(isFixed && fixedStyles)}
    >
      {children}
    </Box>
  );
};

const AsideContent = ({ children }) => (
  <Box
    display={'flex'}
    pl={1}
    height={'100%'}
    flexDirection={'column'}
    position={'relative'}
    boxSizing={'border-box'}
  >
    {children}
  </Box>
);

const AsideTop = ({ children }) => (
  <Box
    display={'flex'}
    flexDirection={'column'}
    flexGrow={1}
    position={'relative'}
    overflow={'auto'}
  >
    {children}
  </Box>
);

const AsideTopHolder = ({ children }) => (
  <Box
    display={'flex'}
    minH={0}
    flexDirection={'column'}
    position={'relative'}
    overflow={'hidden'}
  >
    {children}
  </Box>
);

const AsideTopHolderNav = ({ children }) => (
  <Box flexGrow={1} zIndex={0} willChange={'transform'}>
    {children}
  </Box>
);

const AsideBottom = ({ children }) => (
  <Box width={'100%'} flexShrink={0} alignSelf={'end'} boxSizing={'border-box'}>
    {children}
  </Box>
);

export default Aside;
