import { BehaviorSubject } from 'rxjs';

const initialState = {
  activeFilter: '',
};

const effectsFilterSubject = new BehaviorSubject(initialState);
const titlesFilterSubject = new BehaviorSubject(initialState);
const elementsFilterSubject = new BehaviorSubject(initialState);
const transitionsFilterSubject = new BehaviorSubject(initialState);

export const useEffectsFilterObservable = () => {
  const update = (payload) => {
    setNextState({ ...payload, error: '' });
  };

  const setNextState = (payload) => {
    const state = effectsFilterSubject.getValue();
    effectsFilterSubject.next({ ...state, ...payload });
  };

  const getObservable = () => {
    return effectsFilterSubject;
  };

  return {
    update,
    getObservable,
  };
};

export const useTitlesFilterObservable = () => {
  const update = (payload) => {
    setNextState({ ...payload, error: '' });
  };

  const setNextState = (payload) => {
    const state = titlesFilterSubject.getValue();
    titlesFilterSubject.next({ ...state, ...payload });
  };

  const getObservable = () => {
    return titlesFilterSubject;
  };

  return {
    update,
    getObservable,
  };
};

export const useElementsFilterObservable = () => {
  const update = (payload) => {
    setNextState({ ...payload, error: '' });
  };

  const setNextState = (payload) => {
    const state = elementsFilterSubject.getValue();
    elementsFilterSubject.next({ ...state, ...payload });
  };

  const getObservable = () => {
    return elementsFilterSubject;
  };

  return {
    update,
    getObservable,
  };
};

export const useTransitionFilterObservable = () => {
  const update = (payload) => {
    setNextState({ ...payload, error: '' });
  };

  const setNextState = (payload) => {
    const state = transitionsFilterSubject.getValue();
    transitionsFilterSubject.next({ ...state, ...payload });
  };

  const getObservable = () => {
    return transitionsFilterSubject;
  };

  return {
    update,
    getObservable,
  };
};

const editMenuTabSubject = new BehaviorSubject({ activeTab: 'Adjust' });
export const useEditMenuTabObservable = () => {
  const update = (payload) => {
    setNextState({ activeTab: payload, error: '' });
  };

  const setNextState = (payload) => {
    const state = editMenuTabSubject.getValue();
    editMenuTabSubject.next({ ...state, ...payload });
  };

  const getObservable = () => {
    return editMenuTabSubject;
  };

  return {
    update,
    getObservable,
  };
};

const mediaMenuDrawerSubject = new BehaviorSubject({ isOpen: false });
export const useMediaMenuDrawerObservable = () => {
  const update = (payload) => {
    setNextState({ ...payload, error: '' });
  };

  const setNextState = (payload) => {
    const state = mediaMenuDrawerSubject.getValue();
    mediaMenuDrawerSubject.next({ ...state, ...payload });
  };

  const getObservable = () => {
    return mediaMenuDrawerSubject;
  };

  return {
    update,
    getObservable,
  };
};
