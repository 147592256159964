import { Box, Heading, Text } from '@chakra-ui/react';
import { Avatar } from 'components';
import React from 'react';

const UserButton = React.forwardRef(
  (
    {
      size = 'sm',
      dark = false,
      round = false,
      rightIcon = false,
      onClick = () => null,
      picture,
      name,
      email,
    },
    ref: null
  ) => {
    const isAnyMeta = name || email;

    return (
      <Button
        ref={ref}
        dark={dark}
        round={round}
        onClick={onClick}
        data-testid="avatar-button"
        id="avatar-button"
      >
        <UserMenuButtonHolder dark={dark}>
          <Avatar src={picture} size={size} data-testid="avatar-logo" />

          {isAnyMeta && (
            <UserMenuButtonMeta>
              {name && (
                <UserMenuButtonMetaFullName data-testid="userfullname">
                  {name}
                </UserMenuButtonMetaFullName>
              )}

              {email && (
                <UserMenuButtonMetaEmail>{email}</UserMenuButtonMetaEmail>
              )}
            </UserMenuButtonMeta>
          )}

          {rightIcon && (
            <UserMenuButtonArrow>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  fill="currentColor"
                  d="M11.71 6.47l-3.53 3.54c-.1.1-.26.1-.36 0L4.3 6.47a.75.75 0 1 0-1.06 1.06l3.53 3.54c.69.68 1.8.68 2.48 0l3.53-3.54a.75.75 0 0 0-1.06-1.06z"
                />
              </svg>
            </UserMenuButtonArrow>
          )}
        </UserMenuButtonHolder>
      </Button>
    );
  }
);

const Button = React.forwardRef(({ dark, round, children, onClick }, ref) => {
  return (
    <Box
      as={'button'}
      ref={ref}
      onClick={onClick}
      border={'none'}
      padding={'0'}
      width={'100%'}
      borderRadius={round ? '.25rem' : '0rem'}
      backgroundColor={'transparent'}
      _active={{
        ...(dark && { backgroundColor: 'brand.primary.900' }),
      }}
      _hover={{
        ...(dark && { backgroundColor: 'brand.primary.900' }),
      }}
      data-testid="user-button"
    >
      {children}
    </Box>
  );
});

const UserMenuButtonHolder = ({ dark, children }) => (
  <Box
    display={'flex'}
    p={'1rem'}
    alignItems={'center'}
    color={dark ? 'white' : 'white'}
    data-testid="user-button-holder"
  >
    {children}
  </Box>
);

const UserMenuButtonMeta = ({ children }) => (
  <Box
    pr={'0.5rem'}
    pl={'0.8rem'}
    flexGrow={1}
    textAlign={'left'}
    data-testid="user-button-meta"
  >
    {children}
  </Box>
);

const UserMenuButtonMetaFullName = ({ children }) => (
  <Heading
    as={'h1'}
    size={'md'}
    isTruncated
    fontWeight={600}
    data-testid="user-menu-meta-fullname"
  >
    {children}
  </Heading>
);

const UserMenuButtonMetaEmail = ({ children }) => (
  <Heading
    as={'p'}
    size={'sm'}
    isTruncated
    data-testid="user-menu-meta-email"
    fontWeight={400}
  >
    {children}
  </Heading>
);

const UserMenuButtonArrow = () => (
  <Text as={'span'} data-testid="user-menu-button-arrow" />
);

export default UserButton;
