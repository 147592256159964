import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@chakra-ui/react';
import React from 'react';
import { Redirect } from 'react-router-dom';
import config from '../../../config';
import Templates from './templates';

const Dashboard = () => {
  const { user } = useAuth0();

  if (user?.[config.auth.claims.loginsCount] === 1) {
    return <Redirect to={'/onboarding'} />;
  }

  return (
    <Box p={10} data-testid="dashboard-scene">
      <Templates />
    </Box>
  );
};

export default Dashboard;
