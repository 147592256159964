export const ANIMATION = {
  NONE: 'NONE',
  BOUNCE_IN: 'BOUNCE_IN',
  BOUNCE_IN_DOWN: 'BOUNCE_IN_DOWN',
  BOUNCE_IN_LEFT: 'BOUNCE_IN_LEFT',
  BOUNCE_IN_RIGHT: 'BOUNCE_IN_RIGHT',
  BOUNCE_IN_UP: 'BOUNCE_IN_UP',
  FADE_IN: 'FADE_IN',
  FADE_IN_DOWN: 'FADE_IN_DOWN',
  FADE_IN_LEFT: 'FADE_IN_LEFT',
  FADE_IN_RIGHT: 'FADE_IN_RIGHT',
  FADE_IN_UP: 'FADE_IN_UP',
  FADE_OUT: 'FADE_OUT',
  FADE_OUT_DOWN: 'FADE_OUT_DOWN',
  FADE_OUT_LEFT: 'FADE_OUT_LEFT',
  FADE_OUT_RIGHT: 'FADE_OUT_RIGHT',
  FADE_OUT_UP: 'FADE_OUT_UP',
  FLASH: 'FLASH',
  FLIP: 'FLIP',
  FLIP_IN_X: 'FLIP_IN_X',
  FLIP_IN_Y: 'FLIP_IN_Y',
  FLIP_OUT_X: 'FLIP_OUT_X',
  HEAD_SHAKE: 'HEAD_SHAKE',
  HINGE: 'HINGE',
  JELLO: 'JELLO',
  LIGHT_SPEED_IN: 'LIGHT_SPEED_IN',
  LIGHT_SPEED_OUT: 'LIGHT_SPEED_OUT',
  PULSE: 'PULSE',
  ROLL_IN: 'ROLL_IN',
  ROTATE_IN: 'ROTATE_IN',
  ROATATE_IN_DOWN_LEFT: 'ROATATE_IN_DOWN_LEFT',
  ROATATE_IN_DOWN_RIGHT: 'ROATATE_IN_DOWN_LEFT',
  ROATATE_IN_UP_LEFT: 'ROATATE_IN_UP_LEFT',
  ROATATE_IN_UP_RIGHT: 'ROATATE_IN_UP_RIGHT',
  ROTATE_OUT: 'ROTATE_OUT',
};
