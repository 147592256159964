import { useAiChatObserveables } from 'observeables';
import { useAuth0 } from '@auth0/auth0-react';
import { EditorApiConstants } from '../constants';
import { isEmpty, map } from 'lodash';
import { useContext } from 'react';
import { nanoid } from 'nanoid';
import { EditorApiContext } from 'providers/api-provider';
import { useAddToCompositionHook } from 'hooks/useAddToCompositionHook';

export const useAiChatMessageRepository = () => {
  const aiChatObserveable = useAiChatObserveables();
  const compositionHook = useAddToCompositionHook();
  const { user } = useAuth0();
  /** Axios client */
  const _axios = useContext(EditorApiContext);

  const update = async (payload) => {
    try {
      aiChatObserveable.update(payload);
    } catch (error) {
      aiChatObserveable.error('Error in updating edit area dimensons');
    } finally {
    }
  };

  const generateAiChatMessage = async ({ id, message, projectId }) => {
    if (isEmpty(message)) return null;
    /// generate image for the message
    const data = {
      id: projectId,
      projectId,
      user: user.email,
      prompt: message,
      uniqueId: id,
    };
    try {
      const aiImageResponse = await _axios
        .post(
          `/${EditorApiConstants.endpoints.CREATE_AI_IMAGE_WITH_PROMPT}`,
          JSON.stringify(data),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(({ data }) => {
          return data?.data || {};
        });

      if (isEmpty(aiImageResponse)) return null;
      compositionHook.handleOnAiImageCreateToEditor(aiImageResponse, id);
      aiChatObserveable.updateLoadingState({
        id,
        isLoading: false,
        isError: false,
      });
    } catch (error) {
      aiChatObserveable.updateLoadingState({
        id,
        isLoading: false,
        isError: true,
      });
    }
  };

  const addMessage = async ({ id, message, projectId }) => {
    aiChatObserveable.addMessage({ id, message });
    await generateAiChatMessage({ id, message, projectId });
  };

  const updateMessage = async (id, message) => {
    aiChatObserveable.updateMessage(id, message);
  };

  const getObservable = () => aiChatObserveable.getObservable();

  return {
    addMessage,
    updateMessage,
    update,
    getObservable,
    generateAiChatMessage,
  };
};
