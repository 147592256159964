import { Box, Button, Grid, IconButton, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { map, lowerCase } from 'lodash';
import React from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import { PixelsComponent } from '../../Overlays/components/PixelsComponent';

const SoundWavesList = [
  {
    id: '7c7d11ef-c977-41da-8d48-8abcdddfdab0',
    text: 'None',
    imgSrc:
      'https://eyecastvideoeditorfiles.s3.ap-southeast-2.amazonaws.com/assets/soundwaves/no_stopping.png',
  },
  {
    id: '7c7d11ef-c977-41da-8d48-8abcdddfdab0',
    text: 'Equalizer',
    imgSrc:
      'https://eyecastvideoeditorfiles.s3.ap-southeast-2.amazonaws.com/assets/soundwaves/discrete.png',
  },
  {
    id: 'b6da67e3-56db-432c-b7fc-8917429f0222',
    text: 'Roundbars',
    imgSrc:
      'https://eyecastvideoeditorfiles.s3.ap-southeast-2.amazonaws.com/assets/soundwaves/reflex.png',
  },
  {
    id: 'b6da67e3-56db-432c-b7fc-8917429f0222',
    text: 'led',
    imgSrc:
      'https://eyecastvideoeditorfiles.s3.ap-southeast-2.amazonaws.com/assets/soundwaves/leds.png',
  },
  {
    id: 'b6da67e3-56db-432c-b7fc-8917429f0222',
    text: 'radial_spectrum',
    imgSrc:
      'https://eyecastvideoeditorfiles.s3.ap-southeast-2.amazonaws.com/assets/soundwaves/radial.png',
  },
];

export const AudioWavesGrid = ({
  onClick,
  onViewAllClick,
  selectedUniqueId,
  selectedOverlay,
}) => {
  console.log('selected values', selectedUniqueId, selectedOverlay);
  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={3} data-testid="filter-grid">
      {map(SoundWavesList, (value) => {
        return (
          <Box
            key={`pixeslsgrid-${value.id}`}
            id={value.id}
            position={'relative'}
          >
            <Box
              key={value.id}
              w="104px"
              h="55px"
              fontSize="25px"
              bg="charadeDark3"
              justifyContent="center"
              display="flex"
              flexDir="column"
              alignItems="center"
              borderRadius={5}
              data-testid="filter-grid-box"
              onClick={(e) => {
                e.stopPropagation();
                onClick(value.text.toLowerCase());
              }}
            >
              <PixelsComponent filter={value.text} imgSource={value.imgSrc} />
            </Box>
            <Text mt={1} fontSize="xx-small" align="center" color="pearl">
              {value?.text}
            </Text>
            <Box position={'absolute'} right={1} bottom={'26%'}>
              {selectedUniqueId &&
                lowerCase(selectedOverlay) === lowerCase(value.text) && (
                  <CheckCircleIcon color="azure" />
                )}
            </Box>
          </Box>
        );
      })}
      <Box>
        <Box
          as={Button}
          w="104px"
          h="55px"
          bg="charadeDark3"
          fontSize="37px"
          justifyContent="center"
          display="flex"
          alignItems="center"
          borderRadius={5}
          color="azure"
          data-testid="shape-grid-box"
          // onClick={onViewAllClick}
        >
          <IconButton icon={<FiMoreHorizontal />} />
        </Box>
        <Text fontSize="xx-small" align="center" color="pearl">
          View all
        </Text>
      </Box>
    </Grid>
  );
};
