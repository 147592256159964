import { Box } from '@chakra-ui/react';
import omit from 'lodash/omit';
import React from 'react';
import { useDrag } from 'react-dnd';
import { ItemTypes } from '../../../constants/ItemTypes';

function DraggableBox({ id, children, type, file, ...props }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.BOX,
    item: { id, type, ...props },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        // alert(`You dropped into ${id}!`);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const opacity = isDragging ? 0.6 : 1;
  return (
    <Box
      ref={drag}
      opacity={opacity}
      {...omit(props, ['fileType', 'file', 's3Url', 'src'])}
    >
      {children}
    </Box>
  );
}

export default DraggableBox;
