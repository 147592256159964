import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import WaveSurfer from 'wavesurfer.js';
import { Box } from '@chakra-ui/react';
import { GsapPixieContext } from 'reacthub-pixitweenjs';
import { useInterval } from 'react-use';

const Waveform = ({ audio, uniqueId }) => {
  const containerRef = useRef();
  const audioTweenRef = useRef();
  const waveSurferRef = useRef({
    isPlaying: () => false,
  });
  const { tl: timeline } = React.useContext(GsapPixieContext);
  const totalDuration = timeline.current && timeline.current.totalDuration();

  useEffect(() => {
    const waveSurfer = WaveSurfer.create({
      container: containerRef.current,
      waveColor:
        'linear-gradient(270deg, #483483 -0.36%, #483483 81.07%, #483483 83.46%, #6449B6 94.04%);',
      backgroundColor: '#6449B6',
      progressColor: '#02F7CB',
      hideCursor: true,
      interact: true,
      responsive: true,
      barWidth: 2,
      barHeight: 10,
      cursorWidth: 0,
      height: 31,
      hideScrollbar: true,
    });
    waveSurfer.load(audio, null, 'auto');
    waveSurfer.on('ready', () => {
      waveSurferRef.current = waveSurfer;
    });

    return () => {
      waveSurfer.destroy();
    };
  }, [audio]);

  React.useEffect(() => {
    const tween = timeline.current && timeline.current.getById(uniqueId);
    if (tween) {
      audioTweenRef.current = tween;
    }
  }, [uniqueId, totalDuration]);

  const updateProgress = () => {
    if (waveSurferRef.current) {
      if (waveSurferRef.current.seekTo) {
        const progress =
          audioTweenRef.current && audioTweenRef.current.progress();
        waveSurferRef.current.seekTo(progress || 0);
      }
    }
  };

  useInterval(
    async () => {
      // update project assets data
      updateProgress();
    },
    // Delay in milliseconds or null to stop it
    300
  );

  return (
    <Box>
      <div ref={containerRef} />
    </Box>
  );
};

Waveform.propTypes = {
  audio: PropTypes.string.isRequired,
};

export default Waveform;
