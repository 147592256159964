import { Icon } from '@chakra-ui/react';
import React from 'react';

export const Screenonly = (props) => (
  <Icon viewBox="0 0 21 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.11765C0 0.948103 0.940202 0 2.1 0H18.9C20.0598 0 21 0.948103 21 2.11765V10.5882C21 11.7578 20.0598 12.7059 18.9 12.7059H11.55V15.8824H13.65C14.2299 15.8824 14.7 16.3564 14.7 16.9412C14.7 17.5259 14.2299 18 13.65 18H7.35C6.7701 18 6.3 17.5259 6.3 16.9412C6.3 16.3564 6.7701 15.8824 7.35 15.8824H9.45V12.7059H2.1C0.940203 12.7059 0 11.7578 0 10.5882V2.11765ZM18.9 2.11765H2.1V10.5882H18.9V2.11765Z"
      fill="#7A04B1"
    />
  </Icon>
);
