import { Box, Heading } from '@chakra-ui/react';
import React from 'react';
import TemplateSlider from './template-slider';
import TemplateTags from './template-tags';

const Templates = () => {
  const handleTagClick = (value) => console.log('tag id', value);

  return (
    <Box py={10}>
      <Heading as={'h1'} mb={8} fontWeight={700} size={'xl'}>
        Start from a template
      </Heading>

      <Box mb={8}>
        <TemplateTags onClick={handleTagClick} />
      </Box>

      <TemplateSlider />
    </Box>
  );
};

export default Templates;
