import { AddIcon } from '@chakra-ui/icons';
import { Flex, Image, Link, Text } from '@chakra-ui/react';
import React from 'react';

export const ImageItem = ({
  src,
  username,
  userlink,
  id,
  bgFilter,
  onClick = (f) => f,
}) => {
  const handleOnClick = () => {
    onClick(id);
  };

  return (
    <Flex
      position={`relative`}
      cursor="pointer"
      _hover={{
        '& > .overlay': {
          transform: 'scale(1)',
        },
      }}
      {...(bgFilter && { filter: bgFilter })}
      onClick={handleOnClick}
      justifyContent="center"
      alignItems={'center'}
    >
      <Image
        width={'100%'}
        borderRadius="5px"
        height="100%"
        objectFit={'cover'}
        src={src}
        alt="Dan Abramov"
        crossOrigin="anonymous"
      />

      <Flex
        as={'span'}
        className="overlay"
        data-testid="overlay"
        position={'absolute'}
        width="100%"
        height="100%"
        background={'rgba(57, 57, 57, 0.502)'}
        top={0}
        left={0}
        transform={`scale(0)`}
        transition={`all 0.2s 0.1s ease-in-out`}
        color={'#fff'}
        alignItems={'center'}
        justifyContent={'center'}
        display={'flex'}
        flexDir="column"
      >
        <Flex flex={1}>
          <AddIcon
            fontSize={20}
            margin={'auto'}
            position={'relative'}
            transitionDelay={'0s'}
            display={'inline-block'}
            color={'#ffffff'}
          />
        </Flex>
        <Flex
          as="span"
          justifyContent="flex-end"
          alignItems="flex-end"
          flexDir="row"
        >
          <Text fontSize={'10px'} mr={2}>
            Photo by
          </Text>
          <Link
            target="_blank"
            rel="noreferrer"
            href={userlink}
            bottom={0}
            alignItems={'center'}
          >
            <Text
              fontSize={'10px'}
              textDecoration="underline"
              color={'#8abbff'}
            >{`${username}`}</Text>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
};
