/**
 * This file contains the logic for the aiChatObserveables.
 */
import { BehaviorSubject } from 'rxjs';

const initialState = {
  messages: [],
};

const aiChatSubject = new BehaviorSubject(initialState);

export const useAiChatObserveables = () => {
  const update = (payload) => {
    setNextState({ ...payload, error: '' });
  };

  const addMessage = ({ id, message }) => {
    setNextState({
      messages: [
        ...aiChatSubject.getValue().messages,
        {
          id: id,
          from: 'user',
          text: message,
          isLoading: true,
          isError: false,
        },
      ],
    });
  };

  const updateLoadingState = ({ id, isLoading, isError }) => {
    const messages = aiChatSubject.getValue().messages.map((msg) => {
      if (msg.id === id) {
        return { ...msg, isLoading, isError };
      }
      return msg;
    });
    setNextState({ messages });
  };

  const updateMessage = (id, message) => {
    const messages = aiChatSubject.getValue().messages.map((msg) => {
      if (msg.id === id) {
        return { ...msg, ...message };
      }
      return msg;
    });
    setNextState({ messages });
  };

  const updating = (flag) => {
    setNextState({ updating: flag });
  };

  const removing = (flag) => {
    setNextState({ removing: flag });
  };

  const error = (message) => {
    setNextState({ error: message });
  };

  const setNextState = (payload) => {
    const state = aiChatSubject.getValue();
    aiChatSubject.next({ ...state, ...payload });
  };

  const getObservable = () => {
    return aiChatSubject;
  };

  return {
    update,
    updating,
    removing,
    error,
    getObservable,
    addMessage,
    updateMessage,
    updateLoadingState,
  };
};
