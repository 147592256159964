import { Box } from '@chakra-ui/react';
import useEventCallback from 'hooks/use-event-callback';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import SliderControls from './slider-controls';

const Slider = ({ children }) => {
  const sliderWrapperRef = useRef(null);
  const sliderRef = useRef(null);

  const [displayScroll, setDisplayScroll] = useState({
    start: false,
    end: false,
  });

  const moveSliderScroll = (delta) => {
    let scrollValue = sliderRef.current.scrollLeft;
    scrollValue += delta * getScrollSize();
    sliderRef.current.scroll({ left: scrollValue, behavior: 'smooth' });
  };

  const getScrollSize = () => {
    const containerSize = sliderWrapperRef.current.clientWidth;
    const children = Array.from(sliderRef.current.children);

    let totalSize = 0;
    for (const slide of children) {
      if (totalSize + slide.clientWidth > containerSize) break;
      totalSize += slide.clientWidth;
    }

    return totalSize;
  };

  const updateScrollButtonState = useEventCallback(() => {
    const { scrollWidth, scrollLeft, clientWidth } = sliderRef.current;

    // use 1 for the potential rounding error with browser zooms.
    let showStart = scrollLeft > 1;
    let showEnd = scrollLeft < scrollWidth - clientWidth - 1;

    if (showStart !== displayScroll.start || showEnd !== displayScroll.end) {
      setDisplayScroll({ start: showStart, end: showEnd });
    }
  });

  const handleSliderScroll = useMemo(
    () => debounce(updateScrollButtonState),
    [updateScrollButtonState]
  );

  useEffect(() => {
    updateScrollButtonState();
  });

  return (
    <Box ref={sliderWrapperRef} position={'relative'} height={'100%'}>
      <Box overflow={'hidden'}>
        <Box
          ref={sliderRef}
          onScroll={handleSliderScroll}
          display={'flex'}
          flexWrap={'nowrap'}
          overflowX={'auto'}
          overflowY={'hidden'}
          mx={'-0.5rem'}
          sx={{
            WebkitOverflowScrolling: 'touch',
            '::-webkit-scrollbar': {
              display: 'none',
            },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          }}
        >
          {children}
        </Box>
      </Box>

      {sliderRef?.current?.children.length > 4 && (
        <SliderControls
          showPrev={displayScroll.start}
          showNext={displayScroll.end}
          onClick={moveSliderScroll}
        />
      )}
    </Box>
  );
};

export default Slider;
