import { Divider, Flex, IconButton, Stack, Text } from '@chakra-ui/react';
import { Backarrow } from 'assets/icons';
import React from 'react';
import RecordOptions from './record-options';
import UploadOptions from './upload-options';
import { useMediaMenuDrawerRepository } from 'repositories';

function UploadMedia() {
  const mediaMenuRepo = useMediaMenuDrawerRepository();

  const handleOnClose = () => {
    mediaMenuRepo.update({ isOpen: false });
  };

  return (
    <Flex
      flexDir="column"
      paddingRight={4}
      paddingLeft={3}
      paddingTop={3}
      paddingBottom={3}
      bg="shark"
      data-testid="upload-media"
      height="100%"
    >
      <Stack spacing={4}>
        <IconButton
          icon={<Backarrow fontSize={'18px'} color="alto" />}
          display="block"
          minWidth={6}
          width={1}
          height={7}
          p={0}
          bg="shark"
          onClick={handleOnClose}
        />
        <Divider orientation="horizontal" borderColor="mako" />
        <Text fontSize="sm" fontWeight="600">
          Upload From
        </Text>
        <UploadOptions />
        <Text fontSize="sm" fontWeight="600">
          Record
        </Text>
        <RecordOptions />
      </Stack>
    </Flex>
  );
}

export default UploadMedia;
