import { Icon } from '@chakra-ui/react';
import React from 'react';

export const VectorTitles = (props) => (
  <Icon viewBox="0 0 23 24" {...props}>
    <path
      opacity="0.75"
      d="M0 0V3H10.0357V24H12.9643V3H23V0H0Z"
      fill="currentColor"
    />
  </Icon>
);
