import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { Route, useHistory } from 'react-router-dom';

const ModalRoute = ({ component: Component, path, ...rest }) => {
  const history = useHistory();

  const handleClose = useCallback(() => history.goBack(), [history]);

  return (
    <Route
      path={path}
      children={
        <Modal onClose={handleClose} size={'full'} isOpen={true}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <Component />
            </ModalBody>
          </ModalContent>
        </Modal>
      }
      {...rest}
    />
  );
};

export default ModalRoute;
