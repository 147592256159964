import { Box, Text } from '@chakra-ui/react';
import {
  BsImages,
  ElementsIcon,
  GroupEditIcon,
  Heart,
  VectorEffectsIcon,
  VectorMediaIcon,
  VectorTitles,
  VectorTransitions,
  RobotAI,
} from 'assets/icons';
import React from 'react';
import { useSidebarRepository } from 'repositories';
import { useObservable } from 'utils/useObserveable';

const SIDE_BAR_MENU_IEMS = [
  {
    id: 'photo-video',
    icon: 'caret-square-right',
    name: 'Media',
    element: VectorMediaIcon,
    isBrand: false,
  },
  {
    id: 'edit',
    icon: 'edit',
    name: 'Edit',
    element: GroupEditIcon,
    isBrand: false,
  },
  {
    id: 'photos',
    icon: 'uncharted',
    library: 'fontawesome',
    name: 'Photos',
    isBrand: true,
    element: BsImages,
  },
  {
    id: 'titles',
    icon: 'font',
    name: 'Titles',
    isBrand: false,
    element: VectorTitles,
  },
  {
    id: 'transition',
    icon: 'font',
    name: 'Transition',
    isBrand: false,
    element: VectorTransitions,
  },
  {
    id: 'effects',
    icon: 'star',
    name: 'Effects',
    isBrand: false,
    element: VectorEffectsIcon,
  },
  {
    id: 'elements',
    icon: 'uncharted',
    library: 'fontawesome',
    name: 'Elements',
    isBrand: true,
    element: ElementsIcon,
  },
  {
    id: 'ai',
    icon: 'robot',
    library: 'fontawesome',
    name: 'AI',
    isBrand: false,
    element: RobotAI,
  },
  // {
  //   id: 'More',
  //   icon: 'more',
  //   name: 'More',
  //   isBrand: false,
  //   element: MoreIcon,
  // },
];

const AsideEditingNav = () => {
  const sidebarRepository = useSidebarRepository();
  const sidebarState = useObservable(sidebarRepository.getSidebarObservable());
  const { activeMenu } = sidebarState;

  /** selected menu dispatch */
  const handleOnMenuClick = (menuName) => {
    // rxjs update;
    sidebarRepository.update(menuName);
  };

  const isEditMenu = (name) => {
    if (name === activeMenu) return true;
    else if (name.includes('Edit') && activeMenu === 'Titles-Editing')
      return true;
    return false;
  };

  React.useEffect(() => {}, [sidebarState]);
  // React.useEffect(() => {
  //   if (activeMenu === 'Titles-Editing') {
  //     setSidebarMenu('Edit');
  //   } else {
  //     setSidebarMenu(activeMenu);
  //   }
  // }, [activeMenu]);

  return (
    <React.Fragment>
      {SIDE_BAR_MENU_IEMS.map((navItem) => (
        <Box
          as="button"
          alignItems="center"
          justifyContent="center"
          flexDir="column"
          display="flex"
          key={navItem.name}
          borderLeft={isEditMenu(navItem.name) ? '4px solid turquoise' : ''}
          height="75px"
          width="81px"
          pr={2}
          bg={isEditMenu(navItem.name) ? 'shark' : 'woodsmoke2'}
          color={isEditMenu(navItem.name) ? 'turquoise' : 'manatee'}
          onClick={() => {
            handleOnMenuClick(navItem.name);
          }}
          _hover={{
            backgroundColor: 'charade',
          }}
          _activeLink={{
            backgroundColor: 'charade',
            fontWeight: '700',
          }}
          data-testid="aside-nav-item"
          data-item={navItem.name}
        >
          {React.createElement(navItem.element, {
            mb: '8px',
            fontSize: '25px',
            width: '35px',
            height: '25px',
            color: isEditMenu(navItem.name) ? 'turquoise' : 'manatee',
          })}
          <Text as="span" fontSize="xs" data-testid="aside-nav-item-text">
            {navItem.name}
          </Text>
        </Box>
      ))}
    </React.Fragment>
  );
};

export default AsideEditingNav;

// #8A8999 - mantee
