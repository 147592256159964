import { Box, Flex, Grid, Spinner } from '@chakra-ui/react';
import map from 'lodash/map';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import StipopElement from './StipopElement';

export const AllStipopGrid = ({ onClick, data, isLoading = false }) => {
  // const newData = data.slice(26);
  return (
    <>
      <Flex mt="40px" flex={1} align="center" justify={'center'}>
        {isLoading && <Spinner alignSelf="center" size={'lg'} />}
        <Grid templateColumns="repeat(3, 1fr)" gap={3} data-testid="emojisgrid">
          {map(data, (value) => {
            const ext = value.stickerImg.substr(
              value.stickerImg.lastIndexOf('.') + 1
            );
            const isGif = ext && ext.includes('gif');
            const stickerData = {
              id: value.id,
              type: isGif ? 'gifs' : 'images',
              name: value.id,
              droptype: isGif ? 'gifs' : 'images',
              src: value.stickerImg,
              s3Key: value.stickerImg,
              s3Url: value.stickerImg,
            };
            return (
              <LazyLoadComponent delayTime={400} key={value.stickerId}>
                <Box
                  key={value.stickerId}
                  w="90px"
                  h="50px"
                  bg="charadeDark3"
                  fontSize="37px"
                  justifyContent="center"
                  display="flex"
                  alignItems="center"
                  borderRadius={5}
                  color="azure"
                  data-testid="sticker-grid-box"
                  as="button"
                  onClick={() => onClick(stickerData)}
                >
                  <StipopElement
                    stickerImg={value.stickerImg}
                    id={value.stickerId}
                    width={50}
                  />
                </Box>
              </LazyLoadComponent>
            );
          })}
        </Grid>
      </Flex>
    </>
  );
};
