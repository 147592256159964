import { Box, Grid, GridItem, Text } from '@chakra-ui/react';
import { OverlayList } from 'constants';
import { map } from 'lodash';
import React from 'react';
import { PixelsComponent } from './PixelsComponent';

export const AllOverlayByPixelsGrid = ({ onClick, selectedOverlay }) => {
  console.log('selectedOverlay', selectedOverlay);
  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={1} data-testid="filter-grid">
      {map(OverlayList, (value) => {
        return (
          <GridItem key={`pixeslsgrid-${value.id}`} id={value.id}>
            <Box
              key={value.id}
              fontSize="25px"
              justifyContent="center"
              alignItems="center"
              borderRadius={5}
              data-testid="filter-grid-box"
              onClick={(e) => {
                e.stopPropagation();
                onClick(value.text);
              }}
            >
              <PixelsComponent filter={value.text} />
            </Box>
            <Text mt={1} fontSize="xx-small" align="center" color="pearl">
              {value?.text}
            </Text>
          </GridItem>
        );
      })}
    </Grid>
  );
};
